/* Estilo principal do rodapé */
.rodape-unique {
  color: white; /* Texto branco */
  text-align: center; /* Centraliza o texto */
  padding: 20px; /* Espaçamento interno */
  width: 50%; /* Ocupa toda a largura da tela */
  font-size: 14px;
  position: relative; /* Permite que ele se ajuste conforme o conteúdo da página */
  bottom: 0;
}

.rodape-container-unique {
  max-width: 1200px; /* Limita a largura do rodapé */
  margin: 0 auto; /* Centraliza o conteúdo do rodapé */
}

.rodape-text-unique {
  margin: 0;
  line-height: 1.6;
  display: flex;
  justify-content: center; /* Garante que o texto fique sempre centralizado */
  align-items: center;
  height: 100%;
}

.rodape-link-unique {
  color: #f1f1f1; /* Cor clara para os links */
  text-decoration: none;
  margin-left: 5px; /* Espaçamento entre o texto e o link */
}

.rodape-link-unique:hover {
  text-decoration: underline; /* Sublinha os links ao passar o mouse */
}

/* Responsividade para dispositivos móveis */
@media screen and (max-width: 768px) {
  .rodape-container-unique {
    padding-left: 10px;
    padding-right: 10px;
  }

  .rodape-unique {
    font-size: 12px; /* Texto menor no mobile */
  }
}
.rodape-unique {
  color: white; /* Texto branco */
  text-align: center; /* Centraliza o texto */
  padding: 20px; /* Espaçamento interno */
  width: 100%; /* Ocupa toda a largura da tela */
  font-size: 14px;
  position: relative; /* Permite que ele se ajuste conforme o conteúdo da página */
  bottom: 0;
}
@media screen and (max-width: 480px) {
  .rodape-unique {
    font-size: 10px; /* Texto ainda menor para telas pequenas */
  }
}
