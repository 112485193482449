#root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  /* Adicionei height: 100% para garantir que o root ocupe toda a altura da tela */
}

.auth-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
}

.auth-header span {
  font-weight: bold;
}

.auth-header button {
  padding: 5px 10px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.auth-header button:hover {
  background-color: #0056b3;
}

/* Adicionando o background com repetição */
body {
  background: url('./imagens/fundo-login.webp') repeat-y center center fixed;
  background-size: cover;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Evita que apareça barra de rolagem horizontal */
}

/* Garantindo que o fundo cubra toda a página e se repita conforme a página cresce */
html, body {
  height: 100%;
  background-attachment: fixed;
  background-repeat: repeat-y; /* Faz o background se repetir verticalmente */
  background-size: cover; /* Garante que ele cubra a tela, mas permite repetição */
}
/* Estilos Globais para a Barra de Rolagem */

/* Para navegadores baseados em WebKit (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  /* Não definimos largura ou altura para manter o padrão */
}

::-webkit-scrollbar-track {
  background: #1a1a1a; /* Cor do trilho da barra de rolagem */
}

::-webkit-scrollbar-thumb {
  background-color: #bfa066; /* Cor do polegar (parte que você arrasta) */
  border-radius: 999px; /* Bordas totalmente arredondadas */
}

/* Para navegadores Firefox */
* {
  scrollbar-width: auto; /* Mantém a largura padrão da scrollbar */
  scrollbar-color: #bfa066 #1a1a1a; /* Cor do polegar e do trilho */
}

/* Configurações específicas para dispositivos móveis */
@media (max-width: 768px) {
  body {
    background: url('./imagens/fundo-login.webp') no-repeat center center fixed; /* Chamando a imagem e fixando no mobile */
    background-size: cover; /* Garante que a imagem cubra toda a tela */
    background-attachment: fixed; /* Garante que a imagem fique fixa no scroll */
  }
}