.salary-map-container {
  background-color: #1a1a1a;
  padding: 40px;
  border-radius: 10px;
  color: white;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.salary-map-title {
  font-size: 36px;
  margin-bottom: 40px;
  font-weight: bold;
}

.steps-container {
  position: relative;
  margin: 20px 0;
}

.line {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #ffbf00;
  transform: translateY(-50%);
}

.steps {
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-wrap: wrap;
}

.step {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 12%;
  margin: 10px 0;
}

.icon-container {
  background-color: #333;
  padding: 20px;
  border-radius: 50%;
  transition: transform 0.3s;
}

.icon-container.active {
  transform: scale(1.2);
  background-color: #ffbf00;
}

.step-description {
  margin-top: 10px;
}

.step-description.above {
  margin-bottom: 20px;
}

.step-description.below {
  margin-top: 20px;
}

.step-text {
  font-size: 14px;
  color: #aaa;
}

.step-text.active {
  color: #ffbf00;
}

.project-note {
  margin-top: 30px;
  font-size: 16px;
  color: #ccc;
}

/* Responsividade */
@media (max-width: 768px) {
  .steps {
    flex-direction: column;
    align-items: center;
  }

  .step {
    width: 100%;
    margin: 20px 0;
  }

  .line {
    display: none;
  }

  .step-description {
    font-size: 12px;
  }

  .icon-container {
    padding: 15px;
  }
}
