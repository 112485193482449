/* AlteraÃ§Ãµes no container principal */
.vp-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #1a1a1a;
  position: relative;
  isolation: isolate;
  z-index: 1;
  width: 100%;
}

.vp-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #1a1a1a;
  box-shadow: 0 4px 12px #d0995666;
  height: 70px;
}

.vp-header-logo {
  height: 40px;
  transition: transform 0.2s ease;
}

.vp-header-logo:hover {
  transform: scale(1.02);
}

.vp-logo {
  height: 100%;
  width: auto;
}

.vp-header-user {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}

.vp-user-name {
  font-weight: 600;
  color: #fff;
  font-size: 0.95rem;
}

.vp-user-photo {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #2d2d2d;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease;
}

.vp-user-photo:hover {
  transform: scale(1.05);
}

.vp-content {
  display: flex;
  flex: 1;
  padding: 2rem;
  gap: 2rem;
  max-width: 99%;
  margin: 0 auto;
  width: 100%;
  margin-top: 750px;
  height: calc(90vh - 70px);
  position: relative;
}


.vp-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow-y: auto;
  padding-right: 1rem;
  min-width: 0;
  margin-left: 340px; /* Adicionado para compensar a sidebar fixa */
}

.vp-main::-webkit-scrollbar {
  width: 6px;
}

.vp-main::-webkit-scrollbar-track {
  background: #2d2d2d;
  border-radius: 3px;
}

.vp-main::-webkit-scrollbar-thumb {
  background: #454545;
  border-radius: 3px;
}

.vp-main::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.vp-video-section {
  width: calc(100% - 40px); /* Reduz a largura total e adiciona 20px de cada lado */
  background: #2d2d2d;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  border: 1px solid #3d3d3d;
  min-height: 400px;
  position: relative;
  margin: 1rem 20px; /* Margem vertical de 1rem e horizontal de 20px */
}

.vp-video-frame {
  width: 100%;
  height: 100%;
  min-height: 400px;
  max-height: 720px; /* MantÃƒÂ©m proporÃƒÂ§ÃƒÂ£o 16:9 com max-width */
  border: none;
  aspect-ratio: 16/9;
  background: #000;
  object-fit: contain;
}

.vp-video-controls {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.vp-button {
  background: linear-gradient(45deg, #ffc871, #c28b4e);
  color: #1a1a1a;
  border: none;
  padding: 0.4rem 0.8rem; /* DiminuÃƒÂ­do */
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
  font-size: 0.85rem; /* DiminuÃƒÂ­do */
}

.vp-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(194, 139, 78, 0.3);
  opacity: 0.95;
}

/* Ajustes na seÃ§Ã£o de descriÃ§Ã£o */
.vp-description {
  background: #2d2d2d;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  color: #ffffff;
  line-height: 1.6;
  min-height: 200px; /* Altura mÃ­nima para a descriÃ§Ã£o */
  flex: 1;
  display: flex;
  flex-direction: column;
}

.vp-description h4 {
  color: #fff;
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  font-family: 'Inter', sans-serif;
  letter-spacing: -0.5px;
}

.vp-description a {
  color: #ffc871;
  text-decoration: none;
  transition: all 0.2s ease;
  border-bottom: 1px solid transparent;
  padding-bottom: 2px;
}

.vp-description a:hover {
  border-bottom-color: #ffc871;
  opacity: 0.9;
}

.vp-sidebar {
  width: 320px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: calc(100vh - 90px);
  position: fixed;
  left: 2rem;
  top: 90px;
  padding-right: 1rem;
}

.vp-progress {
  background: #2d2d2d;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.vp-progress h3 {
  color: #fff;
  font-size: 1.1rem;
  margin-bottom: 1rem;
  font-family: 'Inter', sans-serif;
}

.vp-progress-bar {
  width: 100%;
  height: 8px;
  background-color: #3d3d3d;
  border-radius: 4px;
  overflow: hidden;
  margin: 1rem 0;
}

.vp-progress-fill {
  height: 100%;
  background: linear-gradient(45deg, #ffc871, #c28b4e);
}

.vp-aulas-lista {
  background: #2d2d2d;
  padding: 1.75rem;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  flex: 1;
  overflow-y: auto;
  border: 1px solid #3d3d3d;
  height: 100%;
  /* Estilos específicos para a barra de rolagem */
  scrollbar-width: thin;
  scrollbar-color: #454545 #2d2d2d;
}

/* Estilos da barra de rolagem para webkit (Chrome, Safari, etc) */
.vp-aulas-lista::-webkit-scrollbar {
  width: 6px;
}

.vp-aulas-lista::-webkit-scrollbar-track {
  background: #2d2d2d;
  border-radius: 3px;
}

.vp-aulas-lista::-webkit-scrollbar-thumb {
  background: #454545;
  border-radius: 3px;
  border: 1px solid #2d2d2d;
}

.vp-aulas-lista::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.vp-aulas-lista h3 {
  color: #fff;
  font-size: 1.3rem;
  margin-bottom: 1.75rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #3d3d3d;
  font-family: 'Inter', sans-serif;
  letter-spacing: -0.5px;
  position: relative;
}

.vp-aulas-lista ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
}

.vp-aula-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1.25rem;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.3s ease;
  border: 1px solid #3d3d3d;
  color: #fff;
  font-family: 'Inter', sans-serif;
  font-size: 0.95rem;
  letter-spacing: -0.3px;
  background: linear-gradient(45deg, rgba(45, 45, 45, 0.5), rgba(61, 61, 61, 0.5));
  position: relative;
  overflow: hidden;
}

/* Efeito hover para os itens */
.vp-aula-item:hover {
  background: linear-gradient(45deg, rgba(255, 200, 113, 0.15), rgba(194, 139, 78, 0.15));
  border-color: #ffc87155;
  transform: translateX(4px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
/* Estilo para aula atual */
.vp-aula-atual {
  background: linear-gradient(45deg, #ffc871, #c28b4e);
  color: #1a1a1a;
  font-weight: 500;
  border: none;
  box-shadow: 0 4px 15px rgba(194, 139, 78, 0.3);
}

.vp-aula-atual:hover {
  background: linear-gradient(45deg, #ffd88f, #d19a5c);
  transform: translateX(4px);
}
.vp-aula-atual .vp-aula-icon {
  color: #1a1a1a;
}

/* Estilo para ícone de aula concluída */
.vp-aula-concluida .vp-aula-icon {
  color: #4CAF50;
  transform: scale(1);
}
/* Estilo unificado para ícones */
.vp-aula-icon {
  min-width: 24px; /* Garante largura mínima consistente */
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #888;
  transition: all 0.3s ease;
}
/* Estilo para ícone de cadeado */
.vp-aula-item:first-child .vp-aula-icon {
  color: #ffffff;
}

/* Animação de conclusão */
@keyframes checkmark {
  0% { transform: scale(0); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

.vp-aula-concluida .vp-aula-icon {
  animation: checkmark 0.5s ease-out forwards;
}

.vp-error {
  background-color: #3d2d2d;
  border: 1px solid #614444;
  color: #ff8080;
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Star Rating System */
.vp-avaliacao {
  display: flex;
  gap: 0.5rem;
  padding: 1rem 0;
  justify-content: center;
  flex-direction: row;
}

.vp-star {
  cursor: pointer;
  font-size: 24px;
  color: #4a4a4a;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.vp-star:hover {
  transform: scale(1.2);
}

.vp-star-active {
  color: #ffc871;
  animation: starPop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@keyframes starPop {
  0% { transform: scale(1); }
  50% { transform: scale(1.3); }
  100% { transform: scale(1); }
}

/* Mobile Menu Toggle Button */
.vp-mobile-menu-toggle {
  display: none;
  background: linear-gradient(45deg, #ffc871, #c28b4e);
  border: none;
  padding: 0.8rem 1.2rem;
  border-radius: 8px;
  color: #1a1a1a;
  font-weight: 600;
  width: 100%;
  margin: 1rem 0;
  cursor: pointer;
  transition: all 0.3s ease;
}

.vp-mobile-menu-toggle:hover {
  opacity: 0.9;
  transform: translateY(-2px);
}

/* Sidebar Mobile Styles */
.vp-sidebar-mobile-active {
  display: flex !important;
  animation: slideDown 0.3s ease forwards;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.vp-aula-titulo {
  margin: 15px 0;
  font-size: 1.2em;
  color: #ffffff;
  text-align: center;
}

.vp-description-header {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.vp-tab-button {
  padding: 8px 16px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1em;
  color: #666;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease;
  
}

.vp-tab-button.active {
  color: #f8c26d;
  border-bottom-color: #c79051;
}

.vp-tab-button:hover {
  color: #007bff;
}

.vp-tab-button svg {
  margin-right: 5px;
}
/* Updated Media Queries */
@media screen and (max-width: 992px) {
  .vp-sidebar {
    display: none; /* Hide by default on mobile */
  }

  .vp-mobile-menu-toggle {
    display: block;
  }

  .vp-content {
    margin-top: 80px; /* Reduced top margin for mobile */
  }

  .vp-main {
    width: 100%;
    padding: 0;
  }
}

/* Mobile-specific animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Fix for container pushing */
.vp-video-section {
  position: relative;
  z-index: 2;
}

.vp-description,
.vp-avaliacao,
.vp-video-controls {
  position: relative;
  z-index: 1;
}

/* Progress bar enhancement */
.vp-progress-fill {
  transition: width 0.3s ease;
}

/* List item hover enhancement */
.vp-aula-item {
  position: relative;
  overflow: hidden;
}

/* Efeito de brilho no hover */
.vp-aula-item::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.05),
    transparent
  );
  transition: left 0.5s ease;
}

.vp-aula-item:hover::after {
  left: 100%;
}
/* Estilo para texto da aula */
.vp-aula-texto {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* Additional responsive breakpoints */
@media screen and (max-width: 480px) {
  .vp-video-section {
    margin: 0.5rem;
  }
  
  .vp-button {
    width: 100%;
    margin: 0.5rem 0;
  }
  
  .vp-avaliacao {
    justify-content: center;
  }
  
  .vp-star {
    font-size: 28px;
  }
}
/* Add any other existing styles from your VideoPlayer.css file */
@media screen and (min-width: 1920px) {
  .vp-video-section {
    height: auto;
    max-height: none; /* Remove max-height constraint */
    margin: 1rem; /* Increased spacing for larger screens */
  }
  
  .vp-video-frame {
    height: auto;
    min-height: 600px; /* Increased minimum height for larger screens */
  }
  
  .vp-content {
    padding: 2rem 3rem; /* Added more horizontal padding */
  }
}

/* Adicione estas classes no final do seu arquivo VideoPlayer.css */

/* Classe para fade-in */
.fade-in {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

/* Classe para fade-out */
.fade-out {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

/* Assegure-se de que os elementos com transiÃ§Ãµes comecem com opacidade 1 */
.vp-video-wrapper,
.vp-description {
  opacity: 1; /* Estado padrÃ£o */
}



/* Responsividade */
@media screen and (max-width: 1400px) {
  .vp-content {
    padding: 1.5rem;
    gap: 1.5rem;
  }
  
  .vp-sidebar {
    width: 300px;
    left: 1.5rem;
  }
  
  .vp-main {
    margin-left: 320px;
  }
}

@media screen and (max-width: 1200px) {
  .vp-content {
    padding: 1rem;
    gap: 1rem;
  }
  
  .vp-sidebar {
    width: 280px;
    left: 1rem;
  }
  
  .vp-main {
    margin-left: 300px;
  }
  
  .vp-header {
    padding: 0.75rem 1.5rem;
  }
}

@media screen and (max-width: 992px) {
  .vp-content {
    flex-direction: column;
    height: auto;
    margin-top: 850px;
  }
  
  .vp-sidebar {
    position: static;
    width: 100%;
    height: auto;
    padding-right: 0;
  }
  
  .vp-main {
    margin-left: 0;
  }
  
  .vp-video-section,
  .vp-video-frame {
    min-height: 300px;
  }
  
  .vp-aulas-lista {
    max-height: 400px;
  }
}

@media screen and (max-width: 768px) {
  .vp-header {
    padding: 0.5rem 1rem;
  }
  
  .vp-user-name {
    display: none;
  }
  
  .vp-video-section,
  .vp-video-frame {
    min-height: 250px;
  }
}

@media screen and (max-width: 480px) {
  .vp-content {
    padding: 0.75rem;
    margin-top: 850px;
  }
  
  .vp-header {
    height: 60px;
  }
  
  .vp-header-logo {
    height: 32px;
  }
  
  .vp-user-photo {
    width: 36px;
    height: 36px;
  }
  
  .vp-video-section,
  .vp-video-frame {
    min-height: 200px;
  }
}