.politica-page-unique {
  font-family: 'Arial', sans-serif;
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: clamp(20px, 5vh, 50px);
  background-color: #000;
}

.politica-header-unique {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: clamp(20px, 4vh, 40px);
  width: 90%;
  max-width: 1200px;
  padding: 0 20px;
}

.politica-logo-unique {
  height: clamp(80px, 15vw, 120px);
  width: auto;
  object-fit: contain;
}

.politica-btn-login-unique {
  background-color: #d6a456;
  color: white;
  padding: 12px 24px;
  text-decoration: none;
  border-radius: 5px;
  font-size: clamp(14px, 2vw, 16px);
  transition: background-color 0.3s ease;
  text-align: center;
  margin-top: 20px;
  width: clamp(120px, 30%, 200px);
}

.politica-btn-login-unique:hover {
  background-color: #cc7a00;
  transform: translateY(-2px);
}

.politica-content-unique {
  background-color: #111;
  padding: clamp(15px, 3vw, 30px);
  border-radius: 10px;
  width: 90%;
  max-width: 800px;
  margin: 0 auto 40px;
  text-align: justify;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.politica-title-unique {
  font-size: clamp(24px, 4vw, 28px);
  margin-bottom: clamp(15px, 3vw, 25px);
  text-align: center;
  font-weight: bold;
}

.politica-subtitle-unique {
  font-size: clamp(18px, 3vw, 22px);
  margin-top: clamp(15px, 3vw, 25px);
  font-weight: 600;
}

.politica-text-unique {
  font-size: clamp(14px, 2vw, 16px);
  line-height: 1.6;
  margin-bottom: 15px;
}

.politica-footer-unique {
  margin-top: auto;
  background-color: #000;
  color: white;
  padding: clamp(10px, 2vw, 20px);
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

.politica-content-unique a {
  text-decoration: none;
  color: #d6a456;
  transition: color 0.3s ease;
}

.politica-content-unique a:hover {
  color: #cc7a00;
}

/* Media Queries para diferentes tamanhos de tela */
@media screen and (max-width: 1024px) {
  .politica-header-unique {
    width: 95%;
  }
}

@media screen and (max-width: 768px) {
  .politica-page-unique {
    padding-top: 15px;
  }

  .politica-content-unique {
    width: 95%;
    padding: 15px;
  }
}

@media screen and (max-width: 480px) {
  .politica-header-unique {
    margin-bottom: 15px;
  }

  .politica-btn-login-unique {
    width: 80%;
    margin-top: 15px;
  }

  .politica-content-unique {
    border-radius: 8px;
    padding: 12px;
  }
}

/* Suporte para telas muito pequenas */
@media screen and (max-width: 320px) {
  .politica-title-unique {
    font-size: 20px;
  }

  .politica-subtitle-unique {
    font-size: 16px;
  }

  .politica-text-unique {
    font-size: 13px;
  }
}

/* Suporte para dispositivos de alta densidade de pixels */
@media screen and (-webkit-min-device-pixel-ratio: 2),
       screen and (min-resolution: 192dpi) {
  .politica-content-unique {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  }
}

/* Suporte para modo paisagem em dispositivos móveis */
@media screen and (max-height: 480px) and (orientation: landscape) {
  .politica-page-unique {
    padding-top: 10px;
  }

  .politica-header-unique {
    margin-bottom: 10px;
  }

  .politica-logo-unique {
    height: 60px;
  }
}