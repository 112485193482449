.painel-admin-container {
  background: url('../../imagens/fundo-login.webp') repeat center center fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;
  text-align: left;
  padding: 20px;
  box-sizing: border-box;
}

.painel-admin-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 0;
  padding: 0 20px;
  box-sizing: border-box;
}

.painel-admin-logo {
  height: 100px;
  margin: 0 auto;
}

.painel-admin-user-info {
  position: absolute;
  top: 140px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.painel-admin-user-info span {
  margin-bottom: 10px;
}

.painel-admin-button {
  background: linear-gradient(to right, #fac97b, #d5a05e);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.painel-admin-button:hover {
  background: #C28B4E;
}

.painel-admin-content {
  margin-top: 200px;
  padding-top: 50px;
  width: 100%;
  max-width: 900px;
  text-align: center;
  animation: fadeInUp 1s ease-out;
}

.painel-admin-title-gradient {
  font-size: 2.5em;
  background: linear-gradient(to right, #FFC871, #997844);
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 20px;
  animation: fadeInUp 1s ease-out;
}

.painel-admin-description {
  font-size: 1.2em;
  max-width: 800px;
  margin: 0 auto 20px;
  color: #FFFFFF;
  animation: fadeInUp 1s ease-out;
}

.painel-admin-menu {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
}

.painel-admin-item {
  text-align: center;
}

.painel-admin-item-img {
  width: 500px; /* Ajuste horizontal */
  height: auto; /* Manter proporção */
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.painel-admin-item-img:hover {
  transform: scale(1.1);
}

.painel-admin-item-button {
  background: linear-gradient(to right, #fac97b, #d5a05e);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.painel-admin-item-button:hover {
  background: #C28B4E;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .painel-admin-header {
    flex-direction: column;
    align-items: center;
  }

  .painel-admin-logo {
    height: 80px;
    margin-bottom: 10px;
  }

  .painel-admin-user-info {
    top: 90px;
  }

  .painel-admin-content {
    margin-top: 150px;
    padding: 0 20px;
  }

  .painel-admin-title-gradient {
    font-size: 2em;
  }

  .painel-admin-description {
    font-size: 1em;
  }

  .painel-admin-menu {
    flex-direction: column;
    align-items: center;
  }

  .painel-admin-item {
    margin-bottom: 20px;
  }

  .painel-admin-item-img {
    width: 200px; /* Tamanho ajustado para mobile */
    height: auto; /* Manter proporção no mobile */
  }
}
