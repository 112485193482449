
/* Container principal */
.treinamento-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #1a1a1a; 
  position: relative;
  isolation: isolate;
  z-index: 1;
  width: 100%;
}

/* ALERTA FIXO NO TOPO */
.treinamento-alerta-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2000; /* acima do cabeçalho */
  background-color: #f44336; /* vermelho de alerta */
  color: #fff;
  text-align: center;
  padding: 0.3rem 1rem; 
  font-weight: 600;
  font-size: 0.9rem; /* fonte menor */
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 36px; /* altura reduzida */
}

.treinamento-alerta-container p {
  margin: 0;
}

@media screen and (max-width: 768px) {
  .treinamento-alerta-container {
    font-size: 0.85rem;
    padding: 0.2rem 0.5rem;
  }
}

/* CABEÇALHO FIXO */
.treinamento-header {
  position: fixed;
  /* header logo abaixo do alerta (36px de altura) */
  top: 36px;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #1a1a1a;
  box-shadow: 0 4px 12px #d0995666;
  height: 70px;
}

.treinamento-header-logo {
  height: 40px;
  transition: transform 0.2s ease;
}

.treinamento-header-logo:hover {
  transform: scale(1.02);
}

.treinamento-logo {
  height: 100%;
  width: auto;
}

.treinamento-header-user {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}

.treinamento-user-name {
  font-weight: 600;
  color: #fff;
  font-size: 0.95rem;
}

/* Agora, .treinamento-content fica abaixo do ALERTA + HEADER 
   (36px do alerta + 70px do header = 106px de margem) */
.treinamento-content {
  display: flex;
  flex: 1;
  padding: 2rem;
  gap: 2rem;
  max-width: 99%;
  margin: 0 auto;
  width: 100%;
  margin-top: 106px; /* Ajuste para ficar logo abaixo do alerta + cabeçalho */
  height: calc(90vh - 70px);
  position: relative;
  background-color: #1a1a1a; /* Fundo por baixo */
}

/* MAIN */
.treinamento-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow-y: auto;
  padding-right: 1rem;
  min-width: 0;
  margin-left: 340px; /* Sidebar fixa no desktop */
}

.treinamento-main::-webkit-scrollbar {
  width: 6px;
}

.treinamento-main::-webkit-scrollbar-track {
  background: #2d2d2d;
  border-radius: 3px;
}

.treinamento-main::-webkit-scrollbar-thumb {
  background: #454545;
  border-radius: 3px;
}

.treinamento-main::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Seção de Vídeo */
.treinamento-video-section {
  width: calc(100% - 0px); 
  background: #2d2d2d;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  border: 1px solid #3d3d3d;
  min-height: 400px;
  position: relative;
  margin: 1rem 20px; 
}

.treinamento-video-frame {
  width: 100%;
  height: 100%;
  min-height: 400px;
  max-height: 720px; 
  border: none;
  aspect-ratio: 16/9;
  background: #000;
  object-fit: contain;
}

.treinamento-video-controls {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.treinamento-button {
  background: linear-gradient(45deg, #ffc871, #c28b4e);
  color: #1a1a1a;
  border: none;
  padding: 0.4rem 0.8rem;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
  font-size: 0.85rem;
}

.treinamento-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(194, 139, 78, 0.3);
  opacity: 0.95;
}

.treinamento-aula-titulo {
  margin: 15px 0;
  font-size: 1.2em;
  color: #ffffff;
  text-align: center;
}

/* Descrição */
.treinamento-description {
  background: #2d2d2d;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  color: #ffffff;
  line-height: 1.6;
  min-height: 200px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.treinamento-description h4 {
  color: #fff;
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  font-family: 'Inter', sans-serif;
  letter-spacing: -0.5px;
}

.treinamento-description a {
  color: #ffc871;
  text-decoration: none;
  transition: all 0.2s ease;
  border-bottom: 1px solid transparent;
  padding-bottom: 2px;
}

.treinamento-description a:hover {
  border-bottom-color: #ffc871;
  opacity: 0.9;
}

/* Sidebar Desktop */
.treinamento-sidebar {
  width: 320px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: calc(100vh - 90px);
  position: fixed;
  left: 2rem;
  top: 90px; /* 70px do header + 20px de "ajuste" */
  padding-right: 1rem;
  /* background do sidebar substituído por container interno */
}

/* Botão de fechar no menu vertical (mobile) */
.treinamentos-vertical-menu-close {
  background: none;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  align-self: flex-end;
  margin-bottom: 1rem;
}

/* Progresso */
.treinamento-progress {
  background: #2d2d2d;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.treinamento-progress h3 {
  color: #fff;
  font-size: 1.1rem;
  margin-bottom: 1rem;
  font-family: 'Inter', sans-serif;
}

.treinamento-progress-bar {
  width: 100%;
  height: 8px;
  background-color: #3d3d3d;
  border-radius: 4px;
  overflow: hidden;
  margin: 1rem 0;
}

.treinamento-progress-fill {
  height: 100%;
  background: linear-gradient(45deg, #ffc871, #c28b4e);
  transition: width 0.3s ease;
}

.modulo-progress-text {
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0.5rem;
  text-align: center;
}

/* Container de aulas */
.treinamento-aulas-container {
  background: linear-gradient(145deg, #2d2d2d, #252525);
  border-radius: 12px;
  border: 1px solid #3d3d3d;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  /* Para garantir que, em desktops, ainda haja rolagem sem cortar a última aula */
  height: auto;
  max-height: calc(100vh - 220px); /* Ajuste para exibir até o final */
}

.treinamento-modulo-header {
  background: linear-gradient(45deg, #1e1e1e, #2d2d2d);
  border-bottom: 2px solid #ffc87155;
  border-radius: 12px 12px 0 0;
  padding: 1.5rem;
  position: sticky;
  top: 0;
  z-index: 2;
}

.treinamento-modulo-header h3 {
  color: #ffc871;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  text-align: center;
  letter-spacing: 1px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

/* Lista de aulas */
.treinamento-aulas-lista {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  max-height: calc(100vh - 400px); /* Para garantir que o scroll vá até o fim */
}

.treinamento-aulas-lista::-webkit-scrollbar {
  width: 6px;
}

.treinamento-aulas-lista::-webkit-scrollbar-track {
  background: #2d2d2d;
  border-radius: 3px;
}

.treinamento-aulas-lista::-webkit-scrollbar-thumb {
  background: #454545;
  border-radius: 3px;
}

.treinamento-aulas-lista::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.treinamento-aulas-lista ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.treinamento-aula-item {
  background: linear-gradient(145deg, #2d2d2d, #252525);
  border: 1px solid #3d3d3d;
  border-radius: 8px;
  padding: 1rem 1.2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.treinamento-aula-item::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 4px;
  background: linear-gradient(to bottom, #ffc871, #c28b4e);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.treinamento-aula-item:hover {
  transform: translateX(4px);
  background: linear-gradient(145deg, #353535, #2d2d2d);
  border-color: #ffc87155;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.treinamento-aula-item:hover::before {
  opacity: 1;
}

.treinamento-aula-icon {
  font-size: 1.2rem;
  min-width: 24px;
  color: #666;
  transition: all 0.3s ease;
}

.treinamento-aula-item.treinamento-aula-atual {
  background: linear-gradient(145deg, #ffc87122, #c28b4e22);
  border-color: #ffc871;
}

.treinamento-aula-item.treinamento-aula-atual::before {
  opacity: 1;
}

.treinamento-aula-item.treinamento-aula-concluida .treinamento-aula-icon {
  color: #4CAF50;
}

.treinamento-aula-item span {
  color: #ffffff;
  font-size: 0.95rem;
  font-weight: 500;
  flex: 1;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

@keyframes checkmark {
  0% { transform: scale(0); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

.treinamento-aula-concluida .treinamento-aula-icon {
  animation: checkmark 0.5s ease-out forwards;
}

.treinamento-error {
  background-color: #3d2d2d;
  border: 1px solid #614444;
  color: #ff8080;
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Star Rating System */
.treinamento-avaliacao {
  display: flex;
  gap: 0.5rem;
  padding: 1rem 0;
  justify-content: center;
  flex-direction: row;
}

.treinamento-star {
  cursor: pointer;
  font-size: 24px;
  color: #4a4a4a;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.treinamento-star:hover {
  transform: scale(1.2);
}

.treinamento-star-active {
  color: #ffc871;
  animation: starPop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@keyframes starPop {
  0% { transform: scale(1); }
  50% { transform: scale(1.3); }
  100% { transform: scale(1); }
}

/* Botão "Ver Aulas" (mobile) */
.treinamento-mobile-menu-toggle {
  display: none;
  background: linear-gradient(45deg, #ffc871, #c28b4e);
  border: none;
  padding: 0.8rem 1.2rem;
  border-radius: 8px;
  color: #1a1a1a;
  font-weight: 600;
  width: 100%;
  margin: 1rem 0;
  cursor: pointer;
  transition: all 0.3s ease;
}

.treinamento-mobile-menu-toggle:hover {
  opacity: 0.9;
  transform: translateY(-2px);
}

/* Lógica do Menu Mobile (vertical) */
.treinamento-sidebar-mobile-active {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #1a1a1a;
  overflow-y: auto;
  z-index: 2500;
  display: block !important;
  padding: 1rem;
  animation: slideDown 0.3s ease forwards;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Tabs Descrição x Comentários */
.treinamento-description-header {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.treinamento-tab-button {
  padding: 8px 16px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1em;
  color: #666;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease;
}

.treinamento-tab-button.active {
  color: #f8c26d;
  border-bottom-color: #c79051;
}

.treinamento-tab-button:hover {
  color: #007bff;
}

.treinamento-tab-button svg {
  margin-right: 5px;
}

/* Responsividade geral */
@media screen and (max-width: 992px) {
  .treinamento-sidebar {
    display: none; /* Hide sidebar no mobile */
  }

  .treinamento-mobile-menu-toggle {
    display: block;
  }

  .treinamento-content {
    margin-top: 106px; /* Mantém abaixo do alerta + header */
  }

  .treinamento-main {
    width: 100%;
    padding: 0;
    margin-left: 0;
  }

  .treinamento-video-section,
  .treinamento-video-frame {
    min-height: 300px;
  }

  .treinamento-aulas-lista {
    max-height: none; /* Libera no mobile */
  }
}

@media screen and (max-width: 768px) {
  .treinamento-header {
    padding: 0.5rem 1rem;
  }
  
  .treinamento-video-section,
  .treinamento-video-frame {
    min-height: 250px;
  }
}

@media screen and (max-width: 480px) {
  .treinamento-content {
    padding: 0.75rem;
    margin-top: 106px; /* mantém logo abaixo do alerta + header */
  }
  
  .treinamento-header {
    height: 70px; /* permanece a mesma altura */
  }
  
  .treinamento-header-logo {
    height: 32px;
  }
  
  .treinamento-video-section,
  .treinamento-video-frame {
    min-height: 200px;
  }
  
  .treinamento-button {
    width: 100%;
    margin: 0.5rem 0;
  }
  
  .treinamento-star {
    font-size: 28px;
  }
}

/* Botões de navegação (home, etc.) */
.modulo-home-btn {
  background: linear-gradient(45deg, #ffc871, #c28b4e);
  color: #1a1a1a;
  border: none;
  padding: 0.6rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
}

.modulo-home-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(194, 139, 78, 0.3);
  opacity: 0.95;
}

/* Download link */
.treinamento-download-link {
  color: #ffc871;
  text-decoration: none;
  display: inline-block;
  margin-top: 1rem;
}

.treinamento-download-link:hover {
  opacity: 0.8;
}

/* Efeito “flash” ao hover dos itens */
.treinamento-aula-item {
  position: relative;
  overflow: hidden;
}

.treinamento-aula-item::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.05),
    transparent
  );
  transition: left 0.5s ease;
}

.treinamento-aula-item:hover::after {
  left: 100%;
}

/* Efeito ellipsis */
.treinamento-aula-texto {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Ajuste extra para telas menores */
@media screen and (max-height: 820px) {
  .treinamento-content {
    margin-top: 200px !important;
  }
}
