.planejador-prosperidade-container {
  background: url('../../imagens/fundo-login.webp') repeat center center fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;
  text-align: left;
  padding: 20px;
  box-sizing: border-box;
}

.planejador-prosperidade-header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 0;
  padding: 0 20px;
  box-sizing: border-box;
}

.planejador-prosperidade-logo {
  height: 200px;
}

.planejador-prosperidade-user-info {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  background: #0009;
  border-radius: 5px;
}

.planejador-prosperidade-user-info span {
  margin-right: 10px;
  color: white;
}

.planejador-prosperidade-button {
  background: linear-gradient(to right, #fac97b, #d5a05e);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.planejador-prosperidade-button:hover {
  background: #C28B4E;
}

.planejador-prosperidade-content {
  margin-top: 150px;
  padding-top: 100px;
  text-align: center;
}

.planejador-prosperidade-title-gradient {
  font-size: 2.5em;
  background: linear-gradient(to right, #FFC871, #997844);
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 20px;
}

.planejador-prosperidade-description {
  font-size: 1.2em;
  max-width: 800px;
}

.planejador-slide-in-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  animation: planejadorSlideIn 1s ease-out;
}

.planejador-slide-in-container.planejador-slide-in {
  animation: planejadorSlideIn 1s ease-out;
}

@keyframes planejadorSlideIn {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.planejador-budget-input {
  padding: 10px;
  font-size: 1.2em;
  border: 2px solid #ccc;
  border-radius: 5px;
  width: 200px;
  position: relative;
  padding-left: 30px;
  box-sizing: border-box;
}

.planejador-budget-input::before {
  content: 'R$';
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #333;
}

.planejador-generate-button {
  background-color: #f0ad4e;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1.2em;
  border-radius: 5px;
}

.planejador-prosperidade-results {
  display: none;
  animation: slideInFromBottom 1s ease-out;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
  margin-top: 20px;
}

.planejador-prosperidade-results h2 {
  font-size: 2em;
  color: #FFC871;
  margin-bottom: 20px;
}

.planejador-prosperidade-results h3 {
  font-size: 1.5em;
  color: #FFD700;
  margin-bottom: 10px;
}

.planejador-prosperidade-results p {
  font-size: 1.1em;
  margin-bottom: 10px;
}

.planejador-prosperidade-results ul {
  list-style: none;
  padding: 0;
}

.planejador-prosperidade-results li {
  font-size: 1.1em;
  margin-bottom: 5px;
}

.campaign-structure {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.campaign-block,
.adset {
  border: 1px solid white;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  text-align: center;
  position: relative;
  background-color: rgba(255, 255, 255, 0.1);
  animation: slideIn 1s ease-in-out;
}

.ad {
  margin-top: 10px;
  border: 1px solid white;
  padding: 5px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  animation: slideIn 1s ease-in-out;
}

@keyframes slideInFromBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Estilo para Desktop */
@media (min-width: 768px) {
  .campaign-structure {
    flex-direction: row;
  }

  .adsets-block {
    display: flex;
  }

  .adset {
    margin: 0 10px;
  }
}
/* Estilos para o botão "Salvar plano" */
.planejador-save-button {
  background-color: #4CAF50; /* Cor verde */
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 5px;
  cursor: pointer;
  margin: 20px 0;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.planejador-save-button:hover {
  background-color: #45a049; /* Verde escuro ao passar o mouse */
}

.planejador-save-button:disabled {
  background-color: #6c757d; /* Cinza quando desabilitado */
  cursor: not-allowed;
}

/* Estilos para o spinner de carregamento */
.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3); /* Fundo do spinner */
  border-top: 4px solid white; /* Cor do spinner */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  animation: spin 1s linear infinite;
}

/* Animação do spinner */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Estilos para a logo no plano salvo */
.print-logo {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}

/* Estilo para Mobile */
@media (max-width: 767px) {
  .planejador-prosperidade-header {
    flex-direction: column;
    align-items: center;
  }

  .planejador-prosperidade-logo {
    height: 100px;
    margin-bottom: 10px;
  }

  .planejador-prosperidade-user-info {
    top: 80px;
  }

  .planejador-prosperidade-user-info span {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .planejador-prosperidade-content {
    margin-top: 100px;
    padding: 0 20px;
  }

  .planejador-prosperidade-title-gradient {
    font-size: 2em;
  }

  .planejador-prosperidade-description {
    font-size: 1em;
  }

  .planejador-slide-in-container {
    flex-direction: column;
  }

  .planejador-generate-button {
    width: 100%;
    margin-top: 10px;
  }
}

.planejador-save-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 0;
}

.planejador-save-button:hover {
  background-color: #45a049;
}

.print-logo {
  width: 300px;
  margin-bottom: 20px;
}

.explanation-icon {
  margin-right: 8px;
  color: #FFD700; /* Cor dourada para os ícones */
}

.explanation {
  margin-top: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 15px;
  border-radius: 5px;
  text-align: left;
}

.explanation-title {
  font-size: 1.5em;
  color: #FFC871;
  margin-bottom: 10px;
}

.explanation-text {
  font-size: 1.1em;
  margin-bottom: 10px;
}

.explanation ul {
  list-style-type: none;
  padding-left: 0;
}

.explanation li {
  margin-bottom: 10px;
}

.explanation li:before {
  content: '⚙️'; /* Exemplo de ícone antes de cada item */
  margin-right: 8px;
  color: #FFD700;
}

.explanation p {
  margin-bottom: 15px;
}

.explanation p strong {
  font-weight: bold;
}

.highlighted-name {
  color: #ffffff; /* Altere para a cor desejada */
  font-weight: bold; /* Opcional: Deixe o nome em negrito */
}

.planejador-slide-in-container {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.planejador-slide-in-container.planejador-slide-in {
  opacity: 1;
  transform: translateX(0);
}

/* CSS específico para dispositivos móveis */
@media (max-width: 768px) {
    body {
        position: relative; /* Garante que o posicionamento não seja afetado */
        overflow-x: hidden; /* Bloqueia a rolagem horizontal */
    }
}

