/* VideoPlayer.css - Layout Refinado e Melhorias Visuais */

/* Container Principal */
.video-player-container {
  display: flex;
  flex-direction: column;
  padding: 0;
  font-family: 'Inter', sans-serif;
  background-color: #f8fafc;
  color: #0f172a;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  min-width: 320px; /* Evita que a tela fique muito estreita */
}

/* Mensagem de Erro */
.video-player-error {
  background-color: rgba(239, 68, 68, 0.95);
  color: white;
  padding: 1rem 1.5rem;
  text-align: center;
  font-weight: 600;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  border-bottom: 2px solid #dc2626;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Layout Principal */
.video-player-layout {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 60px);
  gap: 24px;
  padding: 24px;
  box-sizing: border-box;
  flex-wrap: nowrap; /* MantÃ©m os elementos na mesma linha */
  overflow: hidden; /* Evita scroll horizontal indesejado */
}

/* Lista de Aulas (Sidebar Fixo) */
.video-player-info {
  flex: 0 0 360px; /* Largura fixa */
  max-width: 360px;
  background-color: white;
  overflow: hidden;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
  min-width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* Lista de Aulas */
.aulas-lista {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.aulas-lista h3 {
  margin-bottom: 20px;
  color: #0f172a;
  font-size: 1.25rem;
  font-weight: 600;
  border-bottom: 2px solid #e2e8f0;
  padding-bottom: 12px;
}

/* Lista de Aulas Remodelada */
.aulas-lista ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: auto; /* Ativa o scroll vertical */
  overflow-x: hidden; /* Evita scroll horizontal */
  flex: 1;
}

/* EstilizaÃ§Ã£o da Scrollbar Vertical */
.aulas-lista ul::-webkit-scrollbar {
  width: 6px;
}

.aulas-lista ul::-webkit-scrollbar-thumb {
  background-color: #cbd5e1;
  border-radius: 3px;
}

.aulas-lista ul::-webkit-scrollbar-track {
  background-color: #f1f5f9;
  border-radius: 3px;
}

.aulas-lista ul {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #cbd5e1 #f1f5f9; /* Firefox */
}

/* Itens da Lista de Aulas */
.aulas-lista li {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background-color: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
}

.aulas-lista li.assistindo {
  border: 2px solid #1d4ed8; /* Azul mais escuro */
  background-color: #bfdbfe; /* Azul claro mais intenso */
  color: #1e3a8a; /* Texto azul escuro */
}

.aulas-lista li:hover {
  background-color: #f1f5f9;
  transform: translateX(4px);
  border-color: #3b82f6;
}

.aulas-lista li.concluido {
  background-color: #f0fdf4;
  border-left: 4px solid #22c55e;
}

.aulas-lista li.concluido:hover {
  background-color: #dcfce7;
}

.aula-icon {
  color: #3b82f6;
  margin-right: 12px;
  flex-shrink: 0;
  font-size: 1.2rem;
}

/* SeÃ§Ã£o Principal (VÃ­deo e DescriÃ§Ã£o) */
.video-player-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
  padding: 24px;
  overflow: hidden;
  min-width: 0;
}

/* Player de VÃ­deo */
.video-player-video {
  position: relative;
  width: 100%;
  max-width: 1920px; /* Limita a largura mÃ¡xima do vÃ­deo */
  margin: 0 auto; /* Centraliza o vÃ­deo */
  background-color: #1e293b;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
  aspect-ratio: 16 / 9; /* MantÃ©m a proporÃ§Ã£o 16:9 */
  margin-bottom: 24px; /* Aumenta o espaÃ§amento abaixo do vÃ­deo */
}

.video-player-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

/* ContÃªiner dos elementos abaixo do vÃ­deo */
.video-player-content {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

/* Barra de Progresso Melhorada */
.progress-label {
  font-size: 0.875rem;
  color: #64748b;
  margin-bottom: 8px;
  font-weight: 500;
}

.progress-bar {
  width: 100%;
  height: 12px;
  border-radius: 6px;
  overflow: hidden;
  background-color: #e0f2fe; /* Azul claro quando vazia */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 8px; /* EspaÃ§amento abaixo da barra */
}

.progress-bar > div {
  height: 100%;
  background: linear-gradient(90deg, #3b82f6 0%, #1d4ed8 100%); /* Azul gradiente */
  transition: width 0.5s ease;
  border-radius: 6px 0 0 6px;
}

.video-player-progress-text {
  font-size: 0.875rem;
  color: #64748b;
  text-align: right;
  margin-top: 4px;
  font-weight: 500;
}

/* AÃ§Ãµes do Player */
.video-player-actions {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

/* BotÃ£o Concluir Aula */
.concluir-avancar-button {
  background: linear-gradient(90deg, #f43f5e, #e11d48);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 0.75rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
  display: flex;
  align-items: center;
  gap: 6px;
  min-width: 100px;
}

.concluir-avancar-button:hover {
  background: linear-gradient(90deg, #e11d48, #be123c);
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(225, 29, 72, 0.3);
}

.concluir-avancar-button:active {
  transform: translateY(0);
  box-shadow: none;
}

/* DescriÃ§Ã£o da Aula */
.descricao-aula {
  padding: 24px;
  background-color: white;
  border-top: 1px solid #e2e8f0;
  border-radius: 0 0 12px 12px;
  margin-top: 16px;
  flex-shrink: 0;
}

.descricao-aula h4 {
  margin-bottom: 16px;
  color: #0f172a;
  font-size: 1.125rem;
  font-weight: 600;
}

.descricao-aula p {
  line-height: 1.6;
  color: #475569;
  font-size: 0.875rem;
}

/* Download de Material */
.download-material a {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: white;
  text-decoration: none;
  padding: 10px 18px;
  background: linear-gradient(90deg, #3b82f6 0%, #2563eb 100%);
  border-radius: 6px;
  transition: all 0.2s ease;
  font-weight: 500;
  font-size: 0.75rem;
  margin-top: 16px;
  box-shadow: 0 4px 6px rgba(37, 99, 235, 0.2);
}

.download-material a:hover {
  background: linear-gradient(90deg, #2563eb 0%, #1d4ed8 100%);
  transform: translateY(-1px);
  box-shadow: 0 6px 8px rgba(37, 99, 235, 0.3);
}

/* Scrollbar Oculta para InformaÃ§Ã£o da Aula */
.video-player-info::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.video-player-info {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

/* Media Queries */

/* Ajustes para tablets e telas mÃ©dias */
@media (max-width: 1280px) {
  .video-player-layout {
    padding: 16px;
    gap: 16px;
  }

  .video-player-info {
    max-width: 360px;
  }
}

/* Ajustes para dispositivos mÃ³veis e telas pequenas */
@media (max-width: 1024px) {
  .video-player-layout {
    padding: 16px;
    gap: 16px;
    flex-direction: column;
    align-items: stretch;
  }

  .video-player-info {
    max-width: 100%;
    flex: none;
    height: auto;
  }

  .aulas-lista ul {
    flex: none;
    max-height: 250px;
  }
}

@media (max-width: 768px) {
  .video-player-container {
    padding: 12px;
  }

  .video-player-layout {
    padding: 12px;
    gap: 12px;
    flex-direction: column;
  }

  .video-player-main {
    padding: 16px;
  }

  .video-player-info {
    max-width: 100%;
    height: auto;
    padding: 16px;
    min-width: unset;
  }

  .aulas-lista h3 {
    font-size: 1.125rem;
  }

  .aulas-lista ul {
    max-height: 200px;
  }

  .descricao-aula {
    padding: 16px;
  }

  .download-material a {
    width: 100%;
    justify-content: center;
  }

  .concluir-avancar-button {
    width: 100%;
    justify-content: center;
    padding: 8px 16px;
    font-size: 0.75rem;
    min-width: 100px;
  }
}