.tailwind-scope {
@tailwind base;
@tailwind components;
 @tailwind utilities;
 }
 
 
.tailwind-metafinaceira {
@tailwind base;
@tailwind components;
 @tailwind utilities;
 }
 

/* Animações Personalizadas */
@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fade-in 0.6s ease-out forwards;
}