/* Estilização com base na imagem enviada */

.usuarios-online-container-unique {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.6); /* Fundo transparente */
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.titulo-usuarios-online-unique {
  text-align: center;
  font-size: 24px;
  color: #fff; /* Cor branca para contraste */
  margin-bottom: 20px;
}

.usuarios-online-scroll-unique {
  max-height: none;
  overflow-y: hidden;
  padding: 10px;
}

.scroll-active-unique {
  max-height: 400px; /* Ativa o scroll quando houver 10 ou mais usuários */
  overflow-y: auto;
}

.usuarios-online-list-unique {
  list-style-type: none;
  padding: 0;
}

.usuario-online-item-unique {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1); /* Fundo transparente leve */
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.usuario-online-item-unique:hover {
  background-color: rgba(224, 247, 250, 0.2); /* Hover leve */
}

.user-icon-unique, .clock-icon-unique {
  margin-right: 10px;
  color: #ffc107; /* Cor amarela para ícones */
}

.user-name-unique {
  font-size: 18px;
  font-weight: bold;
  flex: 1;
  color: #fff;
}

.user-email-unique {
  flex: 2;
  color: #fff;
}

.user-last-activity-unique {
  font-size: 14px;
  color: #ccc;
  flex: 1;
}

.no-users-unique {
  text-align: center;
  color: #ccc;
}

.remove-user-button {
  background-color: #ff4d4f;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: calc(18% - 20px);
  background: linear-gradient(90deg, #ffcf7f, #c28b4e);
}

.remove-user-button:hover {
  background-color: #ff7875;
}


/* Responsividade para Mobile */
@media (max-width: 600px) {
  .usuarios-online-container-unique {
    width: 100%;
    padding: 15px;
  }

  .titulo-usuarios-online-unique {
    font-size: 20px;
  }

  .usuario-online-item-unique {
    flex-direction: column; /* Alinhar verticalmente em telas menores */
    justify-content: center;
  }

  .user-name-unique, .user-email-unique, .user-last-activity-unique {
    font-size: 14px;
    margin-bottom: 5px;
  }
}
