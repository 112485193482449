/* Container principal dos comentários */
.comentarioYoutube-container {
  width: 100%;
  padding: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  color: #E0E0E0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  background-color: #1f1f1f;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}


/* Instrução para o usuário */
.comentarioYoutube-instrucao {
  background: #2A2A2A;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 20px;
  text-align: center;
}

.comentarioYoutube-instrucao p {
  color: #E0E0E0;
  margin-bottom: 12px;
  font-size: 16px;
}

.comentarioYoutube-open-modal-button {
  background-color: #ff0000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.comentarioYoutube-open-modal-button:hover {
  background-color: #cc0000;
}

/* Modal Overlay */
.comentarioYoutube-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Conteúdo do Modal */
.comentarioYoutube-modal-content {
  position: relative;
  background-color: #000;
  padding: 20px;
  border-radius: 8px;
  max-width: 800px;
  width: 90%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

/* Botão para fechar o modal */
.comentarioYoutube-close-modal-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #fff;
  transition: color 0.3s;
}

.comentarioYoutube-close-modal-button:hover {
  color: #ff0000;
}

/* Iframe do YouTube */
.comentarioYoutube-youtube-iframe {
  width: 100%;
  height: 450px;
  border-radius: 8px;
}

/* Título dos comentários */
.comentarioYoutube-titulo {
  font-size: 24px;
  margin-bottom: 20px;
  color: #FFFFFF;
}

/* Lista de comentários com scroll */
.comentarioYoutube-lista {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 600px;
  overflow-y: auto;
  padding-right: 16px;
}

/* Estilização do item de comentário */
.comentarioYoutube-item {
  background: #2A2A2A;
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 16px;
  border: 1px solid #3A3A3A;
  transition: all 0.2s ease;
}

.comentarioYoutube-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

/* Conteúdo do comentário */
.comentarioYoutube-conteudo {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

/* Imagem de perfil do autor */
.comentarioYoutube-imagem {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #3A3A3A;
}

/* Container para texto do comentário */
.comentarioYoutube-texto-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Nome do autor */
.comentarioYoutube-autor {
  font-weight: 600;
  color: #FFFFFF;
  font-size: 16px;
}

/* Texto do comentário */
.comentarioYoutube-texto {
  color: #E0E0E0;
  line-height: 1.6;
  font-size: 15px;
  margin: 8px 0;
}

/* Data do comentário */
.comentarioYoutube-data {
  color: #888888;
  font-size: 13px;
}

/* Botão para responder */
.comentarioYoutube-responder-button {
  background: transparent;
  border: none;
  color: #888888;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  border-radius: 6px;
  transition: all 0.2s ease;
  font-size: 14px;
  margin-top: 8px;
  align-self: flex-start;
}

.comentarioYoutube-responder-button:hover {
  color: #FFFFFF;
  background: rgba(255, 255, 255, 0.1);
}

/* Lista de respostas */
.comentarioYoutube-respostas-lista {
  list-style: none;
  padding: 0;
  margin: 12px 0 0 48px;
  border-left: 2px solid #3A3A3A;
  padding-left: 24px;
}

/* Estilização da resposta */
.comentarioYoutube-resposta-item {
  background: #2A2A2A;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 12px;
  border: 1px solid #3A3A3A;
  transition: all 0.2s ease;
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.comentarioYoutube-resposta-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Imagem de perfil da resposta */
.comentarioYoutube-resposta-imagem {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #3A3A3A;
}

/* Container para texto da resposta */
.comentarioYoutube-resposta-texto-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Nome do autor da resposta */
.comentarioYoutube-resposta-autor {
  font-weight: 600;
  color: #FFFFFF;
  font-size: 14px;
}

/* Texto da resposta */
.comentarioYoutube-resposta-texto {
  color: #E0E0E0;
  line-height: 1.5;
  font-size: 14px;
  margin: 6px 0;
}

/* Data da resposta */
.comentarioYoutube-resposta-data {
  color: #888888;
  font-size: 12px;
}

/* Responsividade */
@media (max-width: 768px) {
  .comentarioYoutube-container {
    padding: 16px;
  }

  .comentarioYoutube-instrucao p {
    font-size: 14px;
  }

  .comentarioYoutube-open-modal-button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .comentarioYoutube-titulo {
    font-size: 20px;
  }

  .comentarioYoutube-lista {
    max-height: 500px;
  }

  .comentarioYoutube-item {
    padding: 12px;
  }

  .comentarioYoutube-conteudo {
    flex-direction: column;
    align-items: flex-start;
  }

  .comentarioYoutube-imagem {
    width: 40px;
    height: 40px;
  }

  .comentarioYoutube-modal-content {
    padding: 10px;
  }

  .comentarioYoutube-youtube-iframe {
    height: 300px;
  }

  .comentarioYoutube-respostas-lista {
    margin-left: 24px;
    padding-left: 16px;
  }

  .comentarioYoutube-resposta-imagem {
    width: 36px;
    height: 36px;
  }

  .comentarioYoutube-autor {
    font-size: 14px;
  }

  .comentarioYoutube-texto {
    font-size: 14px;
  }

  .comentarioYoutube-data {
    font-size: 12px;
  }

  .comentarioYoutube-responder-button {
    font-size: 13px;
  }

  .comentarioYoutube-resposta-autor {
    font-size: 13px;
  }

  .comentarioYoutube-resposta-texto {
    font-size: 13px;
  }

  .comentarioYoutube-resposta-data {
    font-size: 11px;
  }
}

/* Scroll personalizado para a lista de comentários */
.comentarioYoutube-lista::-webkit-scrollbar {
  width: 6px;
}

.comentarioYoutube-lista::-webkit-scrollbar-track {
  background: rgba(58, 58, 58, 0.3);
  border-radius: 3px;
}

.comentarioYoutube-lista::-webkit-scrollbar-thumb {
  background: #0066FF;
  border-radius: 3px;
}

.comentarioYoutube-lista::-webkit-scrollbar-thumb:hover {
  background: #0052CC;
}
