/* ConversaoMagica.css */

/* ===========================================
   Contêiner Principal
   =========================================== */
.conversao-magica-container {
  background: url('../../imagens/fundo-login.webp') repeat center center fixed;
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  text-align: left;
  padding: 0px;
  box-sizing: border-box;
  scroll-behavior: smooth; /* Adicionando scroll suave */
  font-family: 'Inter', 'Segoe UI', 'Roboto', sans-serif; /* Consistência de fonte */
  position: relative;
  overflow-x: hidden; /* Previne scroll horizontal indesejado */
}

/* Classe para ocultar elementos */
.conversao-magica-container .hidden {
  display: none !important;
}

/* ===========================================
   Cabeçalho
   =========================================== */
.conversao-magica-container .conversao-magica-header {
  width: 100%;
  display: flex;
  justify-content: space-between; /* Distribui os itens com espaço entre eles */
  align-items: center;
  padding: 1.5rem 2.5rem;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(12px);
  z-index: 100; /* Z-index alto para sobrepor outros elementos, se necessário */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  border-bottom:  1px solid #bfa066; /* Borda inferior */
  transition: all 0.3s ease;
  height: 80px; /* Altura fixa do cabeçalho */
  flex-shrink: 0; /* Impede que o cabeçalho encolha */
  animation: fadeInDown 1s ease-out;
}

.conversao-magica-container .conversao-magica-header:hover {
  background: rgba(0, 0, 0, 0.9);
}

/* Logo no Cabeçalho */
.conversao-magica-container .conversao-magica-logo {
  height: 100px;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.conversao-magica-container .conversao-magica-logo:hover {
  transform: scale(1.05);
}

/* Botão de Voltar no Cabeçalho */
.conversao-magica-container .conversao-magica-back {
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  color: white;
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  font-weight: 500;
}

.conversao-magica-container .conversao-magica-back:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(255, 255, 255, 0.3);
}

/* Informações do Usuário no Cabeçalho */
.conversao-magica-container .conversao-magica-user-info {
  display: inline-flex;
  align-items: center;
  gap: 1.5rem;
  background: rgba(255, 255, 255, 0.1);
  padding: 0.1rem 0.5rem; /* Reduzido para diminuir o tamanho do background */
  border-radius: 12px; /* Ajustado para menor borda */
  transition: all 0.3s ease;
  animation: fadeInRight 1s ease-out;
}

.conversao-magica-container .conversao-magica-user-info:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: translateY(-2px);
}

.conversao-magica-container .conversao-magica-user-email {
  color: #fff;
  font-weight: 500;
  position: relative;
  padding-bottom: 2px;
}

.conversao-magica-container .conversao-magica-user-email::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: #4CAF50;
  transition: width 0.3s ease;
}

.conversao-magica-container .conversao-magica-user-email:hover::after {
  width: 100%;
}

/* Botão de Logout no Cabeçalho */
.conversao-magica-container .conversao-magica-logout {
  background: rgba(244, 67, 54, 0.1);
  border: 1px solid rgba(244, 67, 54, 0.3);
  color: white;
  padding: 0.5rem 1rem; /* Reduzido para diminuir o tamanho do botão */
  border-radius: 12px; /* Ajustado para menor borda */
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.conversao-magica-container .conversao-magica-logout:hover {
  background: rgba(244, 67, 54, 0.2);
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(244, 67, 54, 0.2);
}

/* ===========================================
   Conteúdo Principal
   =========================================== */
.conversao-magica-container .conversao-magica-content {
  margin-top: 5px; /* Espaço entre o cabeçalho e o conteúdo */
  padding: 2rem;
  flex: 1; /* Ocupa espaço disponível */
  max-width: 1200px; /* Ajustado para maior largura */
  width: 100%;
  display: flex;
  flex-direction: column; /* Empilhar os componentes verticalmente */
  gap: 2rem; /* Espaçamento entre os componentes */
  box-sizing: border-box;
  animation: fadeInUp 1s ease-out;
  
}

/* ===========================================
   Layout Principal (Calculadora e Histórico)
   =========================================== */
.conversao-magica-container .conversao-magica-main {
  display: flex;
  flex-direction: row; /* Lado a lado no desktop */
  gap: 2.5rem;
  max-width: 1200px; /* Consistência com o conteúdo principal */
  width: 100%;
  flex: 1; /* Ocupa espaço disponível */
  box-sizing: border-box;
  animation: fadeInUp 1s ease-out;
}

@media (max-width: 768px) {
  .conversao-magica-container .conversao-magica-main {
    flex-direction: column; /* Empilhar em telas menores */
    margin-top: 10px; /* Espaço entre o cabeçalho e o conteúdo */
  }
}

/* ===========================================
   Calculator e History
   =========================================== */
.conversao-magica-container .conversao-magica-calculator,
.conversao-magica-container .conversao-magica-history {
  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(12px);
  border-radius: 20px;
  padding: 2.5rem;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  flex: 1; /* Ocupa espaço igual */
  animation: slideInLeft 1s ease-out;
}

.conversao-magica-container .conversao-magica-history {
  animation: slideInRight 1s ease-out;
}

/* ===========================================
   Títulos
   =========================================== */
.conversao-magica-container .conversao-magica-calculator h1,
.conversao-magica-container .conversao-magica-history-header h2 {
  margin: 0 0 2rem;
  font-size: 2.0em; /* Ajustado para maior destaque */
  color: #fff;
  position: relative;
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  animation: fadeInUp 1s ease-out;
}

.conversao-magica-container .conversao-magica-calculator h1::after,
.conversao-magica-container .conversao-magica-history-header h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 3px;
  background: #4CAF50; /* Cor diferente para cada título */
  border-radius: 3px;
}

.conversao-magica-container .conversao-magica-history-header h2::after {
  background: #2196F3;
}

/* ===========================================
   Formulário
   =========================================== */
.conversao-magica-container .conversao-magica-form {
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
  flex: 1; /* Ocupa espaço disponível */
}

.conversao-magica-container .conversao-magica-input-group {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.conversao-magica-container .conversao-magica-input-group label {
  font-size: 0.95rem;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  transition: color 0.3s ease;
}

.conversao-magica-container .conversao-magica-input-group:hover label {
  color: #4CAF50;
}

.conversao-magica-container .conversao-magica-input-group input {
  background: rgba(255, 255, 255, 0.08);
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 1rem;
  color: white;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.conversao-magica-container .conversao-magica-input-group input:hover {
  background: rgba(255, 255, 255, 0.12);
}

.conversao-magica-container .conversao-magica-input-group input:focus {
  outline: none;
  border-color: #4CAF50;
  box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.2);
}

/* ===========================================
   Botões
   =========================================== */
.conversao-magica-container .conversao-magica-buttons {
  display: flex;
  gap: 1.25rem;
  margin-top: 1.5rem;
  flex-direction: row; /* Mantém em linha no desktop */
}

.conversao-magica-container .conversao-magica-calculate,
.conversao-magica-container .conversao-magica-save {
  flex: 1;
  padding: 1rem;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  transition: all 0.3s ease;
}

.conversao-magica-container .conversao-magica-calculate {
  background: #4CAF50;
  color: white;
  box-shadow: 0 4px 15px rgba(76, 175, 80, 0.3);
}

.conversao-magica-container .conversao-magica-calculate:hover {
  background: #45a049;
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(76, 175, 80, 0.4);
}

.conversao-magica-container .conversao-magica-save {
  background: #2196F3;
  color: white;
  box-shadow: 0 4px 15px rgba(33, 150, 243, 0.3);
}

.conversao-magica-container .conversao-magica-save:hover {
  background: #1e88e5;
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(33, 150, 243, 0.4);
}

/* ===========================================
   Resultados
   =========================================== */
.conversao-magica-container .conversao-magica-result {
  margin-top: 2.5rem;
  text-align: center;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 12px;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.conversao-magica-container .conversao-magica-result:hover {
  background: rgba(255, 255, 255, 0.12);
  transform: scale(1.02);
}

.conversao-magica-container .conversao-magica-result h2 {
  font-size: 2.25rem;
  margin-bottom: 1rem;
  color: #4CAF50;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* ===========================================
   Botão de Dicas
   =========================================== */
.conversao-magica-container .conversao-magica-tips-button {
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.2);
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.conversao-magica-container .conversao-magica-tips-button:hover {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.3);
  transform: translateY(-2px);
}

/* ===========================================
   Histórico de Conversões
   =========================================== */
.conversao-magica-container .conversao-magica-history {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.conversao-magica-container .conversao-magica-history-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.conversao-magica-container .conversao-magica-history-header h2 {
  margin: 0;
  font-size: 2rem;
  color: #fff;
  position: relative;
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.conversao-magica-container .conversao-magica-history-header h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 3px;
  background: #2196F3;
  border-radius: 3px;
}

/* Botão de Limpar Histórico */
.conversao-magica-container .conversao-magica-clear {
  background: #f44336;
  color: white;
  border: none;
  padding: 0.5rem 1rem; /* Reduzido para diminuir o tamanho do botão */
  border-radius: 12px; /* Ajustado para menor borda */
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  transition: all 0.3s ease;
  font-weight: 500;
  box-shadow: 0 4px 15px rgba(244, 67, 54, 0.3);
}

.conversao-magica-container .conversao-magica-clear:hover {
  background: #e53935;
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(244, 67, 54, 0.4);
}

/* ===========================================
   Média Semanal
   =========================================== */
.conversao-magica-container .conversao-magica-weekly-average {
  background: rgba(255, 255, 255, 0.08);
  padding: 1.5rem;
  border-radius: 12px;
  margin-bottom: 2rem;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.conversao-magica-container .conversao-magica-weekly-average:hover {
  background: rgba(255, 255, 255, 0.12);
  transform: translateX(5px);
  border-color: rgba(255, 255, 255, 0.2);
}

.conversao-magica-container .conversao-magica-weekly-average h3 {
  margin: 0;
  font-size: 1.6rem;
  color: #4CAF50;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* ===========================================
   Lista de Histórico
   =========================================== */
.conversao-magica-container .conversao-magica-history-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 400px; /* Ajuste conforme necessário */
  overflow-y: auto;
  padding-right: 0.75rem;
  scroll-behavior: smooth;
}

.conversao-magica-container .conversao-magica-history-list::-webkit-scrollbar {
  width: 8px;
}

.conversao-magica-container .conversao-magica-history-list::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
}

.conversao-magica-container .conversao-magica-history-list::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  transition: background 0.3s ease;
}

.conversao-magica-container .conversao-magica-history-list::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}

.conversao-magica-container .conversao-magica-history-item {
  background: rgba(255, 255, 255, 0.08);
  border-radius: 12px;
  padding: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.conversao-magica-container .conversao-magica-history-item:hover {
  background: rgba(255, 255, 255, 0.12);
  transform: translateX(5px);
  border-color: rgba(255, 255, 255, 0.2);
}

.conversao-magica-container .conversao-magica-history-date {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.conversao-magica-container .conversao-magica-history-date span:first-child {
  font-weight: 600;
  font-size: 1.1rem;
}

.conversao-magica-container .conversao-magica-history-date span:nth-child(2) {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.95rem;
  text-transform: capitalize;
}

.conversao-magica-container .conversao-magica-history-date span:last-child {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.85rem;
}

.conversao-magica-container .conversao-magica-history-stats {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.conversao-magica-container .conversao-magica-history-stats span {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.95rem;
}

.conversao-magica-container .conversao-magica-history-stats span svg {
  color: #FFD700; /* Cor dourada para ícones */
}

.conversao-magica-container .conversao-magica-history-rate {
  color: #FFD700; /* Dourado para destaque */
}

.conversao-magica-container .conversao-magica-history-actions {
  display: flex;
  gap: 0.75rem;
}

.conversao-magica-container .conversao-magica-history-button {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.conversao-magica-container .conversao-magica-history-button:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

.conversao-magica-container .conversao-magica-history-button.delete {
  background: rgba(244, 67, 54, 0.1);
}

.conversao-magica-container .conversao-magica-history-button.delete:hover {
  background: rgba(244, 67, 54, 0.2);
}

/* ===========================================
   Estado Vazio
   =========================================== */
.conversao-magica-container .conversao-magica-empty-state {
  text-align: center;
  padding: 3rem 2rem;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 12px;
  margin-top: 2rem;
}

.conversao-magica-container .conversao-magica-empty-state img {
  width: 200px;
  height: auto;
  margin-bottom: 1.5rem;
  opacity: 0.7;
}

.conversao-magica-container .conversao-magica-empty-state h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: rgba(255, 255, 255, 0.9);
}

.conversao-magica-container .conversao-magica-empty-state p {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 1.5rem;
}

/* ===========================================
   Tooltip
   =========================================== */
.conversao-magica-container .conversao-magica-tooltip {
  position: relative;
  display: inline-block;
}

.conversao-magica-container .conversao-magica-tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.conversao-magica-container .tooltip-text {
  visibility: hidden;
  background: rgba(0, 0, 0, 0.9);
  color: white;
  text-align: center;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%) translateY(10px);
  white-space: nowrap;
  font-size: 0.85rem;
  opacity: 0;
  transition: all 0.3s ease;
}

/* ===========================================
   Estados de Carregamento
   =========================================== */
.conversao-magica-container .conversao-magica-loading {
  position: relative;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.conversao-magica-container .conversao-magica-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top-color: #4CAF50;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* ===========================================
   Estados de Erro
   =========================================== */
.conversao-magica-container .conversao-magica-error {
  background: rgba(244, 67, 54, 0.1);
  border: 1px solid rgba(244, 67, 54, 0.3);
  padding: 1rem;
  border-radius: 12px;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #f44336;
}

/* ===========================================
   Responsividade
   =========================================== */

/* Tablets e abaixo */
@media screen and (max-width: 1200px) {
  .conversao-magica-container .conversao-magica-main {
    flex-direction: column; /* Empilhar em telas menores */
    gap: 2rem;
    margin-top: 10px; /* Espaço entre o cabeçalho e o conteúdo */
    
  }

  .conversao-magica-container .conversao-magica-calculator,
  .conversao-magica-container .conversao-magica-history {
    padding: 2rem;
    gap: 2rem; /* Espaçamento entre os componentes */
     margin-top: 10px; /* Espaço entre o cabeçalho e o conteúdo */
  }
}

/* Dispositivos Médios */
@media screen and (max-width: 768px) {
  .conversao-magica-container .conversao-magica-header {
    padding: 1rem;
    flex-direction: column;
    gap: 1rem;
    height: auto; /* Ajusta altura para conteúdo empilhado */
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Garantir borda em mobile */
    margin-top: 10px; /* Espaço entre o cabeçalho e o conteúdo */
    
  }

  .conversao-magica-container .conversao-magica-user-info {
    flex-direction: column; /* Alterado para coluna */
    padding: 0.5rem 1rem; /* Reduzido para diminuir o tamanho do background */
    gap: 0.75rem;
    width: 100%;
    text-align: center;
  }

  .conversao-magica-container .conversao-magica-main {
    flex-direction: column; /* Garantir layout em coluna em telas menores */
    height: auto;
    overflow-y: visible; /* Permite rolagem interna se necessário */
  }

  .conversao-magica-container .conversao-magica-calculator h1,
  .conversao-magica-container .conversao-magica-history-header h2 {
    font-size: 1.5rem;
  }

  .conversao-magica-container .conversao-magica-buttons {
    flex-direction: column;
  }

  .conversao-magica-container .conversao-magica-history-item {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }

  .conversao-magica-container .conversao-magica-history-stats {
    flex-direction: column;
    align-items: center;
  }

  .conversao-magica-container .conversao-magica-history-actions {
    width: 100%;
    justify-content: center;
  }

  .conversao-magica-container .conversao-magica-content {
    margin-top: 160px; /* Ajuste conforme a altura total do cabeçalho em mobile */
    
  }

  .conversao-magica-container .conversao-magica-back {
    margin-bottom: 1rem; /* Espaço entre o botão e o conteúdo abaixo */
  }
}

/* Dispositivos Pequenos */
@media screen and (max-width: 480px) {
  .conversao-magica-container .conversao-magica-logo {
    height: 100px;
  }

  .conversao-magica-container .conversao-magica-user-email {
    font-size: 0.9rem;
  }

  .conversao-magica-container .conversao-magica-logout {
    width: 100%;
    justify-content: center;
    padding: 0.5rem 1rem; /* Ajuste adicional para dispositivos pequenos */
  }

  .conversao-magica-container .conversao-magica-calculator,
  .conversao-magica-container .conversao-magica-history {
    padding: 1.5rem;
  }

  .conversao-magica-container .conversao-magica-history-list {
    max-height: 300px; /* Ajuste para telas muito pequenas */
  }

  .conversao-magica-container .conversao-magica-empty-state {
    padding: 2rem 1rem;
  }

  .conversao-magica-container .conversao-magica-empty-state img {
    width: 150px;
  }

  /* Previne que a tela fique muito comprimida */
  .conversao-magica-container {
    min-width: 320px; /* Define um mínimo para prevenir compressão excessiva */
  }

  /* Adição de espaçamento extra para dispositivos pequenos */
  .conversao-magica-container .conversao-magica-content {
    margin-top: 10px; /* Ajuste conforme necessário */
  }
}

/* ===========================================
   Seleção de Semanas e Botões de Toggle
   =========================================== */
.conversao-magica-container .conversao-magica-week-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.1);
  border-bottom: 2px solid #4CAF50;
  transition: background 0.3s ease, border-color 0.3s ease;
}

.conversao-magica-container .conversao-magica-week-header:hover {
  background: rgba(255, 255, 255, 0.15);
  border-color: #45a049;
}

.conversao-magica-container .semana-titulo {
  font-size: 1.2rem;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.conversao-magica-container .toggle-button {
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  transition: transform 0.3s ease;
}

.conversao-magica-container .toggle-button:hover {
  transform: rotate(180deg);
}

/* ===========================================
   Registros
   =========================================== */
.conversao-magica-container .conversao-magica-registros {
  margin-top: 1rem;
}

.conversao-magica-container .conversao-magica-history-stats span {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.95rem;
}

.conversao-magica-container .conversao-magica-history-stats span svg {
  color: #FFD700; /* Cor dourada para ícones */
}

.conversao-magica-container .conversao-magica-history-rate {
  color: #FFD700; /* Dourado para destaque */
}

/* ===========================================
   Animações
   =========================================== */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes slideInRight {
  from { opacity: 0; transform: translateX(100%); }
  to { opacity: 1; transform: translateX(0); }
}

@keyframes slideInLeft {
  from { opacity: 0; transform: translateX(-100%); }
  to { opacity: 1; transform: translateX(0); }
}

.conversao-magica-container .conversao-magica-fade-in {
  animation: fadeIn 0.5s ease forwards;
}

/* ===========================================
   Estilos de Impressão
   =========================================== */
@media print {
  .conversao-magica-container {
    background: #000000; /* Background preto para impressão */
    color: white; /* Texto branco para contraste */
    font-size: 12px; /* Ajuste de tamanho de fonte para impressão */
  }

  .conversao-magica-container .conversao-magica-header,
  .conversao-magica-container .conversao-magica-buttons,
  .conversao-magica-container .conversao-magica-history-actions,
  .conversao-magica-container .toggle-button,
  .conversao-magica-container .conversao-magica-export-pdf-history {
    display: none; /* Ocultar elementos desnecessários no print */
  }

  .conversao-magica-container .conversao-magica-calculator,
  .conversao-magica-container .conversao-magica-history {
    background: #000000; /* Fundo preto para os blocos */
    border: none; /* Remover bordas */
    box-shadow: none; /* Remover sombras */
  }

  .conversao-magica-container .conversao-magica-result,
  .conversao-magica-container .conversao-magica-weekly-average {
    background: #000000; /* Fundo preto */
    border: none;
  }

  .conversao-magica-container .conversao-magica-history-item,
  .conversao-magica-container .conversao-magica-empty-state {
    background: #000000;
    border: none;
  }

  /* Adicionar a logo no topo do PDF */
  .conversao-magica-container .pdf-logo {
    display: block;
    margin: 0 auto 20px auto;
    width: 150px; /* Ajuste conforme necessário */
  }

  /* Garantir que o conteúdo se ajuste às páginas */
  .conversao-magica-container,
  .conversao-magica-container .conversao-magica-main,
  .conversao-magica-container .conversao-magica-calculator,
  .conversao-magica-container .conversao-magica-history,
  .conversao-magica-container .conversao-magica-result,
  .conversao-magica-container .conversao-magica-weekly-average,
  .conversao-magica-container .conversao-magica-history-item,
  .conversao-magica-container .conversao-magica-empty-state {
    page-break-inside: avoid; /* Evitar quebras de página dentro de elementos */
  }

  /* Ajustar margens das páginas impressas */
  @page {
    margin: 20mm;
    size: A4;
  }
}

/* ===========================================
   Botão de Exportação em PDF
   =========================================== */
.conversao-magica-container .conversao-magica-export-pdf {
  background: #FF5722;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: background 0.3s ease, transform 0.3s ease;
  font-weight: 500;
  margin-top: 1rem;
}

.conversao-magica-container .conversao-magica-export-pdf:hover {
  background: #E64A19;
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(255, 87, 34, 0.3);
}

/* Botão de Exportação em PDF no Histórico */
.conversao-magica-container .conversao-magica-export-pdf-history {
  background: #9C27B0;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: background 0.3s ease, transform 0.3s ease;
  font-weight: 500;
  margin-left: auto;
}

.conversao-magica-container .conversao-magica-export-pdf-history:hover {
  background: #7B1FA2;
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(156, 39, 176, 0.3);
}

/* Destaque na Sessão Selecionada */
.conversao-magica-container .conversao-magica-week-header.selected {
  background: rgba(76, 175, 80, 0.2);
  border-color: #4CAF50;
}

/* ===========================================
   Classes Adicionadas
   =========================================== */

/* Container para cada semana no histórico */
.conversao-magica-container .conversao-magica-week {
  margin-bottom: 2rem; /* Espaçamento entre semanas */
}

/* Seção de Dicas de Melhoria */
.conversao-magica-container .conversao-magica-tips {
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  margin-top: 2rem;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.conversao-magica-container .conversao-magica-tips:hover {
  background: rgba(255, 255, 255, 0.15);
}

/* ===========================================
   Limitar a largura mínima para prevenir compressão excessiva
   =========================================== */
@media screen and (max-width: 480px) {
  .conversao-magica-container {
    min-width: 320px; /* Define um mínimo para prevenir compressão excessiva */
    margin-top: 10px; /* Espaço entre o cabeçalho e o conteúdo */
  }

  .conversao-magica-container .conversao-magica-content {
    margin-top: 10px; /* Ajuste conforme necessário */
  }
}