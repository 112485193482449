/* Container principal com fundo escuro igual ao assistente */
.unique-chat-containerr {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  background: #212121; /* Alterado para coincidir com o background do assistente */
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 90vh;
  transition: all 0.3s ease;
}
.unique-chat-main {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
}
/* Estilo da logo */
.unique-chat-logo {
  background-image: url('../../imagens/logo_menu_login.webp');
  background-size: contain;
  background-repeat: no-repeat;
  height: 80px;
  width: 240px;
  margin: 0 auto 20px;
  transition: all 0.3s ease;
}

/* Cabeçalho com fundo escuro */
.unique-chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 15px;
  background-color: #1a1a1a;
  color: #ecf0f1;
  border-radius: 15px;
  transition: all 0.3s ease;
}

/* Título do cabeçalho */
.unique-chat-header h2 {
  flex: 1;
  text-align: center;
  font-size: 1.8rem;
  color: #ecf0f1;
  font-weight: 600;
  letter-spacing: 1px;
}

/* Botões do cabeçalho */
.unique-chat-buttons {
  display: flex;
  gap: 15px;
}

/* Ícones dos botões */
.unique-chat-button svg {
  font-size: 1.8rem;
  color: #ecf0f1;
  cursor: pointer;
  transition: all 0.3s ease;
}

.unique-chat-button svg:hover {
  color: #3498db;
  transform: scale(1.1);
}

/* Área das mensagens */
.unique-chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  border-radius: 15px;
  background: #212121;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: inset 0 2px 10px rgba(0, 0, 0, 0.5);
}

/* Estilo das mensagens */
.unique-chat-message {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px; /* Reduzido para diminuir o espaçamento entre mensagens */
  padding: 10px 15px; /* Reduzido para diminuir o espaçamento interno */
  border-radius: 20px;
  animation: fadeIn 0.5s ease-in-out;
  word-wrap: break-word;
  white-space: pre-line;
  max-width: 85%;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}

/* Mensagens do usuário */
.unique-chat-message.user {
  justify-content: flex-end;
  background-color: #ffffff;
  color: #000000;
  border-radius: 20px 20px 0 20px;
  align-self: flex-end;
  margin-left: auto;
}

/* Mensagens do assistente */
.unique-chat-message.assistant {
  justify-content: flex-start;
  background-color: #212121;
  color: #ecf0f1;
  border-radius: 20px 20px 20px 0;
  align-self: flex-start;
  margin-right: auto;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  padding: 8px 12px; /* Reduzido para diminuir o espaçamento interno */
}

/* Ícone do assistente */
.unique-chat-message.assistant .unique-message-icon {
  background-image: url('../../imagens/assistente.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  margin-right: 10px; /* Reduzido para diminuir o espaçamento entre ícone e texto */
  border-radius: 50%;
}

/* Efeito de digitação */
.unique-chat-message.assistant.typing span {
  color: #3498db;
  font-style: italic;
}

/* Área de input */
.unique-chat-input-container {
  display: flex;
  align-items: center;
  border-top: 1px solid #444;
  padding: 20px;
  background-color: #1a1a1a;
  border-radius: 0 0 20px 20px;
  position: relative;
  box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.5);
}

/* Campo de input de texto */
.unique-chat-text-input {
  flex: 1;
  padding: 15px;
  border: 2px solid #555;
  border-radius: 25px;
  margin-right: 15px;
  font-size: 1.1rem;
  max-height: 100px;
  overflow-y: auto;
  color: #ecf0f1;
  background-color: #2c2c2c;
  resize: none;
  transition: all 0.3s ease;
}

.unique-chat-text-input:focus {
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
  outline: none;
}

/* Placeholder do campo de texto */
.unique-chat-text-input::placeholder {
  color: #7f8c8d;
}

/* Barra de rolagem do campo de texto e área de mensagens */
.unique-chat-text-input::-webkit-scrollbar,
.unique-chat-messages::-webkit-scrollbar {
  width: 6px;
}

.unique-chat-text-input::-webkit-scrollbar-thumb,
.unique-chat-messages::-webkit-scrollbar-thumb {
  background-color: #7f8c8d;
  border-radius: 10px;
}

/* Botão de envio */
.unique-chat-send-button {
  background: linear-gradient(135deg, #3498db, #2980b9);
  color: #ffffff;
  border: none;
  border-radius: 50%;
  padding: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1.2rem;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
}

.unique-chat-send-button:hover {
  transform: scale(1.05);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.7);
}

.unique-chat-send-button svg {
  font-size: 1.5rem;
}

/* Botão de download */
.unique-chat-download-button {
  background: linear-gradient(135deg, #2c3e50, #34495e);
  border: none;
  color: #ecf0f1;
  cursor: pointer;
  font-size: 1rem;
  padding: 10px 15px;
  border-radius: 25px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
}

.unique-chat-download-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.7);
}

.unique-chat-download-button svg {
  font-size: 1.2rem;
  margin-right: 5px;
}

/* Contador de caracteres */
.char-count {
  position: absolute;
  bottom: 60px;
  right: 30px;
  color: #7f8c8d;
  font-size: 0.9rem;
  font-weight: 600;
}

/* Floating button container */
.floating-button-container {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background: #2077f2ab;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1000;
  transition: all 0.3s ease;
}

/* Floating input */
.floating-input {
  margin-bottom: 15px;
  padding: 12px;
  width: 220px;
  border: 2px solid #ffffff;
  border-radius: 25px;
  color: #fffefe;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.floating-input:focus {
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
  outline: none;
}

/* Floating button */
.floating-button {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  background: linear-gradient(135deg, #2ecc71, #27ae60);
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
}

.floating-button:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.7);
}

.floating-button:disabled {
  background: linear-gradient(135deg, #bdc3c7, #95a5a6);
  cursor: not-allowed;
}

.floating-button svg {
  margin-right: 8px;
  font-size: 1.2rem;
}

/* Animação de fade-in */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Estilos para o conteúdo Markdown */
.markdown-content {
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #ecf0f1;
  line-height: 1.4; 
   flex: 1;
  word-wrap: break-word;
  white-space: normal;/* Ajustado para reduzir espaçamento entre linhas */
}

.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
  font-weight: bold;
  margin: 12px 0 6px; /* Reduzido o espaçamento entre cabeçalhos e parágrafos */
}

.markdown-content p {
  margin: 6px 0; /* Reduzido o espaçamento entre parágrafos */
}

.markdown-content ul,
.markdown-content ol {
  margin: 6px 0 6px 16px; /* Reduzido o espaçamento entre listas */
}

.markdown-content li {
  margin-bottom: 10px; /* Reduzido o espaçamento entre itens de lista */
}

.markdown-content code {
  background-color: #333;
  padding: 2px 4px;
  border-radius: 4px;
  font-family: 'Courier New', Courier, monospace;
}

.markdown-content pre {
  background-color: #333;
  padding: 8px;
  border-radius: 4px;
  overflow: auto;
}

.markdown-content blockquote {
  border-left: 4px solid #555;
  padding-left: 16px;
  color: #bdc3c7;
  margin: 6px 0; /* Reduzido o espaçamento */
}

.markdown-content a {
  color: #3498db;
  text-decoration: none;
}

.markdown-content a:hover {
  text-decoration: underline;
}
/* Botão "Assistir Tutorial" com classe única */
.tutorial-chat-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #007BFF; /* Cor de fundo agradável */
  color: white;
  border: none;
  padding: 8px 12px;
  margin-left: 8px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.tutorial-chat-button:hover {
  background-color: #0056b3;
}

.tutorial-chat-button svg {
  margin-right: 5px;
}

/* Pop-up do Tutorial */
.tutorial-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.tutorial-popup {
  position: relative;
  width: 80%;
  max-width: 800px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}

.tutorial-popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: #333;
  font-size: 24px;
  cursor: pointer;
  z-index: 1001;
}

.tutorial-popup-close:hover {
  color: #ff0000;
}

.tutorial-video-container {
  width: 100%;
  height: 0;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  position: relative;
}

.tutorial-video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Responsividade */
@media (max-width: 768px) {
  .tutorial-popup {
    width: 95%;
  }

  .tutorial-chat-button {
    padding: 6px 10px;
    font-size: 12px;
  }

  .tutorial-chat-button svg {
    margin-right: 3px;
  }
}

/* Ajuste na seção de saudação para acomodar os novos botões */
.greeting-buttons {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
/* Estilos responsivos */
@media (max-width: 768px) {
  .unique-chat-containerr {
    width: 95%;
    height: 95vh;
    padding: 15px;
  }

  .unique-chat-logo {
    height: 60px;
    width: 180px;
    margin-bottom: 15px;
  }

  .unique-chat-header {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .unique-chat-header h2 {
    font-size: 1.4rem;
    margin-bottom: 10px;
  }

  .unique-chat-buttons {
    gap: 10px;
  }

  .unique-chat-button svg {
    font-size: 1.5rem;
  }

  .unique-chat-messages {
    padding: 15px;
  }

  .unique-chat-message {
    padding: 12px;
    max-width: 90%;
  }

  .unique-chat-input-container {
    padding: 15px;
  }

  .unique-chat-text-input {
    font-size: 1rem;
    padding: 12px;
  }

  .unique-chat-send-button,
  .unique-chat-download-button {
    height: 45px;
    width: 45px;
    padding: 12px;
  }

  .unique-chat-download-button {
    padding: 8px 12px;
    font-size: 0.9rem;
  }

  .char-count {
    bottom: 50px;
    right: 20px;
    font-size: 0.8rem;
  }

  .floating-button-container {
    bottom: 20px;
    right: 20px;
    padding: 15px;
  }

  .floating-input {
    width: 180px;
    padding: 10px;
    font-size: 0.9rem;
  }

  .floating-button {
    padding: 10px 15px;
    font-size: 0.9rem;
  }
}

/* Animação adicional para mensagens */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.unique-chat-message.user {
  animation: fadeIn 0.5s ease-in-out, slideIn 0.5s ease-in-out;
}

.unique-chat-message.assistant {
  animation: fadeIn 0.5s ease-in-out, slideIn 0.5s ease-in-out;
}
