.chat-suporte-container {
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    padding: 30px;
    background-color: rgba(30, 30, 30, 0.9);
    border-radius: 15px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;
    box-sizing: border-box;
}

.chat-suporte-form h2 {
    font-size: 28px;
    background: linear-gradient(135deg, #FFD700, #FFA500, #FF4500);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-align: center;
    margin-bottom: 25px;
    display: block;
    font-weight: 700;
    letter-spacing: 1px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.chat-suporte-input-group {
    margin-bottom: 22px;
}

.chat-suporte-input-group label {
    display: block;
    font-size: 18px;
    background: linear-gradient(135deg, #FFD700, #FFA500);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-bottom: 12px;
    font-weight: 600;
}

.input-with-icon {
    position: relative;
}

.input-with-icon select,
.input-with-icon input,
.input-with-icon textarea {
    width: 100%;
    padding: 14px;
    padding-left: 50px;
    font-size: 16px;
    color: #FFFFFF;
    background-color: rgba(60, 60, 60, 0.7);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    transition: all 0.3s ease;
    box-sizing: border-box;
}

.input-with-icon .icon {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #FFA500;
    font-size: 22px;
    margin-right: 10px;
    transition: all 0.3s ease;
}

.input-with-icon select {
    padding-right: 50px;
    appearance: none;
}

.input-with-icon .icon-dropdown {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #FFA500;
    transition: all 0.3s ease;
}

.input-with-icon select:focus,
.input-with-icon input:focus,
.input-with-icon textarea:focus {
    border-color: #FFA500;
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 165, 0, 0.3);
}

.input-with-icon select:hover,
.input-with-icon input:hover,
.input-with-icon textarea:hover,
.chat-suporte-button:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.chat-suporte-button {
    width: 100%;
    padding: 14px;
    font-size: 18px;
    color: #FFFFFF;
    background: linear-gradient(135deg, #FFD700, #FFA500, #FF4500);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 20px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.chat-suporte-button:hover {
    background: linear-gradient(135deg, #FF4500, #FFA500, #FFD700);
    box-shadow: 0 6px 12px rgba(255, 165, 0, 0.3);
}

.chat-suporte-erro {
    color: #FF6347;
    margin-top: 12px;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
}

.chat-suporte-sucesso {
    color: #4CAF50;
    margin-top: 12px;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
}

@media (max-width: 768px) {
    .chat-suporte-container {
        width: 100%;
        padding: 20px;
        max-width: none;
    }
    
    .chat-suporte-form h2 {
        font-size: 24px;
    }
    
    .chat-suporte-input-group label {
        font-size: 16px;
    }
    
    .input-with-icon select,
    .input-with-icon input,
    .input-with-icon textarea {
        font-size: 14px;
    }
    
    .chat-suporte-button {
        font-size: 16px;
    }
}