.captura-container {
  background: linear-gradient(135deg, #0a0a0a 0%, #141414 100%);
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  position: relative;
  overflow-x: hidden;
  padding: 20px;
}

.captura-header {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  animation: fadeInDown 0.8s ease-out;
  margin-top: 170px;
}

.captura-logo {
  max-width: 400px;
  height: auto;
  transition: transform 0.3s ease;
}

.captura-logo:hover {
  transform: scale(1.05);
}

.captura-main {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  animation: fadeIn 1s ease-out;
}

.captura-headline {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1.5rem;
  background: linear-gradient(45deg, #FFC871, #FFB347);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: fadeInUp 0.8s ease-out;
}

.captura02-headline {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1.5rem;
  background: linear-gradient(45deg, #bebebe, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: fadeInUp 0.8s ease-out;
}

.captura-subtitle {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 2rem;
  line-height: 1.6;
  color: #e0e0e0;
  animation: fadeInUp 1s ease-out;
}

.captura-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  animation: fadeInUp 1.2s ease-out;
}

.form-group {
  position: relative;
  width: 100%;
}

.captura-input,
.captura-textarea {
  width: 100%;
  padding: 15px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
  color: #ffffff;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.captura-input:focus,
.captura-textarea:focus {
  border-color: #FFC871;
  outline: none;
  box-shadow: 0 0 10px rgba(255, 200, 113, 0.3);
  background: rgba(255, 255, 255, 0.1);
}

.captura-textarea {
  min-height: 120px;
  resize: vertical;
}

.captura-button {
  background: linear-gradient(45deg, #FFC871, #FFB347);
  color: #000000;
  font-weight: bold;
  padding: 15px 30px;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 1rem;
}

.captura-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(255, 200, 113, 0.4);
}

.captura-button:active {
  transform: translateY(1px);
}

.captura-button-description {
  text-align: center;
  color: #e0e0e0;
  font-size: 0.9rem;
  margin-top: 1rem;
}

.captura-footer {
  margin-top: auto;
  padding: 20px;
  text-align: center;
  color: #888;
  font-size: 0.9rem;
  width: 100%;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/* Estilos do Pop-up */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: #141414;
  padding: 20px 30px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  max-width: 90%;
  width: 400px;
  animation: fadeIn 0.5s ease-out;
}

.popup-content h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #FFC871;
}

.popup-content p {
  font-size: 1rem;
  color: #ffffff;
  margin-bottom: 20px;
}

.popup-close {
  background: linear-gradient(45deg, #FFC871, #FFB347);
  color: #000000;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.popup-close:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(255, 200, 113, 0.4);
}

.popup-close:active {
  transform: translateY(1px);
}

/* Responsive Design */
@media (max-width: 768px) {
  .captura-headline {
    font-size: 2rem;
    
  }
  .captura-logo {
    max-width: 200px;
    height: auto;
    transition: transform 0.3s ease;
    margin-top: 250px;
  }
  
  .captura-logo:hover {
    transform: scale(1.05);
  }
  .captura-subtitle {
    font-size: 1.1rem;
  }

  .captura-main {
    padding: 10px;
  }

  .captura-form {
    padding: 0 10px;
  }
}

@media (max-width: 480px) {
  .captura-headline {
    font-size: 1.8rem;
  }

  .captura-subtitle {
    font-size: 1rem;
  }

  .captura-button {
    padding: 12px 24px;
    font-size: 1rem;
  }

  .captura-input,
  .captura-textarea {
    padding: 12px;
  }
}