/* Mapa Digital - Estilos Atualizados com Transparência Reduzida */
.mapa-digital-container {
  background: url('../../imagens/fundo-login.webp') repeat center center fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 20px;
  box-sizing: border-box;
}

.mapa-digital-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.mapa-digital-logo {
  height: 200px;
  margin: 0 auto;
}

.mapa-digital-user-info {
  display: flex;
  align-items: center;
}

.mapa-digital-logout {
  background: linear-gradient(to right, #fac97b, #d5a05e);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.mapa-digital-back-button {
  background: linear-gradient(to right, #fac97b, #d5a05e);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}

.mapa-digital-content {
  width: 100%;
  max-width: 1200px;
}

.profile-section, .market-section, .work-section, .future-section {
  margin-bottom: 20px;
}

.job-category {
  background-color: rgba(0, 0, 0, 0.45);
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  transition: transform 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.job-category:hover {
  transform: scale(1.05);
}

.job-category-title {
  font-size: 1.8em;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
  color: #ecf0f1;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
}

.jobs-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
}

.profile-icon {
  text-align: center;
  transition: transform 0.3s;
}

.icon-container {
  background-color: rgba(255, 255, 255, 0.45);
  border-radius: 50%;
  padding: 20px;
  margin-bottom: 10px;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.profile-icon:hover {
  transform: scale(1.1);
}

.icon-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.1em;
  color: #ffffff;
}

/* Paleta de cores refinada */
.blue { background-color: rgba(41, 128, 185, 0.45); }
.purple { background-color: rgba(142, 68, 173, 0.45); }
.green { background-color: rgba(39, 174, 96, 0.45); }
.red { background-color: rgba(231, 76, 60, 0.45); }
.yellow { background-color: rgba(243, 156, 18, 0.45); }

/* Estilos específicos para cada bloco */
.profile-today-block { background-color: rgba(23, 162, 184, 0.45); }
.formal-training-block { background-color: rgba(102, 16, 242, 0.45); }
.informal-training-block { background-color: rgba(32, 201, 151, 0.45); }
.trained-workers-block { background-color: rgba(232, 62, 140, 0.45); }
.untrained-workers-block { background-color: rgba(255, 193, 7, 0.45); }

/* Estilos específicos para ícones de cada bloco */
.profile-today-icon .icon-container { background-color: rgba(52, 152, 219, 0.45); }
.formal-training-icon .icon-container { background-color: rgba(155, 89, 182, 0.45); }
.informal-training-icon . icon-container { background-color: rgba(46, 204, 113, 0.45); }
.trained-workers-icon .icon-container { background-color: rgba(236, 112, 99, 0.45); }
.untrained-workers-icon .icon-container { background-color: rgba(241, 196, 15, 0.45); }

/* Estilos específicos para títulos de cada bloco */
.profile-today-title, .formal-training-title, .informal-training-title, .trained-workers-title, .untrained-workers-title {
  color: #ffffff;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

/* Seção "Seu Perfil Futuro" */
.future-section {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
}

.future-section-title {
  font-size: 1.8em;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
}

.future-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.future-option {
  background-color: rgba(255, 255, 255, 0.45);
  color: #ffffff;
  padding: 15px;
  border-radius: 5px;
  text-align: center;
  font-size: 1.2em;
  width: 250px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, background-color 0.3s;
}

.future-option:hover {
  transform: scale(1.05);
  background-color: rgba(255, 255, 255, 0.6);
}

.future-option.green { background-color: rgba(39, 174, 96, 0.6); }
.future-option.blue { background-color: rgba(41, 128, 185, 0.6); }
.future-option.purple { background-color: rgba(142, 68, 173, 0.6); }
.future-option.yellow { background-color: rgba(243, 156, 18, 0.6); }
.future-option.orange { background-color: rgba(255, 87, 34, 0.6); }
.future-option.pink { background-color: rgba(255, 105, 180, 0.6); }

@media (max-width: 768px) {
  .future-option {
    flex: 1 1 100%;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .mapa-digital-header {
    flex-direction: column;
    align-items: center;
  }

  .mapa-digital-user-info {
    margin-top: 10px;
  }

  .job-category {
    flex: 1 1 100%;
  }

  .future-option {
    flex: 1 1 100%;
  }
}
.mapa-digital-logo {
  height: 100px;
  margin: 0 auto;
}


/* Título com Gradiente em Movimento */
.mapa-digital-title {
  font-size: 2.5em;
  font-weight: bold;
  text-align: center;
  margin: 20px 0;
  position: relative;
  z-index: 1;
  color: transparent;
  background: linear-gradient(90deg, #ff6f61, #6b5b95, #88b04b, #ff6f61);
  background-size: 400%;
  -webkit-background-clip: text;
  animation: gradientAnimation 10s ease infinite;
}

@keyframes gradientAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}
