/* Container Principal */
.aulas-container {
  background: linear-gradient(135deg, #0f0c29, #302b63, #24243e);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  color: white;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

/* Cabeçalho */
.aulas-header {
  width: 100%;
  max-width: 1200px;
  margin-bottom: 40px;
  text-align: center;
}

.aulas-title-gradient {
  font-size: 4em;
  background: linear-gradient(to right, #FFD700, #FFA500);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-bottom: 20px;
  animation: shimmer 2s infinite linear;
  text-shadow: 0 0 10px rgba(255, 215, 0, 0.3);
}

@keyframes shimmer {
  0% { background-position: -200% center; }
  100% { background-position: 200% center; }
}

.aulas-description {
  font-size: 1.5em;
  max-width: 900px;
  margin: 0 auto 30px;
  color: #e0e0e0;
  animation: fadeInUp 1.2s ease-out;
  line-height: 1.8;
  text-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* Progress Bar */
.progress-container {
  width: 200px;
  margin: 0 auto;
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 10px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.progress-container p {
  margin-top: 10px;
  color: #FFD700;
  font-weight: 600;
}

/* Área de Vídeo e Navegação */
.aulas-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  padding: 40px;
  background: rgba(255, 255, 255, 0.07);
  border-radius: 30px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  animation: fadeInUp 1.5s ease-out;
  backdrop-filter: blur(10px);
}

.aulas-video {
  width: 100%;
  max-width: 900px;
  height: 500px;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.aulas-video iframe {
  width: 100%;
  height: 100%;
  border: none;
}

/*.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.video-overlay:hover {
  background: rgba(0, 0, 0, 0.4);
}

.play-icon {
  font-size: 6em;
  color: #FFD700;
  transition: all 0.3s ease;
}

.video-overlay:hover .play-icon {
  transform: scale(1.1);
  text-shadow: 0 0 20px rgba(255, 215, 0, 0.7);
}

/* Informação da Aula */
.aulas-info {
  width: 100%;
  text-align: center;
}

.aulas-info h2 {
  font-size: 2.5em;
  color: #FFD700;
  margin-bottom: 20px;
  animation: fadeInUp 1.6s ease-out;
  text-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.aulas-info p {
  font-size: 1.4em;
  color: #e0e0e0;
  margin-bottom: 30px;
  animation: fadeInUp 1.7s ease-out;
  line-height: 1.8;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

/* Lesson Actions */
.lesson-actions {
  margin-bottom: 30px;
}

.completion-button {
  background: linear-gradient(to right, #FFD700, #FFA500);
  color: #1a1a2e;
  border: none;
  padding: 15px 30px;
  font-size: 1.2em;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.completion-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(255, 215, 0, 0.4);
}

.completion-button.completed {
  background: linear-gradient(to right, #4CAF50, #45a049);
  color: white;
}

/* Navegação */
.aulas-navigation {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  margin: 30px auto 0;
}

.aulas-button {
  background: linear-gradient(to right, #FFD700, #FFA500);
  color: #1a1a2e;
  border: none;
  padding: 15px 30px;
  font-size: 1.2em;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.aulas-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(255, 215, 0, 0.4);
}

.aulas-button:disabled {
  background: linear-gradient(to right, #555, #444);
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
  opacity: 0.7;
}

/* Toggle List Button */
.toggle-list-button {
  background: transparent;
  color: #FFD700;
  border: 2px solid #FFD700;
  padding: 12px 25px;
  font-size: 1.1em;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 40px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.toggle-list-button:hover {
  background: rgba(255, 215, 0, 0.1);
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(255, 215, 0, 0.2);
}

/* Lista de Aulas */
.aulas-list {
  margin-top: 50px;
  text-align: left;
  background: rgba(255, 255, 255, 0.07);
  padding: 40px;
  border-radius: 30px;
  width: 90%;
  max-width: 900px;
  animation: fadeInUp 1.8s ease-out;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
}

.aulas-list h3 {
  font-size: 2.2em;
  color: #FFD700;
  margin-bottom: 30px;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.aulas-list ul {
  list-style: none;
  padding: 0;
}

.aulas-list li {
  font-size: 1.3em;
  margin-bottom: 20px;
  color: #e0e0e0;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 15px 20px;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
}

.aulas-list li:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateX(10px);
}

.aula-active {
  font-weight: bold;
  color: #FFD700;
  background: rgba(255, 215, 0, 0.2) !important;
  box-shadow: 0 5px 15px rgba(255, 215, 0, 0.1);
}

.aula-completed {
  color: #4CAF50;
}

.completion-icon {
  margin-left: 15px;
  font-size: 1.2em;
}

/* Animação */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsividade */
@media (max-width: 768px) {
  .aulas-title-gradient {
    font-size: 3em;
  }

  .aulas-description {
    font-size: 1.3em;
  }

  .aulas-video {
    height: 300px;
  }

  .aulas-info h2 {
    font-size: 2em;
  }

  .aulas-info p {
    font-size: 1.2em;
  }

  .aulas-navigation {
    flex-direction: column;
    align-items: center;
  }

  .aulas-button {
    margin-bottom: 20px;
    width: 100%;
  }

  .aulas-list h3 {
    font-size: 2em;
  }

  .aulas-list li {
    font-size: 1.2em;
  }
}

/* Ajustes para telas muito pequenas */
@media (max-width: 480px) {
  .aulas-title-gradient {
    font-size: 2.5em;
  }

  .aulas-description {
    font-size: 1.1em;
  }

  .aulas-video {
    height: 200px;
  }

  .aulas-content {
    padding: 20px;
  }

  .aulas-list {
    padding: 20px;
  }
}