.relatorio-suporte-container {
  padding: 30px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #2a2a2a;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.relatorio-suporte-titulo {
  font-size: 32px;
  font-weight: bold;
  color: #FFD700;
  margin-bottom: 30px;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.relatorio-suporte-erro {
  color: #ff6b6b;
  background-color: rgba(255, 107, 107, 0.1);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  font-weight: bold;
}

.ticket-container {
  border: 1px solid #444;
  border-radius: 10px;
  margin-bottom: 25px;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}

.ticket-container:hover {
  box-shadow: 0 0 20px rgba(255, 215, 0, 0.2);
}

.ticket-header {
  background-color: #333;
  color: #FFD700;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.ticket-header:hover {
  background-color: #444;
}

.ticket-icon {
  margin-right: 15px;
  font-size: 24px;
}

.ticket-categoria {
  flex-grow: 1;
  font-size: 20px;
  margin: 0;
  font-weight: bold;
}

.ticket-detalhes {
  padding: 20px;
  background-color: rgba(78, 76, 76, 0.5);
  color: #FFF;
  line-height: 1.6;
}

.interacoes-container {
  margin-top: 20px;
}

.interacao {
  background-color: #444;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.input-mensagem {
  width: 100%;
  padding: 15px;
  margin-top: 15px;
  border-radius: 8px;
  border: 1px solid #555;
  background-color: #333;
  color: #fff;
  resize: vertical;
  min-height: 100px;
  font-family: inherit;
  transition: border-color 0.3s ease;
}

.input-mensagem:focus {
  outline: none;
  border-color: #FFD700;
}

.button-enviar, .button-fechar-ticket {
  background: linear-gradient(90deg, #FFD700, #FFA500);
  color: #333;
  padding: 12px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  margin-top: 15px;
  display: block;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.button-enviar:hover, .button-fechar-ticket:hover {
  background: linear-gradient(90deg, #FFA500, #FFD700);
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(255, 215, 0, 0.3);
}

.button-fechar-ticket {
  background: linear-gradient(90deg, #ff6b6b, #ff8e8e);
  color: #fff;
}

.button-fechar-ticket:hover {
  background: linear-gradient(90deg, #ff8e8e, #ff6b6b);
}

@media (max-width: 768px) {
  .relatorio-suporte-container {
    padding: 20px;
  }

  .relatorio-suporte-titulo {
    font-size: 28px;
  }

  .ticket-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .ticket-categoria {
    margin-top: 10px;
  }
}