/* Container Principal - Tela ÃƒÅ¡nica com Fundo Extenso */
.prova-social-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  background: linear-gradient(135deg, #141414, #1e1e1e);
  font-family: 'Roboto', sans-serif;
  color: #ecf0f1;
  position: relative;
   animation: fadeIn 1s ease-in;
}
/* Efeitos de Animação */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes slideIn {
  from { opacity: 0; transform: translateX(100%); }
  to { opacity: 1; transform: translateX(0); }
}

/* CabeÃƒÂ§alho */
.prova-social-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  background-color: #1a1a1a;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid #bfa066;
  animation: fadeIn 1s ease-in;
}

.prova-social-header-left {
  display: flex;
  align-items: center;
  gap: 20px;
}

.prova-social-logo {
  height: 100px;
  width: auto;
  cursor: pointer;
}

.prova-social-back-button {
  display: flex;
  align-items: center;
  color: #ecf0f1;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s;
}

.prova-social-back-button:hover {
  color: #3498db;
}

.prova-social-header-right {
  display: flex;
  align-items: center;
  gap: 15px;
}

/* **Novas Classes para Exibir Nome e Foto do UsuÃƒÂ¡rio** */
.prova-social-user-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.prova-social-user-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.prova-social-user-name {
  font-size: 1rem;
  color: #ecf0f1;
}

.prova-social-logout-button {
  background: linear-gradient(135deg, #e74c3c, #e74c3c);
  border: none;
  color: #ecf0f1;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 90px;
  min-width: 45px;
}

.prova-social-logout-button:hover {
  background-color: #c0392b;
}

/* ConteÃƒÂºdo Principal - Centralizado */
.prova-social-main {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

/* Container do Chat - Removendo bordas e efeito de profundidade */
.prova-social-chat-container {
  width: 100%;
  max-width: 1200px; /* Aumentado para dar mais espaÃƒÂ§o */
  background-color: transparent; /* Removido o background para integrar com o fundo */
  border-radius: 0; /* Removido o border-radius */
  box-shadow: none; /* Removido o box-shadow */
  display: flex;
  flex-direction: column;
  height: 90vh;
  transition: all 0.4s ease;
  position: relative;
  overflow: hidden;
}

/* CabeÃƒÂ§alho do Chat */
.prova-social-chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #161616;
}

.prova-social-chat-header h2 {
  font-size: 1.5rem;
  color: #ecf0f1;
}

.prova-social-chat-buttons {
  display: flex;
  gap: 10px;
}

.prova-social-chat-button {
  background: none;
  border: none;
  color: #ecf0f1;
  font-size: 1.2rem;
  cursor: pointer;
  transition: color 0.3s, transform 0.3s;
}

.prova-social-chat-button:hover {
  color: #3498db;
  transform: scale(1.2);
}

/* ÃƒÂrea de Mensagens - Aumentando o espaÃƒÂ§o e removendo bordas */
.prova-social-messages {
  flex: 1;
  padding: 30px; /* Aumentado o padding */
  overflow-y: auto;
  background: transparent; /* Removido o background */
  position: relative;
  display: flex;
  flex-direction: column;
}

/* Estilo das Mensagens */
.prova-social-message {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 80%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  animation: fadeIn 0.5s ease-in;
}

.prova-social-message:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

/* Mensagens do UsuÃƒÂ¡rio */
.prova-social-message.user {
  justify-content: flex-end;
  background-color: #ffffff;
  color: #000000;
  align-self: flex-end;
  border-radius: 20px 20px 0 20px;
  margin-left: auto;
}

/* Mensagens do Assistente */
.prova-social-message.assistant {
  justify-content: flex-start;
  background-color: #212121;
  color: #ffffff;
  align-self: flex-start;
  border-radius: 20px 20px 20px 0;
  margin-right: auto;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

/* **AtualizaÃƒÂ§ÃƒÂ£o das Mensagens com Fotos Reais** */
.prova-social-message-icon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover; /* Garante que a imagem preencha o ÃƒÂ­cone */
}

/* ConteÃƒÂºdo das Mensagens */
.prova-social-markdown {
  flex: 1;
  word-wrap: break-word;
  white-space: pre-wrap;
}

/* BotÃƒÂ£o de Download */
.prova-social-download-button {
  background: none;
  border: none;
  color: #ecf0f1;
  font-size: 1rem;
  cursor: pointer;
  margin-left: 10px;
  transition: color 0.3s;
  min-height: 45px;
  min-width: 45px;
  width: 45px;
}

.prova-social-download-button:hover {
  color: #3498db;
}

/* Efeito de DigitaÃƒÂ§ÃƒÂ£o */
.prova-social-message.assistant.typing span {
  color: #3498db;
  font-style: italic;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.prova-social-message.assistant.typing::after {
  content: '...';
  animation: blink 1s infinite;
}

/* BotÃ£o de Scroll para o Final */
.prova-social-scroll-button {
  position: fixed; /* MantÃ©m fixo no canto */
  bottom: 120px;
  right: 1550px;
  background: #3498db;
  color: white;
  border: none;
  border-radius: 50%;
  width: 35px; /* Reduzido o tamanho */
  height: 35px; /* Reduzido o tamanho */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.3s, background-color 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  z-index: 1000; /* Garantir que fique acima de outros elementos */
}

.prova-social-scroll-button:hover {
  opacity: 1;
  background-color: #2980b9;
}


/* ÃƒÂrea de Input - Ajustando estilo e aumentando tamanho */
.prova-social-input-container {
  display: flex;
  align-items: center;
  padding: 20px 30px; /* Aumentado o padding */
  background-color: transparent; /* Removido o background */
  border-top: none; /* Removida a borda */
  position: relative;
  margin-top: 20px; /* Adicionado margem superior */
}

/* Campo de Input de Texto - Aumentando tamanho e ajustando estilo */
.prova-social-text-input {
  flex: 1;
  padding: 15px 20px; /* Aumentado o padding */
  border: 2px solid rgba(255, 255, 255, 0.1); /* Borda mais sutil */
  border-radius: 25px; /* Aumentado o border-radius */
  margin-right: 15px;
  font-size: 1.1rem; /* Aumentado o tamanho da fonte */
  max-height: 100px; /* Aumentado a altura mÃƒÂ¡xima */
  overflow-y: auto;
  color: #ecf0f1;
  background-color: #212121; /* Background mais transparente */
  resize: none;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.prova-social-text-input:focus {
  border-color: rgba(52, 152, 219, 0.5);
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.2);
  outline: none;
}

.prova-social-text-input::placeholder {
  color: #7f8c8d;
}

/* Contador de Caracteres */
.prova-social-char-count {
  position: absolute;
  bottom: 60px;
  right: 30px;
  color: #7f8c8d;
  font-size: 0.9rem;
  font-weight: 600;
}

/* BotÃƒÂ£o de Envio - Corrigindo tamanho e estilo */
.prova-social-send-button {
  width: 45px; /* Tamanho fixo */
  height: 45px; /* Tamanho fixo */
  min-width: 45px; /* Garantir tamanho mÃƒÂ­nimo */
  min-height: 45px; /* Garantir tamanho mÃƒÂ­nimo */
  background-color: #3498db;
  color: #ecf0f1;
  border: none;
  border-radius: 50%;
  padding: 0; /* Removido padding para evitar distorÃƒÂ§ÃƒÂ£o */
  cursor: pointer;
  transition: transform 0.3s, background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.prova-social-send-button:hover {
  transform: scale(1.05);
  background-color: #2980b9;
}

.prova-social-send-button:disabled {
  background-color: #7f8c8d;
  cursor: not-allowed;
  transform: none;
}

.prova-social-send-button svg {
  width: 20px; /* Tamanho fixo para o ÃƒÂ­cone */
  height: 20px; /* Tamanho fixo para o ÃƒÂ­cone */
  transition: transform 0.3s;
}

/* FormulÃƒÂ¡rio Flutuante */
.prova-social-floating-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1001;
}

.prova-social-floating-button {
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 30px;
  cursor: pointer;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s, transform 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prova-social-floating-button:hover {
  background-color: #2980b9;
  transform: scale(1.1);
}

.prova-social-floating-form {
  background-color: #2c2c2c;
  width: 350px;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.7);
  position: fixed;
  bottom: 90px;
  right: 20px;
  z-index: 1001;
  animation: slideUp 0.3s ease-out;
  border: 1px solid #444;
  display: none;
  color: #ecf0f1;
}

.prova-social-floating-form.open {
  display: block;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.prova-social-floating-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  color: #bdc3c7;
  cursor: pointer;
  transition: color 0.3s;
  height: 30px; /* Tamanho fixo */
  min-width: 30px; /* Garantir tamanho mÃƒÂ­nimo */
  min-height: 30px; /* Garantir tamanho mÃƒÂ­nimo */
}

.prova-social-floating-close:hover {
  color: #e74c3c;
}

.prova-social-floating-form h3 {
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

/* **Novas EstilizaÃƒÂ§ÃƒÂµes para o FormulÃƒÂ¡rio Flutuante** */

/* Campo de MarcaÃƒÂ§ÃƒÂ£
o: Seguir as polÃƒÂ­ticas de Publicidade do Facebook ADS */
.prova-social-floating-field input[type="checkbox"] {
  margin-right: 10px;
  transform: scale(1.2);
}

.prova-social-floating-field label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* Grupo de Checkboxes: Tipo de Produto */
.prova-social-checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.prova-social-checkbox-group label {
  font-weight: normal;
  color: #ecf0f1;
  cursor: pointer;
}

.prova-social-checkbox-group input[type="checkbox"] {
  margin-right: 10px;
  transform: scale(1.2);
}

/* BotÃƒÂ£o de Envio do FormulÃƒÂ¡rio Flutuante */
.prova-social-floating-submit {
  width: 100%;
  padding: 12px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.prova-social-floating-submit:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.prova-social-floating-submit:not(:disabled):hover {
  background-color: #218838;
}

/* Novas Classes Adicionadas para FormulÃƒÂ¡rio Flutuante */

/* Input de Texto no FormulÃƒÂ¡rio Flutuante */
.prova-social-floating-input {
  width: 100%;
  padding: 10px 5px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  background-color: rgba(44, 44, 44, 0.7);
  color: #ecf0f1;
  font-size: 1rem;
  margin-top: 5px;
  margin-bottom: 15px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.prova-social-floating-input:focus {
  border-color: #3498db;
  box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
  outline: none;
}

.prova-social-floating-input::placeholder {
  color: #7f8c8d;
}

/* Textarea no FormulÃƒÂ¡rio Flutuante */
.prova-social-floating-textarea {
  width: 95%;
  padding: 10px 15px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  background-color: rgba(44, 44, 44, 0.7);
  color: #ecf0f1;
  font-size: 1rem;
  margin-top: 5px;
  margin-bottom: 15px;
  resize: vertical;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.prova-social-floating-textarea:focus {
  border-color: #3498db;
  box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
  outline: none;
}

.prova-social-floating-textarea::placeholder {
  color: #7f8c8d;
}

/* Efeito de Digitação na Saudação */
.greeting-text {
  font-size: 1.5rem;
  color: #ecf0f1;
  position: relative;
}

.cursor {
  display: inline-block;
  width: 10px;
  background-color: #ecf0f1;
  margin-left: 2px;
  animation: blink 1s infinite;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

/* Classe para indicar que está digitando */
.typing {
  /* Pode adicionar estilos adicionais se necessário */
}

/* Transição de Entrada para o Componente */
.prova-social-container {
  animation: fadeIn 1s ease-in;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Background semi-transparente para o botão "Voltar para o Menu" */
.prova-social-back-button {
  background-color: #5a5a5a80; /* Meio transparente */
  padding: 8px 12px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.prova-social-back-button:hover {
  background-color: rgba(52, 152, 219, 0.5); /* Azul semi-transparente no hover */
}

/* Background semi-transparente sobre o nome do usuário */
.prova-social-user-name {
  background-color: #5a5a5a80; /* Meio transparente */
  padding: 5px 10px;
  border-radius: 10px;
}

/* Garantir que as novas regras não interfiram nas existentes */
/* Você pode adicionar mais ajustes conforme necessário */
/* CSS específico para o botão "Assistir Tutorial" e o pop-up do tutorial */

/* Botão "Assistir Tutorial" com classe única */
.tutorial-chat-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #007BFF; /* Cor de fundo agradável */
  color: white;
  border: none;
  padding: 8px 12px;
  margin-left: 8px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.tutorial-chat-button:hover {
  background-color: #0056b3;
}

.tutorial-chat-button svg {
  margin-right: 5px;
}

/* Pop-up do Tutorial */
.tutorial-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.tutorial-popup {
  position: relative;
  width: 80%;
  max-width: 800px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}

.tutorial-popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: #333;
  font-size: 24px;
  cursor: pointer;
  z-index: 1001;
}

.tutorial-popup-close:hover {
  color: #ff0000;
}

.tutorial-video-container {
  width: 100%;
  height: 0;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  position: relative;
}

.tutorial-video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Responsividade */
@media (max-width: 768px) {
  .tutorial-popup {
    width: 95%;
  }

  .tutorial-chat-button {
    padding: 6px 10px;
    font-size: 12px;
  }

  .tutorial-chat-button svg {
    margin-right: 3px;
  }
}

/* Ajuste na seção de saudação para acomodar os novos botões */
.greeting-buttons {
  display: flex;
  align-items: center;
  margin-top: 10px;
}


/* Responsividade */
@media (max-width: 1024px) {
  .prova-social-container {
    flex-direction: column;
     height: 100vh;
  }

  .prova-social-main {
    padding: 10px;
  }

  .prova-social-chat-container {
    max-width: 100%;
    height: 90vh;
    border-radius: 0;
  }

  /* Esconder a barra de rolagem do chat */
  .prova-social-messages {
    scrollbar-width: none; /* Firefox */
  }

  .prova-social-messages::-webkit-scrollbar {
    display: none; /* Chrome, Safari e Opera */
  }
}

@media (max-width: 768px) {
  .prova-social-chat-container {
    padding: 10px;
    height: 90vh;
  }

  .prova-social-header {
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: #1a1a1a;
  }

  .prova-social-header-left {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .prova-social-logo {
    height: 100px;
  }

  .prova-social-back-button {
    font-size: 0.9rem;
    align-self: center;
    margin-top: 10px;
  }

  .prova-social-header-right {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
  }

  .prova-social-user-info {
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;
  }

  .prova-social-user-photo {
    width: 50px;
    height: 50px;
  }

  .prova-social-user-name {
    font-size: 1.1rem;
  }

  .prova-social-logout-button {
    padding: 8px 12px;
    font-size: 1rem;
    width: auto;
  }

  .prova-social-chat-header h2 {
    font-size: 1.3rem;
    text-align: center;
  }

  .prova-social-chat-buttons {
    gap: 8px;
  }

  .prova-social-chat-button {
    font-size: 1rem;
  }

  .prova-social-messages {
    padding: 15px;
  }

  .prova-social-message {
    padding: 8px 12px;
  }

  .prova-social-send-button {
    width: 40px;
    height: 40px;
    padding: 10px;
    font-size: 1rem;
  }

  .prova-social-send-button svg {
    font-size: 1.2rem;
  }

  .prova-social-scroll-button {
    width: 35px;
    height: 35px;
    bottom: 15px;
    right: 15px;
  }

  .prova-social-floating-form {
    width: 90%;
    bottom: 80px;
    right: 5%;
  }

  /* ReorganizaÃ§Ã£o do CabeÃ§alho para Mobile */
  .prova-social-header-left {
    flex-direction: column;
    align-items: center;
  }

  .prova-social-header-right {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .prova-social-back-button {
    order: 1;
    margin-top: 10px;
  }

  .prova-social-user-info {
    order: 2;
  }

  .prova-social-logout-button {
    order: 3;
    align-self: flex-end;
  }

  /* Ajuste do BotÃ£o Flutuante no Mobile */
  .prova-social-floating-button {
    width: 50px;
    height: 50px;
    font-size: 24px;
  }

  /* FixaÃ§Ã£o do BotÃ£o de Scroll no Mobile */
  .prova-social-scroll-button {
    bottom: 15px;
    right: 15px;
  }
}

@media (max-width: 600px) {
  .prova-social-scroll-button {
    width: 30px;
    height: 30px;
    bottom: 10px;
    right: 10px;
  }

  .prova-social-chat-container {
    height: 100vh;
  }

  /* Ajustes Adicionais para Telas Muito Pequenas */
  .prova-social-floating-button {
    width: 45px;
    height: 45px;
    font-size: 20px;
  }

  .prova-social-user-photo {
    width: 40px;
    height: 40px;
  }

  .prova-social-user-name {
    font-size: 1rem;
  }

  .prova-social-logout-button {
    padding: 6px 10px;
    font-size: 0.9rem;
  }

  .prova-social-chat-header h2 {
    font-size: 1.1rem;
  }

  .prova-social-chat-button {
    font-size: 0.9rem;
  }

  .prova-social-send-button {
    width: 35px;
    height: 35px;
  }

  .prova-social-send-button svg {
    width: 18px;
    height: 18px;
  }
}

/* EstilizaÃ§Ã£o da Barra de Rolagem (InvisÃ­vel ou Discreta) */
.prova-social-messages::-webkit-scrollbar {
  width: 6px;
}

.prova-social-messages::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

.prova-social-messages::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.prova-social-messages {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
}

/* Tornar a barra de rolagem invisÃ­vel para Firefox */
.prova-social-messages {
  scrollbar-width: none;
}

.prova-social-messages::-webkit-scrollbar {
  display: none;
}

/* EstilizaÃ§Ã£o das Mensagens com Foto */
.prova-social-message.user .prova-social-message-icon,
.prova-social-message.assistant .prova-social-message-icon {
  /* JÃ¡ estÃ£o sendo estilizados acima */
}

/* **Novas EstilizaÃ§Ãµes Adicionadas** */

/* Ajuste para a foto do usuÃ¡rio no cabeÃ§alho */
.prova-social-user-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

/* Ajuste para o nome do usuÃ¡rio no cabeÃ§alho */
.prova-social-user-name {
  font-size: 1rem;
  color: #ecf0f1;
}

/* EstilizaÃ§Ã£o do Grupo de Checkboxes no FormulÃ¡rio Flutuante */
.prova-social-checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.prova-social-checkbox-group label {
  font-weight: normal;
  color: #ecf0f1;
  cursor: pointer;
}

.prova-social-checkbox-group input[type="checkbox"] {
  margin-right: 10px;
  transform: scale(1.2);
}

/* EstilizaÃ§Ã£o das Labels para Checkboxes */
.prova-social-floating-field label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* **Organizar as Mensagens Enviadas a partir do FormulÃ¡rio Flutuante** */

.prova-social-message.assistant .prova-social-message-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.prova-social-message.assistant .prova-social-message-content p {
  margin: 5px 0;
  padding: 0;
  line-height: 1.4;
}

/* Melhorias no Botão Flutuante para Mobile */
@media (max-width: 768px) {
  .prova-social-floating-button {
    width: 50px;
    height: 50px;
    font-size: 24px;
  }

  .prova-social-floating-form {
    width: 90%;
    bottom: 80px;
    right: 5%;
    padding: 15px;
    /* Reduzindo altura máxima para evitar que o formulário suba muito */
    max-height: 80vh;
    overflow-y: auto;
    animation: slideIn 0.3s ease-out;
  }

  .prova-social-floating-close {
    top: 5px;
    right: 5px;
    height: 25px;
    min-width: 25px;
    font-size: 18px;
  }

  /* Ajustando o botão de fechar para ficar visível */
  .prova-social-floating-form.open {
    display: block;
    z-index: 1002;
  }

  /* Adicionando overlay quando o formulário está aberto */
  .prova-social-container.form-open::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
}

@media (max-width: 600px) {
  .prova-social-floating-button {
    width: 45px;
    height: 45px;
    font-size: 20px;
  }

  .prova-social-floating-form {
    width: 95%;
    bottom: 70px;
    right: 2.5%;
    padding: 10px;
    max-height: 70vh;
  }

  .prova-social-floating-close {
    top: 3px;
    right: 3px;
    height: 25px;
    min-width: 25px;
    font-size: 18px;
  }
}

/* Prevenindo Interações com o Fundo Quando o Formulário Está Aberto */
.prova-social-floating-form.open + .prova-social-container {
  pointer-events: none;
}

.prova-social-floating-form.open {
  pointer-events: auto;
}

/* Overlay para bloquear interações com o fundo */
.prova-social-container.form-open {
  pointer-events: none;
}

.prova-social-container.form-open .prova-social-floating-form {
  pointer-events: auto;
}
