.oportunidades-container {
  padding: 20px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(245, 245, 245, 0.8));
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.oportunidades-title {
  font-size: 2.5em;
  font-weight: 700;
  color: #4a4a4a;
  text-align: center;
  margin-bottom: 20px;
}

.oportunidades-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.opportunity-wrapper {
  transition: transform 0.3s;
}

.opportunity-wrapper.hovered {
  transform: scale(1.05);
}

.opportunity-card {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s, transform 0.3s;
  cursor: pointer;
}

.opportunity-card.expanded {
  transform: scale(1.05);
}

.opportunity-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.opportunity-icon-name {
  display: flex;
  align-items: center;
}

.opportunity-icon {
  font-size: 2.5em;
  margin-right: 10px;
}

.opportunity-name {
  font-size: 1.2em;
  font-weight: 600;
  color: #4a4a4a;
}

.chevron-icon {
  color: #4a4a4a;
}

.opportunity-description {
  font-size: 1em;
  color: #666;
  overflow: hidden;
  transition: max-height 0.3s;
}

.opportunity-description.hide {
  max-height: 0;
}

.opportunity-description.show {
  max-height: 500px;
}
