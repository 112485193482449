.metricas-container {
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  margin-top: 2rem;
}

.metricas-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1.5rem;
  color: #343a40;
}

.metricas-category {
  margin-bottom: 2rem;
}

.category-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #007bff;
}

.metricas-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}

.metric-card {
  padding: 1.5rem;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.metric-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.metric-header {
  display: flex;
  align-items: center;
}

.metric-icon {
  margin-right: 1rem;
  color: #007bff;
}

.metric-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #343a40;
}

.metric-description {
  font-size: 0.9rem;
  color: #6c757d;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.metric-card:hover .metric-description {
  max-height: 200px;
  margin-top: 1rem;
}

.metric-card:hover {
  background: linear-gradient(135deg, #007bff 0%, #6610f2 100%);
  color: white;
}

.metric-card:hover .metric-icon {
  color: white;
}

.metric-card:hover .metric-title {
  color: white;
}

.metric-card:hover .metric-description {
  color: white;
}
