.gameficacao-container {
  padding: 2rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
}

.gameficacao-container h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #eee;
}

.gameficacao-loading {
  text-align: center;
  padding: 2rem;
  color: #666;
}

.gameficacao-pontuacao {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: linear-gradient(to right, #f6f7f9, #edf1f7);
  border-radius: 8px;
  margin-bottom: 2rem;
}

.pontuacao-icone {
  font-size: 2rem;
  color: #ffd700;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
}

.gameficacao-pontuacao span {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
}

.gameficacao-conquistas {
  display: grid;
  gap: 1.5rem;
}

.conquista-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.conquista-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.conquista-icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: white;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.conquista-icone svg {
  font-size: 1.5rem;
  color: #ffd700;
}

.conquista-info {
  flex: 1;
}

.conquista-info h4 {
  font-size: 1.1rem;
  color: #333;
  margin: 0 0 0.25rem 0;
}

.conquista-info p {
  color: #666;
  font-size: 0.9rem;
  margin: 0 0 0.5rem 0;
  line-height: 1.4;
}

.conquista-info span {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  background: #e9ecef;
  border-radius: 12px;
  font-size: 0.875rem;
  color: #495057;
  font-weight: 500;
}

/* Animações */
@keyframes conquistaDestaque {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.conquista-item.nova {
  animation: conquistaDestaque 0.5s ease;
}

/* Responsividade */
@media (max-width: 768px) {
  .gameficacao-container {
    padding: 1rem;
  }

  .gameficacao-pontuacao {
    flex-direction: column;
    text-align: center;
  }

  .conquista-item {
    flex-direction: column;
    text-align: center;
  }

  .conquista-icone {
    margin: 0 auto;
  }

  .conquista-info h4 {
    margin-top: 0.5rem;
  }
}

/* Temas de conquistas */
.conquista-item.bronze {
  background: linear-gradient(to right, #f7e6d3, #e6d5c3);
}

.conquista-item.prata {
  background: linear-gradient(to right, #f0f0f0, #e0e0e0);
}

.conquista-item.ouro {
  background: linear-gradient(to right, #fff7e6, #ffe0b2);
}

/* Estados de carregamento */
.gameficacao-loading-item {
  background: #f0f0f0;
  height: 80px;
  border-radius: 8px;
  margin-bottom: 1rem;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.6;
  }
}