/* MenuVertical.css */

/* Container do menu vertical */
.menu-vertical-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 280px;
    height: 100%;
    background: linear-gradient(135deg, #1e1e1e, #2d2d2d);
    transition: all 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    z-index: 9999;
    border-radius: 0 20px 20px 0;
    box-shadow: 5px 0 15px rgba(0, 0, 0, 0.1);
    overflow-y: auto; /* Permite rolagem vertical quando necessÃ¡rio */
    scrollbar-width: none; /* Esconde a barra de rolagem no Firefox */
}

.menu-vertical-container::-webkit-scrollbar {
    display: none; /* Esconde a barra de rolagem no Chrome, Safari e Edge */
}

/* Estado fechado do menu */
.menu-vertical-container.closed {
    width: 80px;
    border-radius: 0 15px 15px 0;
    overflow: visible; /* Permite que os elementos fiquem visíveis fora do container */
}

/* Aumentar o tamanho dos ícones quando o menu estiver fechado */
.menu-vertical-container.closed .menu-vertical-icon {
    font-size: 28px; /* Ajuste conforme necessário */
}
.menu-vertical-container.closed .menu-vertical-link:hover .tooltip {
    opacity: 1;
    transform: translateX(0);
    pointer-events: none; /* Evita que o tooltip interfira com outros elementos */
}
/* Remover quaisquer marcadores antes dos itens de menu */
.menu-vertical-list,
.submenu-list {
    list-style: none; /* Garantir que não haja marcadores */
    padding: 0;
    margin: 0;
}

.menu-vertical-item::before,
.submenu-item::before {
    content: none !important; /* Remover marcadores antes dos itens */
}

/* Seções de logo */
.logo-section {
    display: flex;
    align-items: center;
    padding: 25px 20px;
    background: linear-gradient(90deg, #ffcf7f, #c28b4e);
    border-radius: 0 20px 0 0;
}

.menu-logo.expanded {
    width: 150px;
    transition: all 0.4s ease;
}

.menu-logo.collapsed {
    width: 50px;
    transition: all 0.4s ease;
}

/* Botão de alternância do menu */
.menu-toggle {
    background: none;
    border: none;
    color: #ffffff;
    font-size: 24px;
    cursor: pointer;
    margin-left: auto;
    transition: transform 0.4s ease;
}

.menu-toggle:hover {
    transform: rotate(180deg);
}

/* Navegação do menu */
.menu-vertical-nav {
    padding: 20px 0;
    /* Remover overflow-y para evitar barras de rolagem internas */
    /* overflow-y: auto; */
}

/* Lista principal do menu */
.menu-vertical-list {
    list-style: none; /* Garantir que não haja marcadores */
    padding: 0;
    margin: 0;
}

/* Itens principais do menu */
.menu-vertical-item {
    margin-bottom: 10px;
    position: relative; /* Para posicionar o overlay */
}

/* Links principais do menu */
.menu-vertical-link {
    display: flex;
    align-items: center;
    color: #fff;
    text-decoration: none;
    padding: 12px 20px;
    transition: all 0.3s ease;
    border-radius: 0 30px 30px 0;
    margin-right: 20px;
    text-transform: uppercase; /* Transformar texto em maiúsculas */
    font-weight: bold; /* Destacar menus principais */
}

/* Hover nos links principais */
.menu-vertical-link:hover {
    background: linear-gradient(90deg, rgba(255, 207, 127, 0.1), rgba(194, 139, 78, 0.1));
    transform: translateX(5px);
}

/* Ícones principais do menu */
.menu-vertical-icon {
    font-size: 22px;
    margin-right: 5px;
    color: #ffcf7f; /* Cor padrão do sistema para ícones principais */
    background: none; /* Remove background-clip e text-fill-color */
    -webkit-background-clip: none;
    -webkit-text-fill-color: currentColor;
    transition: all 0.3s ease;
}

/* Hover nos ícones principais */
.menu-vertical-link:hover .menu-vertical-icon {
    transform: scale(1.1);
}

/* Rodapé do menu vertical */
.menu-vertical-footer {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: #fff;
    opacity: 0.7;
    transition: opacity 0.3s ease;
}

.menu-vertical-footer:hover {
    opacity: 1;
}

.credits {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 14px;
}

.credits-details {
    font-size: 12px;
}

/* Estilo para links desabilitados */
.disabled-link {
    pointer-events: none;
    opacity: 0.6;
}

/* Overlay para indicar bloqueio */
.overlay-blocked {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semitransparente */
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    border-radius: 8px;
    pointer-events: none; /* Permite que os cliques passem para os elementos abaixo */
}


/* Responsividade para telas menores */
@media only screen and (max-width: 768px) {
    .menu-vertical-container {
        width: 0;
        overflow: hidden;
        transition: width 0.4s ease-in-out;
    }

    .menu-vertical-container.open {
        width: 250px;
    }

    .menu-logo.expanded,
    .menu-logo.collapsed {
        display: none;
    }
    
    .menu-vertical-container.closed {
        width: 1px;
        border-radius: 0 15px 15px 0;
    }

    .menu-toggle {
        position: fixed;
        top: 10px;
        left: 10px;
        z-index: 10000;
        background: linear-gradient(90deg, #ffcf7f, #c28b4e);
        padding: 10px;
        border-radius: 5px;
        width: 15%;
    }

    .menu-vertical-nav {
        display: none;
    }

    .menu-vertical-container.open .menu-vertical-nav {
        display: block;
    }

    .menu-vertical-container.open .menu-toggle {
        left: 260px;
    }
}

/* Tooltips para menus fechados */
.tooltip {
    display: inline-block;
    background: linear-gradient(135deg, #ffcf7f, #c28b4e);
    color: #1e1e1e;
    padding: 8px 12px;
    border-radius: 20px;
    position: absolute;
    left: 70px; /* Ajustado para ficar mais próximo dos ícones */
    white-space: nowrap;
    z-index: 1001;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-weight: 500;
    opacity: 0;
    transform: translateX(-10px);
    transition: all 0.3s ease;
}

.menu-vertical-link:hover .tooltip {
    opacity: 1;
    transform: translateX(0);
}

/* Submenus inicialmente escondidos */
.submenu-list,
.submenu-icons {
    display: none;
}

/* Mostrar submenus quando o menu estiver aberto */
.menu-vertical-container.open .submenu-list {
    display: block;
    padding-left: 40px;
}

/* Ajustes para ícones dos submenus */
.menu-vertical-container.closed .submenu-icons {
    display: block;
    position: absolute;
    top: 80px;
    left: 80px;
    z-index: 1000;
}

/* Ícones apenas nos submenus */
.submenu-icon-only {
    padding: 12px;
    margin-bottom: 10px;
    position: relative;
    background-color: rgba(30, 30, 30, 0.8);
    border-radius: 50%;
    transition: all 0.3s ease;
}

.submenu-icon-only:hover {
    background-color: #c28b4e;
    transform: scale(1.1);
}

.submenu-icon-only:hover .unique-submenu-icon {
    color: #1e1e1e;
}

/* Ícones dos submenus */
.submenu-icon {
    font-size: 18px;
}

/* Links dos submenus */
.submenu-link {
    color: #fff;
    text-decoration: none;
    padding: 8px 0;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
    text-transform: uppercase; /* Transformar texto em maiúsculas */
}

.submenu-link:hover {
    transform: translateX(5px);
}

.unique-submenu-icon {
    color: #ffcf7f;
    transition: all 0.3s ease;
}

.submenu-title {
    margin-left: 15px;
    font-size: 16px;
    color: #fff;
    transition: all 0.3s ease;
    text-transform: uppercase; /* Transformar texto em maiúsculas */
}

.submenu-link:hover .submenu-title {
    color: #ffcf7f;
}

.submenu-link:hover .unique-submenu-icon {
    transform: scale(1.1);
}

/* Adicionar uma barra de rolagem vertical invisível somente para submenus */
.menu-vertical-container.open .submenu-list {
    max-height: calc(100vh - 100px); /* Ajuste conforme necessário */
    overflow-y: auto; /* Permite rolagem vertical quando necessário */
    scrollbar-width: none; /* Esconde a barra de rolagem no Firefox */
}

.menu-vertical-container.open .submenu-list::-webkit-scrollbar {
    display: none; /* Esconde a barra de rolagem no Chrome, Safari e Edge */
}
