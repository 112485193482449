:root {
    --presente-black: #1A1A1A;
    --presente-red: #ea2b2b; 
    --presente-gold: #DBA642;
    --presente-white: #FFFFFF;
    --presente-gray: #333333;
    --presente-light-gray: #2A2A2A;
}

body {
    margin: 0;
    padding: 0;
    background: var(--presente-black);
    min-height: 100vh;
}
/* Estilo para a notificação de confirmação dentro do modal */
.presente-notification {
  margin-top: 15px; /* Espaço acima da notificação */
  padding: 10px 20px;
  background-color: #d4edda; /* Fundo verde claro */
  color: #155724; /* Texto verde escuro */
  border: 1px solid #c3e6cb; /* Borda verde */
  border-radius: 4px;
  font-size: 16px;
  text-align: center;
  animation: fadeIn 0.5s ease-in-out;
}

/* Animação de fade-in */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.presente-container {
    min-height: 100vh;
    width: 100%;
    background: var(--presente-black);
    font-family: 'Montserrat', sans-serif;
    color: var(--presente-white);
    margin: 0;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-top: 100px; /* Espaço para o cabeçalho fixo */
}

.presente-header {
    width: 100%;
    background: linear-gradient(to right, var(--presente-black), var(--presente-gray), var(--presente-black));
    padding: 20px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    transition: background 0.3s ease;
    height: 150px;
}

.presente-header::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, 
        transparent, 
        var(--presente-gold), 
        transparent
    );
}

.presente-logo-placeholder {
    height: 80px;
    width: 200px;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    position: relative;
    overflow: hidden;
    box-shadow: inset 0 0 10px rgba(0,0,0,0.5);
}

.presente-logo-placeholder::after {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.1),
        transparent
    );
    animation: shine 2s infinite;
}

@keyframes shine {
    to {
        left: 100%;
    }
}

.presente-logo {
    height: 80px;
    width: 200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.presente-logo img {
    max-height: 150%;
    max-width: 250%;
    object-fit: contain;
    margin-top: 100px;
}

.presente-main {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    padding: 20px 0;
    animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
}

.presente-title {
    font-size: 3.5rem;
    font-weight: 800;
    margin: 1250px 0 20px; /* Margem alta replicada conforme solicitado */
    background: linear-gradient(45deg, var(--presente-white) 30%, var(--presente-gold) 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 1.1;
    transition: margin 0.3s ease;
}

.presente-subtitle {
    font-size: 1.6rem;
    color: #CCCCCC;
    max-width: 800px;
    margin: 0 auto 40px;
    line-height: 1.4;
    font-weight: 400;
}

.presente-countdown {
    background: linear-gradient(145deg, var(--presente-gray) 0%, var(--presente-black) 100%);
    border-radius: 15px;
    padding: 30px;
    margin: 60px auto;
    max-width: 800px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.presente-countdown:hover {
    transform: scale(1.02);
    box-shadow: 0 25px 50px rgba(0, 0, 0, 0.5);
}

.presente-countdown::before {
    content: '🎁';
    position: absolute;
    top: -20px;
    right: -20px;
    font-size: 100px;
    opacity: 0.1;
    transform: rotate(15deg);
}

.presente-urgency {
    color: var(--presente-red);
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 35px;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    display: inline-block;
}

.presente-urgency::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 2px;
    background: var(--presente-red);
}

.presente-timer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 25px;
    margin: 0 auto;
    max-width: 800px;
    padding: 20px;
    transition: all 0.3s ease;
}

.timer-item {
    background: linear-gradient(145deg, var(--presente-black) 0%, var(--presente-gray) 100%);
    padding: 30px 20px;
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease, background 0.3s ease;
}

.timer-item:hover {
    transform: translateY(-5px);
    background: linear-gradient(145deg, var(--presente-gray) 0%, var(--presente-black) 100%);
}

.timer-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(90deg, 
        transparent,
        var(--presente-gold),
        transparent
    );
}

.timer-number {
    font-size: 3.5rem;
    font-weight: 700;
    color: var(--presente-gold);
    margin-bottom: 10px;
    text-shadow: 0 0 20px rgba(219, 166, 66, 0.3);
}

.timer-label {
    font-size: 1.1rem;
    color: #999;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
}

.presente-section {
    background: linear-gradient(145deg, var(--presente-gray) 0%, var(--presente-black) 100%);
    border-radius: 25px;
    padding: 40px;
    margin: 60px auto;
    max-width: 800px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.presente-section:hover {
    transform: scale(1.02);
    box-shadow: 0 25px 50px rgba(0, 0, 0, 0.5);
}

.presente-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
        circle at top right,
        rgba(219, 166, 66, 0.1),
        transparent 70%
    );
}

.presente-section h3 {
    color: var(--presente-gold);
    font-size: 2rem;
    margin-bottom: 20px;
    font-weight: 700;
    position: relative;
    display: inline-block;
}

.presente-section h3::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 2px;
    background: var(--presente-gold);
}

.presente-section p {
    color: #CCCCCC;
    font-size: 1.2rem;
    line-height: 1.4;
    margin-bottom: 15px;
    position: relative;
    z-index: 1;
}

.presente-final-message {
    background: linear-gradient(145deg, var(--presente-black) 0%, var(--presente-gray) 100%);
    border-radius: 25px;
    padding: 40px;
    margin: 60px auto;
    max-width: 800px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    position: relative;
    overflow: hidden;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.presente-final-message:hover {
    transform: scale(1.02);
    box-shadow: 0 25px 50px rgba(0, 0, 0, 0.5);
}

.presente-final-message::before {
    content: '✨';
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 40px;
    opacity: 0.2;
}

.presente-final-message::after {
    content: '💫';
    position: absolute;
    bottom: 20px;
    right: 20px;
    font-size: 40px;
    opacity: 0.2;
}

.presente-final-message h3 {
    color: var(--presente-gold);
    font-size: 2rem;
    margin-bottom: 20px;
    font-weight: 700;
    line-height: 1.4;
    position: relative;
    display: inline-block;
}

.presente-final-message p {
    color: #CCCCCC;
    font-size: 1.2rem;
    line-height: 1.4;
    margin: 0 auto;
    max-width: 800px;
    position: relative;
    z-index: 1;
}

.presente-cta-button {
    background: #74e639;
    color: var(--presente-white);
    border: none;
    padding: 15px 40px;
    font-size: 1.4rem;
    font-weight: 700;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 60px 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    box-shadow: 0 10px 20px rgba(230, 57, 70, 0.3);
}

.presente-cta-button:hover {
    box-shadow: 0 15px 30px rgba(230, 57, 70, 0.4);
    background: #ff4d4d;
}

.presente-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
    animation: fadeInOverlay 0.3s ease;
}

@keyframes fadeInOverlay {
    from { opacity: 0; }
    to { opacity: 1; }
}

.presente-modal {
    background: var(--presente-gray);
    padding: 40px;
    border-radius: 20px;
    width: 90%;
    max-width: 500px;
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 25px 50px rgba(0, 0, 0, 0.5);
    animation: slideDown 0.5s ease;
}

@keyframes slideDown {
    from { transform: translateY(-50px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}

.presente-modal h3 {
    color: var(--presente-gold);
    font-size: 1.8rem;
    margin-bottom: 30px;
    text-align: center;
}

.presente-modal-close {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    color: #999;
    font-size: 24px;
    cursor: pointer;
    transition: color 0.3s ease;
}

.presente-modal-close:hover {
    color: var(--presente-white);
}

.presente-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.presente-form-group input,
.presente-form-group textarea {
    width: 100%;
    padding: 15px;
    background: var(--presente-black);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    color: var(--presente-white);
    font-size: 1.1rem;
    transition: all 0.3s ease;
    box-shadow: inset 0 2px 5px rgba(0,0,0,0.5);
}

.presente-form-group input:focus,
.presente-form-group textarea:focus {
    outline: none;
    border-color: var(--presente-gold);
    box-shadow: 0 0 10px rgba(219, 166, 66, 0.5);
    transform: scale(1.02);
}

.presente-form-group textarea {
    min-height: 120px;
    resize: vertical;
}

.presente-submit-button {
    background: var(--presente-gold);
    color: var(--presente-black);
    border: none;
    padding: 18px 40px;
    font-size: 1.2rem;
    font-weight: 700;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    box-shadow: 0 5px 15px rgba(219, 166, 66, 0.3);
}

.presente-submit-button:hover {
    background: #E5B654;
    transform: translateY(-2px);
    box-shadow: 0 8px 20px rgba(219, 166, 66, 0.5);
}

.presente-date-info {
    width: 100%;
    background: var(--presente-red);
    color: var(--presente-white);
    text-align: center;
    padding: 10px 0;
    font-weight: bold;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
}

.presente-date-info p {
    margin: 0;
    font-size: 1.1rem;
}

.presente-date-info-bottom {
    width: 100%;
    background: var(--presente-red);
    color: var(--presente-white);
    text-align: center;
    padding: 10px 0;
    margin-top: 20px;
    border-radius: 8px;
}

.presente-date-info-bottom p {
    margin: 0;
    font-size: 1.1rem;
    font-weight: bold;
}

@media (max-width: 926px) {
    .presente-title {
        margin: 1200px 0 20px; /* Replicando a margem alta no mobile */
    }

    .presente-countdown,
    .presente-section,
    .presente-final-message,
    .presente-cta-button {
        margin: 40px auto;
    }

    .presente-countdown,
    .presente-section,
    .presente-final-message {
        padding: 20px;
    }

    .presente-timer {
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
    }

    .timer-number {
        font-size: 2.5rem;
    }

    .timer-label {
        font-size: 0.9rem;
    }

    .presente-cta-button {
        padding: 12px 30px;
        font-size: 1.2rem;
        margin: 30px 0;
    }
}


@media (max-width: 812px) {
    .presente-title {
        margin: 1400px 0 20px; /* Replicando a margem alta no mobile */
    }

    .presente-countdown,
    .presente-section,
    .presente-final-message,
    .presente-cta-button {
        margin: 40px auto;
    }

    .presente-countdown,
    .presente-section,
    .presente-final-message {
        padding: 20px;
    }

    .presente-timer {
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
    }

    .timer-number {
        font-size: 2.5rem;
    }

    .timer-label {
        font-size: 0.9rem;
    }

    .presente-cta-button {
        padding: 12px 30px;
        font-size: 1.2rem;
        margin: 30px 0;
    }
}

@media (max-width: 768px) {
    .presente-title {
        margin: 1400px 0 20px; /* Replicando a margem alta no mobile */
    }

    .presente-countdown,
    .presente-section,
    .presente-final-message,
    .presente-cta-button {
        margin: 40px auto;
    }

    .presente-countdown,
    .presente-section,
    .presente-final-message {
        padding: 20px;
    }

    .presente-timer {
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
    }

    .timer-number {
        font-size: 2.5rem;
    }

    .timer-label {
        font-size: 0.9rem;
    }

    .presente-cta-button {
        padding: 12px 30px;
        font-size: 1.2rem;
        margin: 30px 0;
    }
}

@media (max-width: 480px) {
    .presente-title {
        margin: 1500px 0 20px; /* Replicando a margem alta no mobile */
        font-size: 2rem;
    }

    .presente-main {
        padding: 10px 0;
    }

    .presente-countdown,
    .presente-section,
    .presente-final-message,
    .presente-cta-button {
        margin: 20px auto;
    }

    .presente-timer {
        display: flex;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        padding: 10px 0;
    }

    .timer-item {
        flex: 0 0 auto;
        width: 80px; /* Diminuindo a largura para caber horizontalmente */
        margin-right: 10px;
        scroll-snap-align: start;
        padding: 15px 10px; /* Reduzindo o padding */
    }

    .timer-number {
        font-size: 2rem;
    }

    .timer-label {
        font-size: 0.8rem;
    }

    .presente-cta-button {
        padding: 10px 25px;
        font-size: 1rem;
        margin: 20px 0;
    }
}
