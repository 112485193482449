.facebook-ads-dashboard-container {
  font-family: 'Inter', 'Segoe UI', 'Roboto', sans-serif;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-color: #f8fafc;
  color: #1e293b;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

/* ----- ADIÇÃO DAS CLASSES PARA EDIÇÃO, POP-UPS E DEMAIS FUNCIONALIDADES ----- */

.fad-edit-icon {
  opacity: 0;
  transition: opacity 0.2s ease;
  margin-left: 8px;
  cursor: pointer;
  font-size: 14px;
  color: #475569;
}

.fad-table-row:hover .fad-edit-icon {
  opacity: 1;
}

.fad-edit-name-input {
  width: 150px;
  border: 1px solid #cbd5e1;
  border-radius: 4px;
  padding: 2px 6px;
  font-size: 14px;
}

.fad-save-button {
  background: #22c55e;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  margin-left: 4px;
  cursor: pointer;
}

.fad-toggle-switch {
  position: relative;
  width: 40px;
  height: 20px;
  background: #cbd5e1;
  border-radius: 20px;
  cursor: pointer;
  transition: background 0.2s ease;
}

.fad-toggle-switch:before {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  background: #ffffff;
  border-radius: 50%;
  top: 2px;
  left: 2px;
  transition: transform 0.2s ease;
}

.fad-toggle-switch.active {
  background: #22c55e;
}

.fad-toggle-switch.active:before {
  transform: translateX(20px);
}

.fad-budget-input {
  width: 80px;
  border: 1px solid #cbd5e1;
  border-radius: 4px;
  padding: 2px 6px;
  font-size: 14px;
  margin-right: 4px;
}

.fad-duplicate-selected-btn {
  background: #3b82f6;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 10px 18px;
  font-size: 14px;
  cursor: pointer;
  margin-left: 10px;
}

.fad-duplicate-selected-btn:hover {
  background: #2563eb;
}

.fad-item-name-container {
  display: flex;
  align-items: center;
  gap: 4px;
}

/* ----- HEADER ----- */
.facebook-ads-dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(145deg, #000000, #051c3c);
  color: white;
  padding: 15px 30px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  flex-shrink: 0;
  height: 70px;
  position: relative;
  z-index: 10;
  backdrop-filter: blur(10px);
}

.header-left {
  display: flex;
  align-items: center;
  gap: 24px;
}

.header-logo {
  height: 90px;
  filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.3));
  transition: all 0.3s ease;
}

.header-logo:hover {
  filter: drop-shadow(0 0 12px rgba(255, 255, 255, 0.5));
  transform: scale(1.02);
}

.back-button {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.back-button:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.header-right {
  display: flex;
  align-items: center;
  gap: 24px;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 16px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.user-info:hover {
  background: rgba(255, 255, 255, 0.15);
  border-color: rgba(255, 255, 255, 0.3);
  transform: translateY(-1px);
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid rgba(255, 255, 255, 0.3);
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.user-info:hover .user-avatar {
  border-color: rgba(255, 255, 255, 0.6);
  transform: scale(1.05);
}

.user-name {
  font-weight: 500;
  letter-spacing: 0.3px;
  color: rgba(255, 255, 255, 0.9);
}

.logout-button {
  background: linear-gradient(135deg, #dc2626, #991b1b);
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(220, 38, 38, 0.3);
}

.logout-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(220, 38, 38, 0.4);
  background: linear-gradient(135deg, #b91c1c, #7f1d1d);
}

/* ----- CONTEÚDO PRINCIPAL ----- */
.facebook-ads-dashboard-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  background: #ffffff;
  margin: 24px;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.fad-main-content {
  flex: 1;
  padding: 28px;
  overflow: auto;
  background-color: #ffffff;
  border-radius: 16px;
}

.fad-main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
  padding-bottom: 24px;
  border-bottom: 1px solid #e2e8f0;
  flex-wrap: wrap;
}

.fad-main-header-left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.fad-title {
  font-size: 28px;
  font-weight: 700;
  color: #1e293b;
  letter-spacing: -0.5px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.fad-main-header-right {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}

.fad-button-group {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 24px;
}

/* ----- BOTÕES GERAIS ----- */
.fad-button {
  background: #ffffff;
  color: #475569;
  border: 2px solid #e2e8f0;
  padding: 10px 18px;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.fad-button:hover {
  background: #f8fafc;
  border-color: #cbd5e1;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
}

.fad-button.active {
  background: #3b82f6;
  color: white;
  border-color: #2563eb;
  box-shadow: 0 4px 12px rgba(59, 130, 246, 0.3);
}

.fad-metrics-selector-button {
  background: linear-gradient(135deg, #6366f1, #4f46e5);
  color: #ffffff;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(99, 102, 241, 0.3);
}

.fad-metrics-selector-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(99, 102, 241, 0.4);
  background: linear-gradient(135deg, #4f46e5, #4338ca);
}

.metric-card {
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;
  border: 1px solid #e2e8f0;
}

.metric-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
}

.metric-title {
  font-size: 16px;
  font-weight: 600;
  color: #475569;
  margin-bottom: 8px;
}

.metric-value {
  font-size: 24px;
  font-weight: 700;
  color: #1e293b;
}

/* ----- FILTROS ----- */
.fad-filters {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
  margin-bottom: 20px;
  padding: 20px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.fad-filter-item {
  display: flex;
  flex-direction: column;
}

.fad-filter-label {
  font-size: 14px;
  color: #7f8c8d;
  margin-bottom: 5px;
}

.fad-filters select,
.fad-filters input[type="text"],
.fad-filters input[type="date"] {
  appearance: none;
  background-color: #ecf0f1;
  border: 1px solid #bdc3c7;
  border-radius: 8px;
  padding: 10px 16px;
  font-size: 16px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.fad-filters select:hover,
.fad-filters input[type="text"]:hover,
.fad-filters input[type="date"]:hover {
  border-color: #2980b9;
}

.fad-filters select:focus,
.fad-filters input[type="text"]:focus,
.fad-filters input[type="date"]:focus {
  border-color: #2980b9;
  box-shadow: 0 0 0 3px rgba(41, 128, 185, 0.2);
  outline: none;
}

/* ----- CALENDÁRIO ----- */
.fad-date-selector-wrapper {
  position: relative;
}

.fad-date-selector {
  display: flex;
  flex-direction: column;
}

.fad-date-selector-content {
  background-color: #ffffff;
  border: 1px solid #bdc3c7;
  border-radius: 6px;
  padding: 10px;
  margin-top: 10px;
  z-index: 10;
  transition: all 0.3s ease;
}

.fad-date-selector-content.dragging {
  cursor: grab;
}

.fad-date-inputs {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.fad-date-preset-buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.fad-button.date-toggle-button {
  justify-content: flex-start;
}

.fad-button.date-toggle-button svg {
  margin-right: 5px;
}

/* ----- SELETOR DE MÉTRICAS ----- */
.fad-metrics-selector {
  position: relative;
}

.fad-metrics-selector-button {
  background: linear-gradient(135deg, #6366f1, #4f46e5);
  color: #ffffff;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(99, 102, 241, 0.3);
}

.fad-metrics-selector-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(99, 102, 241, 0.4);
  background: linear-gradient(135deg, #4f46e5, #4338ca);
}

.fad-metrics-selector-button.active {
  background: #16a085;
  box-shadow: 0 8px 16px rgba(22, 160, 133, 0.4);
}

.fad-metrics-selector-content {
  position: absolute;
  top: 100%;
  left: 0;
  width: 400px;
  background: #ffffff;
  border: 1px solid #bdc3c7;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 8px;
  z-index: 100;
  transition: all 0.3s ease;
}

.fad-metrics-selector-content.dragging {
  cursor: grab;
}

.fad-metrics-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 300px;
  overflow-y: auto;
}

.fad-metric-checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
}

.fad-metric-checkbox input {
  cursor: pointer;
}

.fad-metric-checkbox label {
  cursor: pointer;
}

/* ----- IDENTIFICADORES DE CAMPANHA ----- */
.fad-campaign-type,
.fad-campaign-objective {
  display: inline-block;
  padding: 4px 8px;
  background: #bdc3c7;
  border-radius: 6px;
  font-weight: 600;
  color: #2c3e50;
  margin-right: 8px;
  transition: background 0.3s ease;
}

.fad-campaign-type:hover,
.fad-campaign-objective:hover {
  background: #95a5a6;
}

/* ----- INFO ADICIONAL (BARRA AZUL) ----- */
.fad-additional-info {
  margin-bottom: 20px;
  font-size: 16px;
  color: #2c3e50;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 20px;
  border-left: 4px solid #2980b9;
  background-color: #ecf0f1;
  border-radius: 4px;
}

/* ----- STATUS ----- */
.fad-status.active {
  background-color: #22c55e;
  box-shadow: 0 0 0 4px rgba(34, 197, 94, 0.2);
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.fad-status.inactive {
  background-color: #ef4444;
  box-shadow: 0 0 0 4px rgba(239, 68, 68, 0.2);
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

/* ----- NOME DO ITEM (CAMPANHA / ADSET / AD) ----- */
.fad-item-name {
  cursor: pointer;
  color: #183050;
  position: relative;
  transition: color 0.3s ease;
}

.fad-item-name:hover {
  color: #3498db;
}

.fad-item-name::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0%;
  height: 2px;
  background-color: #2980b9;
  transition: width 0.3s ease;
}

.fad-item-name:hover::after {
  width: 100%;
}

/* ----- TABELA ----- */
.fad-table-container {
  overflow-x: auto;
  overflow-y: auto;
  border-radius: 12px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
  background: #ffffff;
  margin-top: 24px;
  border: 1px solid #e2e8f0;
  position: relative;
  max-height: 600px;
}

.fad-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
}

.fad-table th,
.fad-table td {
  padding: 12px 16px;
  font-size: 14px;
  border-bottom: 1px solid #f1f5f9;
  transition: background 0.2s ease;
  white-space: nowrap;
}

.fad-table th {
  background: linear-gradient(145deg, #1e293b, #334155);
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-size: 13px;
  position: sticky;
  top: 0;
  z-index: 2;
  cursor: pointer;
  padding: 16px;
  border-bottom: 2px solid #475569;
}

.fad-table th:hover {
  background: linear-gradient(145deg, #334155, #1e293b);
}

.fad-table-row {
  transition: all 0.2s ease;
  border-bottom: 1px solid #f1f5f9;
}

.fad-table-row:nth-child(even) {
  background-color: #f8fafc;
}

.fad-table-row:hover {
  background-color: #f1f5f9;
}

.fad-table-row.selected {
  background-color: #e0f2fe;
  border-left: 3px solid #3b82f6;
}

.fad-table td.sticky-column {
  position: sticky;
  left: 0;
  z-index: 45;
  background-color: inherit;
}

.fad-table th.sticky-column {
  position: sticky;
  left: 0;
  z-index: 45;
  background: linear-gradient(145deg, #1e293b, #334155);
}

/* ----- ALINHAMENTO DE NÚMEROS ----- */
.fad-table td[data-type="number"] {
  font-family: 'Inter', monospace;
  font-variant-numeric: tabular-nums;
  text-align: right;
  color: #1e293b;
  font-weight: 500;
}

.fad-table td[data-trend="positive"] {
  color: #16a34a;
}

.fad-table td[data-trend="negative"] {
  color: #dc2626;
}

/* ----- AÇÃO APARECE AO HOVER ----- */
.fad-table .action-cell {
  opacity: 0;
  transition: opacity 0.2s ease;
}

.fad-table-row:hover .action-cell {
  opacity: 1;
}

/* ----- FOOTER DA TABELA (TOTAL) ----- */
.fad-table tfoot {
  background: linear-gradient(145deg, #f8fafc, #f1f5f9);
  font-weight: 600;
  position: sticky;
  bottom: 0;
  z-index: 40;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.05);
}

.fad-table tfoot td {
  color: #1e293b;
  border-top: 2px solid #e2e8f0;
  font-size: 14px;
  padding: 16px;
}

/* Status indicators dentro da tabela */
.fad-table .status-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
}

.fad-table .status-active {
  background: #22c55e;
  box-shadow: 0 0 0 2px rgba(34, 197, 94, 0.2);
}

.fad-table .status-inactive {
  background: #ef4444;
  box-shadow: 0 0 0 2px rgba(239, 68, 68, 0.2);
}

/* ----- MODAL (POP-UP) ----- */
.fad-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.fad-modal-content {
  background-color: #ffffff;
  padding: 32px;
  border-radius: 16px;
  max-width: 600px;
  max-height: 90%;
  overflow-y: auto;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  transform: scale(0.95);
  opacity: 0;
  animation: modalAppear 0.3s ease forwards;
}

@keyframes modalAppear {
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* ----- LOADING SPINNER ----- */
.fad-loading-spinner {
  position: fixed;
  top: 24px;
  right: 24px;
  z-index: 1002;
  background: #ffffff;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* ----- RESPONSIVIDADE ----- */
@media (max-width: 1200px) {
  .facebook-ads-dashboard-content {
    margin: 16px;
  }

  .fad-main-content {
    padding: 16px;
  }

  .fad-filters {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .facebook-ads-dashboard-header {
    padding: 12px 16px;
    height: auto;
  }

  .header-right {
    gap: 12px;
  }

  .user-info {
    padding: 6px 12px;
  }

  .fad-main-header {
    flex-direction: column;
    gap: 16px;
    align-items: stretch;
  }

  .fad-button-group {
    flex-direction: column;
    gap: 12px;
  }

  .fad-button {
    width: 100%;
    justify-content: center;
  }

  .fad-filters {
    grid-template-columns: 1fr;
    padding: 16px;
  }

  .fad-table th,
  .fad-table td {
    padding: 10px 12px;
    font-size: 13px;
  }

  .fad-date-preset-buttons {
    flex-direction: column;
    align-items: stretch;
  }

  .fad-date-preset-buttons .fad-button {
    width: 100%;
    text-align: center;
  }

  .fad-metrics-selector-content {
    width: 100%;
    padding: 16px;
  }

  .fad-campaign-type,
  .fad-campaign-objective {
    margin-bottom: 8px;
  }
}

@media (max-width: 480px) {
  .facebook-ads-dashboard-content {
    margin: 8px;
  }

  .header-logo {
    height: 32px;
  }

  .user-name {
    display: none;
  }

  .fad-title {
    font-size: 24px;
  }

  .fad-main-header-right {
    flex-direction: column;
    align-items: stretch;
  }

  .fad-button {
    width: 100%;
    justify-content: center;
  }

  .fad-filters {
    grid-template-columns: 1fr;
  }

  .fad-filter-item {
    margin-bottom: 15px;
  }

  .fad-table th,
  .fad-table td {
    padding: 8px 10px;
    font-size: 12px;
  }

  .fad-update-text {
    font-size: 12px;
  }

  .fad-refresh-container {
    width: 100%;
    justify-content: space-between;
  }

  .fad-navigation-buttons .fad-button {
    width: 100%;
    text-align: center;
  }

  .fad-metrics-selector-content {
    width: 100%;
    padding: 12px;
  }
}

/* ----- SELETOR DE CORES DAS MÉTRICAS ----- */
.metric-color-selector {
  display: flex;
  gap: 4px;
  margin-left: 8px;
  align-items: center;
}

.color-circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border 0.3s ease, transform 0.3s ease;
}

.color-circle.selected-color {
  border: 2px solid #000000;
}

.colored-cell {
  background-color: rgba(255, 255, 255, 0.2);
}

/* ----- COLUNAS FIXAS (STICKY) ----- */
.sticky-column {
  position: sticky;
  left: 0;
  background-color: #ffffff;
  z-index: 40;
  transition: left 0.3s ease;
}

.sticky-column:nth-child(1) {
  left: 0;
  z-index: 45;
}

.sticky-column:nth-child(2) {
  left: 50px;
  z-index: 44;
}

.sticky-column:nth-child(3) {
  left: 139px;
  z-index: 43;
}

.fad-table td.sticky-column {
  position: sticky;
  left: 0;
  z-index: 45;
  background-color: #ffffff;
}

.fad-table td.sticky-column:nth-child(2) {
  left: 50px;
  z-index: 0;
}

.fad-table td.sticky-column:nth-child(3) {
  left: 139px;
  z-index: 0;
}

/* Repetimos as classes de status para garantir que não fiquem sobrescritas */
.fad-status.active {
  background-color: #22c55e;
  box-shadow: 0 0 0 4px rgba(34, 197, 94, 0.2);
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.fad-status.inactive {
  background-color: #ef4444;
  box-shadow: 0 0 0 4px rgba(239, 68, 68, 0.2);
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.fad-item-name {
  cursor: pointer;
  color: #183050;
  position: relative;
  transition: color 0.3s ease;
}

.fad-item-name:hover {
  color: #3498db;
}

.fad-item-name::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0%;
  height: 2px;
  background-color: #2980b9;
  transition: width 0.3s ease;
}

.fad-item-name:hover::after {
  width: 100%;
}

/* FOOTER DA TABELA (TOTAL) */
.fad-table tfoot {
  background-color: #e2e8f0;
  font-weight: bold;
  position: sticky;
  bottom: 0;
  z-index: 40;
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1);
}

.fad-table tfoot td {
  background-color: #e2e8f0;
  color: #1e293b;
  position: sticky;
  bottom: 0;
  z-index: 40;
}

/* Layout interno do pop-up do seletor de métricas */
.fad-modal-content .fad-metric-checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
}

.fad-modal-content .fad-metric-checkbox label {
  flex: 1;
  cursor: pointer;
}
