.gerador-audio-container-unique {
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 10px;
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.gerador-audio-container-unique h2 {
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
}

.gerador-audio-container-unique h3 {
  color: #333;
  font-size: 20px;
  margin-bottom: 15px;
}

.gerador-audio-container-unique .textarea-unique {
  width: 100%;
  height: 100px;
  margin-bottom: 15px;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.gerador-audio-container-unique .select-unique {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.gerador-audio-container-unique .button-unique {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 15px;
}

.gerador-audio-container-unique .button-unique:disabled {
  background-color: #aaa;
}

.bloco-gerar-audio, .bloco-listar-audios, .bloco-listar-vozes {
  margin-bottom: 30px;
}

.audios-salvos-unique audio, .vozes-disponiveis-unique audio {
  display: block;
  margin: 10px auto;
}

.audios-salvos-unique h4, .vozes-disponiveis-unique h4 {
  margin-bottom: 10px;
  color: #333;
}
