/* CriarCursos.css */

.cc-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Roboto', sans-serif;
  color: #ecf0f1;
  background-color: #1a1a1a;
}

.cc-form {
  background: rgba(40, 40, 40, 0.95);
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  margin-bottom: 2rem;
  border: 1px solid rgba(255, 200, 113, 0.1);
}

.cc-title {
  font-size: 1.75rem;
  background: linear-gradient(45deg, #FFC871, #C28B4E);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-bottom: 1.5rem;
  border-bottom: 2px solid rgba(255, 200, 113, 0.3);
  padding-bottom: 0.5rem;
}

.cc-input-group {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.cc-input, .cc-textarea, .cc-select {
  width: 100%;
  padding: 0.85rem 1rem;
  border: 1px solid rgba(255, 200, 113, 0.2);
  border-radius: 8px;
  font-size: 1rem;
  background-color: rgba(30, 30, 30, 0.95);
  color: #ecf0f1;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.cc-input::placeholder, .cc-textarea::placeholder, .cc-select::placeholder {
  color: #95a5a6;
}

.cc-input:focus, .cc-textarea:focus, .cc-select:focus {
  outline: none;
  border-color: #FFC871;
  box-shadow: 0 0 0 2px rgba(255, 200, 113, 0.1);
  background-color: rgba(40, 40, 40, 0.95);
}

.cc-textarea {
  min-height: 120px;
  resize: vertical;
}

.cc-btn-primary, .cc-btn-secondary, .cc-btn-save, .cc-btn-cancel {
  padding: 0.85rem 1.25rem;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.cc-btn-primary {
  background: linear-gradient(45deg, #FFC871, #C28B4E);
  color: #000;
}

.cc-btn-secondary {
  background-color: rgba(127, 140, 141, 0.2);
  color: #ecf0f1;
  border: 1px solid rgba(255, 200, 113, 0.2);
}

.cc-btn-save {
  background: linear-gradient(45deg, #27ae60, #219a52);
  color: #fff;
}

.cc-btn-cancel {
  background: linear-gradient(45deg, #e74c3c, #c0392b);
  color: #fff;
}

.cc-btn-primary:hover, .cc-btn-secondary:hover, .cc-btn-save:hover, .cc-btn-cancel:hover {
  filter: brightness(1.1);
  transform: translateY(-2px);
}

.cc-btn-primary:active, .cc-btn-secondary:active, .cc-btn-save:active, .cc-btn-cancel:active {
  transform: translateY(0);
}

.cc-separator {
  height: 1px;
  background-color: rgba(127, 140, 141, 0.2);
  margin: 2rem 0;
}

.cc-input-icon {
  position: relative;
}

.cc-input-icon svg {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #95a5a6;
}

.cc-input-icon input {
  padding-left: 2.5rem;
  background-color: rgba(30, 30, 30, 0.95);
  color: #ecf0f1;
  border: 1px solid rgba(255, 200, 113, 0.2);
}

.cc-input-icon input::placeholder {
  color: #95a5a6;
}

.cc-modulos-lista {
  background: rgba(40, 40, 40, 0.95);
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 200, 113, 0.1);
  min-height: 100px;
}

.cc-modulo-card {
  background-color: rgba(30, 30, 30, 0.95);
  border: 1px solid rgba(255, 200, 113, 0.2);
  border-radius: 8px;
  margin-bottom: 1rem;
  transition: all 0.3s ease;
}

.cc-modulo-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 200, 113, 0.4);
}

.cc-modulo-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem;
  cursor: pointer;
}

.cc-modulo-titulo {
  margin: 0;
  font-size: 1.3rem;
  color: #FFC871;
}

.cc-modulo-actions {
  display: flex;
  gap: 0.75rem;
}

.cc-btn-icon, .cc-btn-icon-delete {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 200, 113, 0.1);
  cursor: pointer;
  padding: 0.4rem;
  border-radius: 8px;
  transition: all 0.3s ease;
  color: #ecf0f1;
}

.cc-btn-icon:hover {
  background-color: rgba(255, 200, 113, 0.1);
  transform: scale(1.1);
}

.cc-btn-icon-delete:hover {
  background-color: rgba(231, 76, 60, 0.1);
  color: #e74c3c;
  transform: scale(1.1);
}

.cc-aulas-lista {
  padding: 1.25rem;
  background: rgba(20, 20, 20, 0.5);
  border-radius: 0 0 8px 8px;
  min-height: 50px;
}

.cc-aula-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.85rem 1rem;
  background-color: rgba(30, 30, 30, 0.95);
  border: 1px solid rgba(255, 200, 113, 0.2);
  border-radius: 8px;
  margin-bottom: 0.75rem;
  transition: all 0.3s ease;
}

.cc-aula-item:hover {
  background-color: rgba(40, 40, 40, 0.95);
  border-color: rgba(255, 200, 113, 0.4);
}

.cc-aula-actions {
  display: flex;
  gap: 0.5rem;
}

.cc-modulo-edit, .cc-aula-edit {
  padding: 1.25rem;
  background-color: rgba(40, 40, 40, 0.95);
  border-radius: 8px;
  border: 1px solid rgba(255, 200, 113, 0.1);
}

.cc-btn-group {
  display: flex;
  gap: 0.75rem;
  margin-top: 1.25rem;
}

.cc-mensagem-sucesso {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background: linear-gradient(45deg, #27ae60, #219a52);
  color: #fff;
  padding: 1rem 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  animation: fadeInOut 3s ease forwards;
  z-index: 1000;
  font-size: 1rem;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0; transform: translateY(20px); }
  10%, 90% { opacity: 1; transform: translateY(0); }
}

/* Mantendo as classes essenciais para o drag and drop */
[data-rbd-draggable-context-id] {
  user-select: none;
}

[data-rbd-droppable-id] {
  min-height: 50px;
}

[data-rbd-draggable-context-id="0"] {
  outline: none !important;
}

.dragging {
  opacity: 0.5;
  background: rgba(255, 200, 113, 0.1) !important;
}

@media (max-width: 768px) {
  .cc-container {
    padding: 1rem;
  }

  .cc-form, .cc-modulos-lista {
    padding: 1rem;
  }

  .cc-modulo-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .cc-modulo-actions {
    margin-top: 0.5rem;
  }

  .cc-aula-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .cc-aula-actions {
    margin-top: 0.5rem;
  }
}
