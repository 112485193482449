.suporte-container {
  background: url('../../imagens/fundo-login.webp') repeat center center fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;
  text-align: left;
  padding: 20px;
  box-sizing: border-box;
}

.suporte-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 0;
  padding: 0 20px;
  box-sizing: border-box;
}

.suporte-logo {
  height: 200px;
  margin: 0 auto;
}

.suporte-user-info {
  position: absolute;
  top: 140px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  background: #0009;
  border-radius: 5px;
}

.suporte-user-info span {
  margin-bottom: 10px;
}

.suporte-button {
  background: linear-gradient(to right, #fac97b, #d5a05e);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.suporte-button:hover {
  background: #C28B4E;
}

.suporte-content {
  margin-top: 200px;
  padding-top: 50px;
  width: 100%;
  max-width: 900px;
  text-align: center;
  animation: fadeInUp 1s ease-out;
}

.suporte-title-gradient {
  font-size: 2.5em;
  background: linear-gradient(to right, #FFC871, #997844);
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 20px;
  animation: fadeInUp 1s ease-out;
}

.suporte-description {
  font-size: 1.2em;
  max-width: 800px;
  margin: 0 auto 20px;
  color: #FFFFFF;
  animation: fadeInUp 1s ease-out;
}

.suporte-button-large {
  background: linear-gradient(to right, #fac97b, #d5a05e);
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeInUp 1s ease-out;
  margin-bottom: 20px;
}

.suporte-button-large:hover {
  background: #C28B4E;
}

.suporte-button-large svg {
  margin-right: 10px;
}

.suporte-button-menu {
  background: linear-gradient(to right, #fac97b, #d5a05e);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  animation: fadeInUp 1s ease-out;
}

.suporte-button-menu:hover {
  background: #C28B4E;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .suporte-header {
    flex-direction: column;
    align-items: center;
  }

  .suporte-logo {
    height: 100px;
    margin-bottom: 10px;
  }

  .suporte-user-info {
    top: 90px;
  }

  .suporte-content {
    margin-top: 150px;
    padding: 0 20px;
  }

  .suporte-title-gradient {
    font-size: 2em;
  }

  .suporte-description {
    font-size: 1em;
  }
}
