/* FacebookLogin.css */

/* Importar Font Awesome para os ícones */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css');

.facebook-login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.facebook-login-button {
  display: flex;
  align-items: center;
  justify-content: center;
 background: linear-gradient(90deg, #1877F2, #1877F2); /* Cor oficial do Facebook */
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  outline: none;
  width: 100%;
}
.facebook-login-button:hover {
  background-color: #145dbf; /* Tom mais escuro para o hover */
}

.facebook-login-button:active {
  background-color: #0e4c99; /* Tom ainda mais escuro para o estado ativo */
}

.facebook-login-button i {
  margin-right: 10px;
  font-size: 18px;
}

.facebook-login-button:focus {
  box-shadow: 0 0 0 3px rgba(24, 119, 242, 0.5); /* Sombra para acessibilidade */
}
