.troca-senha-container-troca {
    background-color: rgba(78, 76, 76, 0.7);
    padding: 30px;
    border-radius: 20px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    animation: fadeIn 1s ease-in-out;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
}

.troca-senha-header-troca {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.troca-senha-icon-troca {
    color: #FFD700;
    margin-right: 15px;
    font-size: 32px;
    text-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
}

.troca-senha-titulo-troca {
    color: #FFD700;
    text-align: center;
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.troca-senha-instrucao-troca {
    text-align: center;
    color: #FFF;
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 1.5;
}

.input-container-troca-troca {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
}

.input-troca-troca {
    width: 100%;
    padding: 12px 15px;
    margin-top: 5px;
    border-radius: 10px;
    border: 2px solid rgba(221, 221, 221, 0.3);
    font-size: 16px;
    background-color: rgba(51, 51, 51, 0.7);
    color: #FFF;
    transition: all 0.3s ease;
}

.input-troca-troca:focus {
    outline: none;
    border-color: #FFD700;
    box-shadow: 0 0 10px rgba(255, 215, 0, 0.3);
}

.botao-troca-enviar,
.botao-troca-validar,
.botao-troca-redefinir {
    background: linear-gradient(45deg, #FFD700, #FFA500);
    color: #000;
    padding: 12px 25px;
    margin-top: 15px;
    border: none;
    border-radius: 50px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(255, 215, 0, 0.3);
}

.botao-troca-enviar:hover,
.botao-troca-validar:hover,
.botao-troca-redefinir:hover {
    background: linear-gradient(45deg, #FFA500, #FFD700);
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(255, 215, 0, 0.4);
}

.erro-troca-troca {
    color: #FF6B6B;
    text-align: center;
    margin-top: 15px;
    font-size: 14px;
    font-weight: bold;
}

.mensagem-troca-troca {
    text-align: center;
    color: #4ECCA3;
    font-size: 16px;
    margin-top: 15px;
    font-weight: bold;
}
.upload-message {
  margin-top: 10px;
  color: green; /* Ou a cor que preferir para mensagens de sucesso */
  font-size: 0.9rem;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Ajustes para Mobile */
@media only screen and (max-width: 768px) {
    .troca-senha-container-troca {
        width: 95%;
        padding: 25px;
    }

    .troca-senha-titulo-troca {
        font-size: 22px;
    }

    .input-troca-troca,
    .botao-troca-enviar,
    .botao-troca-validar,
    .botao-troca-redefinir {
        font-size: 16px;
    }
}