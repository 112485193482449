/* Login.css */
/* Prevent zoom and horizontal scroll on mobile */
body {
  font-family: 'Poppins', Arial, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: url('../imagens/fundo-login.webp') no-repeat center center fixed;
  background-size: cover;
  color: var(--text-color);
  overflow-x: hidden; /* Evita o scroll horizontal */
}

body {
  background-color: #0a0a0a;
  background-image: 
    radial-gradient(circle at 20% 30%, rgba(255, 136, 0, 0.15) 0%, transparent 50%),
    radial-gradient(circle at 80% 70%, rgba(255, 136, 0, 0.1) 0%, transparent 50%);
}

.login-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Alterado para centralizar verticalmente */
  padding: 2rem 1rem;
  position: relative;
  box-sizing: border-box; /* Garante que o padding seja contabilizado corretamente */
}

.logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px; /* Ajuste conforme necessário */
}

.login-logo {
  max-width: 100%; /* Ajusta o tamanho da logo */
  width: 300px; /* Ajuste o valor conforme o design desejado */
  height: auto;
}

.logo-container {
  margin-bottom: 2rem;
  width: 100%;
  max-width: 300px;
}

.login-logo {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.register-title {
  font-size: 1.8rem; /* Ajuste o tamanho conforme necessário */
  font-weight: 600;
  color: #ffffff; /* Ajuste a cor conforme o design */
  margin-bottom: 1rem;
  text-align: center;
}

.form-container {
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 2.5rem;
  width: 100%;
  max-width: 480px;
  box-shadow: 
    0 4px 24px -1px rgba(0, 0, 0, 0.2),
    0 0 16px -1px rgba(255, 136, 0, 0.05);
  animation: container-fade-in 0.5s ease-out;
  margin-top: 7rem; /* Adiciona espaçamento superior */
}

.gradient-text {
  background: linear-gradient(135deg, #ff8800, #ffa640);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1.5rem;
  font-weight: 600;
  text-align: center;
}

.login-title {
  font-size: 2.2em;
  font-weight: 700;
  margin-bottom: 0.5rem;
  background: linear-gradient(45deg, #ff8800, #ffa640);
  -webkit-background-clip: text;
  color: transparent;
}

.login-page-description {
  color: #a0a0a0;
  text-align: center;
  margin-bottom: 2rem;
  line-height: 1.6;
}

.input-container {
  position: relative;
  margin-bottom: 1.5rem;
}

.input-container input {
  width: 90%; /* Ajustado para ocupar todo o espaço disponível */
  padding: 1rem 1rem 1rem 2.5rem; /* Removido o espaço para o ícone do olhinho */
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
  color: #ffffff;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.input-container input:focus {
  outline: none;
  border-color: #ff8800;
  box-shadow: 0 0 0 2px rgba(255, 136, 0, 0.2);
}

/* Ícone do Cadeado */
.input-container i {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
  transition: color 0.3s ease;
}

.input-container input:focus + i {
  color: #ff8800;
}

/* Removendo o ícone do olhinho */
.toggle-password {
  display: none; /* Esconde o ícone do olhinho */
}

/* Caso queira remover completamente o espaço reservado para o ícone do olhinho */
.input-container input {
  padding-right: 1rem; /* Ajuste o padding direito se necessário */
}

/* Se houver regras que afetam o ícone do olhinho ao focar no input, remova-as */
/* Exemplo: Remover qualquer seletor que use .toggle-password com input:focus */

.error {
  color: #ff4444;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

/* Botão de envio e de voltar para a tela de login */
button {

  padding: 1rem;
  margin: 1.0rem 0 1rem;
  background-color: #f80;
  color: var(--text-color);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

}

form button,
.login-button-group button {
 
  padding: 1rem;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%
 
}

form button {
  background: linear-gradient(135deg, #ff8800, #ffa640);
  color: #ffffff;
  margin-bottom: 1.5rem;
}

form button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(255, 136, 0, 0.3);
}

form button:disabled {
  background: #666;
  cursor: not-allowed;
  transform: none;
}

.login-button-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.login-button-group button {
  background: transparent;
  border: 1px solid rgba(255, 136, 0, 0.5);
  color: #ff8800;
  width: 100%
}

.login-button-group button:hover {
  background: rgba(255, 136, 0, 0.1);
}

@keyframes container-fade-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/* Botão do YouTube */
.loginScreen-button-youtube {
  padding: 1rem;
  margin: 1rem 0;
  background: #FF0000; /* Cor padrão do YouTube */
  color: #ffffff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%; /* Restaurando tamanho do botão */
  max-width: 300px; /* Ajuste conforme necessário */
}

.loginScreen-button-youtube:hover {
  background: #e60000;
}

/* Animação */
@keyframes loginScreen-container-fade-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/* Configurações globais para prevenir zoom e scroll horizontal */
html {
  touch-action: manipulation;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}

body {
  min-height: 100%;
  overflow-x: hidden;
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
}

/* Meta viewport no seu HTML (adicione se não tiver): */
/* <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"> */

/* Estilos específicos para mobile */
@media (max-width: 480px) {
  .login-container {
    min-height: 100vh;
    padding: 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: 0;
  }

  .form-container {
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(10px);
    padding: 1.5rem;
    width: 100%;
    max-width: none;
    margin: 1rem auto;
    box-sizing: border-box;
    border-radius: 15px;
  }

  .logo-wrapper {
    width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
  }

  .login-logo {
    width: 200px;
    max-width: 80%;
    height: auto;
    margin: 0 auto;
  }

  .input-container {
    width: 100%;
    margin-bottom: 1rem;
  }

  .input-container input {
    width: 100%;
    box-sizing: border-box;
    padding: 0.875rem 1rem 0.875rem 2.5rem;
    font-size: 16px; /* Previne zoom no iOS */
  }

  /* .toggle-password já está escondido, mas garantindo */
  .toggle-password {
    display: none;
  }

  button {
    
    margin: 1rem 0;
    padding: 0.875rem;
    font-size: 16px;
  }

  .gradient-text {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .login-title {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
  }

  .login-page-description {
    font-size: 0.9rem;
    padding: 0 1rem;
    margin-bottom: 1.5rem;
  }

  /* Previne highlight ao tocar em elementos no mobile */
  * {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }

  /* Ajustes para telas muito pequenas */
  @media (max-height: 600px) {
    .login-container {
      padding: 0.5rem;
    }

    .form-container {
      padding: 1rem;
    }

    .logo-wrapper {
      padding: 0.5rem;
    }

    .login-logo {
      width: 150px;
    }
  }
}

/* Ajustes para tablets e telas médias */
@media (min-width: 481px) and (max-width: 768px) {
  .login-container {
    padding: 1.5rem;
  }

  .form-container {
    padding: 2rem;
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
  }

  .login-logo {
    width: 250px;
    max-width: 70%;
  }

  .input-container input {
    font-size: 16px;
  }

  button {
    padding: 1rem;
  }
}
