.checklist-container {
  min-height: 100vh;
  width: 100%;
  padding: 2rem;
  background: linear-gradient(135deg, #0a0a0a 0%, #141414 100%);
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
}
/* Adicionar estilo para o campo de tipo */
.form-group select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Estilo para exibir o tipo na tarefa */
.task-tipo, .modulo-tipo {
  font-size: 0.9em;
  color: #555;
  margin-top: 5px;
}

/* Opcional: estilizar a exibição do tipo na task-actions */
.task-tipo-info {
  margin-left: 10px;
  font-size: 0.9em;
  color: #555;
}

.indicador-card {

  border-left: 10px solid #007bff; /* Altere para a cor da borda desejada */
}

.indicador-card .task-content h4 {
  color: #007bff; /* Altere para a cor do título desejada */
}

.indicador-card .task-content p {
  color: #a2ff00ad; /* Altere para a cor do texto desejada */
   border-left: 10px solid #007bff; /* Altere para a cor da borda desejada */
  }
  
.progress-bar-container {
  margin: 20px 0;
  text-align: center;
}

.progress-bar {
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  height: 20px;
  width: 80%;
  margin: 0 auto;
}

.progress-bar-fill {
  background-color: #76c7c0;
  height: 100%;
  width: 0;
  transition: width 0.5s;
}

.progress-percentage {
  margin-top: 10px;
  font-weight: bold;
}

.checklist-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding: 1rem 2rem;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  border: 1px solid rgba(255, 200, 113, 0.1);
}

.checklist-header h1 {
  font-size: 2rem;
  background: linear-gradient(45deg, #FFC871, #C28B4E);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.header-buttons {
  display: flex;
  gap: 1rem;
}

.header-buttons button {
  padding: 0.8rem 1.5rem;
  background: linear-gradient(45deg, #FFC871, #C28B4E);
  border: none;
  border-radius: 8px;
  color: #000;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.header-buttons button:hover {
  transform: translateY(-2px);
  filter: brightness(1.1);
  box-shadow: 0 4px 15px rgba(255, 200, 113, 0.3);
}

.modulos-container {
  position: sticky;
  top: 0;
  display: flex;
  flex-wrap: nowrap;
  gap: 2rem;
  padding: 1rem;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #FFC871 rgba(255, 255, 255, 0.1);
  -webkit-overflow-scrolling: touch;
}

.modulos-container::-webkit-scrollbar {
  height: 8px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

.modulos-container::-webkit-scrollbar-thumb {
  background: #FFC871;
  border-radius: 4px;
}

.modulos-container::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

.modulo-card {
  min-width: 350px;
  max-width: 350px;
  background: rgba(40, 40, 40, 0.95);
  border-radius: 15px;
  border: 1px solid rgba(255, 200, 113, 0.1);
  overflow: hidden;
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
}

.modulo-header {
  display: flex;
  flex-wrap: wrap; /* Permite que os itens pulem para a próxima linha, se necessário */
  justify-content: space-between; /* Espaçamento entre os itens */
  align-items: center; /* Alinha os itens verticalmente ao centro */
  padding: 1.5rem;
  background: rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(255, 200, 113, 0.1);
}

.modulo-header h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #FFC871;
  word-wrap: break-word; /* Garante que o título grande quebre dentro do contêiner */
  overflow-wrap: break-word; /* Compatibilidade para navegadores mais modernos */
  text-align: left; /* Garante alinhamento consistente do texto */
}


.modulo-actions {
  display: flex;
  gap: 0.5rem;
}

.modulo-actions button {
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.modulo-actions button:not(.toggle-collapse) {
  background: rgba(255, 200, 113, 0.1);
  border: 1px solid rgba(255, 200, 113, 0.2);
  color: #FFC871;
}

.modulo-actions button:hover:not(.toggle-collapse) {
  background: rgba(255, 200, 113, 0.2);
  transform: translateY(-2px);
}

.delete-button {
  padding: 0.3rem 0.8rem;
  background: rgba(255, 77, 77, 0.1);
  border: 1px solid rgba(255, 77, 77, 0.2);
  border-radius: 4px;
  color: #ff4d4d;
  cursor: pointer;
  font-size: 0.8rem;
  transition: all 0.3s ease;
}

.delete-button:hover {
  background: rgba(255, 77, 77, 0.2);
  transform: translateY(-2px);
}

.toggle-collapse {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.tarefas01-container {
  height: 100%;
  padding: 1rem;
}

.tarefa-card {
  background: rgba(30, 30, 30, 0.95);
  border: 1px solid rgba(255, 200, 113, 0.1);
  border-radius: 8px;
  padding: 1.25rem;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
}

.tarefa-card:hover {
  transform: translateY(-2px);
  border-color: rgba(255, 200, 113, 0.3);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.tarefa-card h3 {
  margin: 0 0 0.5rem 0;
  color: #FFC871;
  font-size: 1.2rem;
  padding-right: 30px;
}

.tarefa-card p {
  margin: 0;
  color: #ecf0f1;
  font-size: 0.95rem;
  line-height: 1.5;
  max-height: 3em;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.tarefa-card p.expanded {
  max-height: none;
}

.tarefa-card input[type="checkbox"] {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.tarefa-card.concluido {
  opacity: 0.7;
  border-color: #4CAF50;
}

.tarefa-card.concluido h3 {
  text-decoration: line-through;
  color: #888;
}

/* Modal Styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.modal-content {
  max-width: 500px;
  background: rgba(40, 40, 40, 0.95);
  border-radius: 15px;
  padding: 2rem;
  width: 90%;
  border: 1px solid rgba(255, 200, 113, 0.1);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
}

.modal-content h2 {
  margin: 0 0 1.5rem 0;
  color: #FFC871;
  font-size: 1.8rem;
}

.modal-content form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.modal-content input,
.modal-content textarea {
  width: 100%;
  padding: 0.8rem 1rem;
  background: rgba(30, 30, 30, 0.95);
  border: 1px solid rgba(255, 200, 113, 0.2);
  border-radius: 8px;
  color: #ffffff;
  font-size: 1rem;
}

.modal-content textarea {
  min-height: 150px;
  line-height: 1.6;
  padding: 1rem;
  font-family: 'Roboto', sans-serif;
  resize: vertical;
}

.modal-content input:focus,
.modal-content textarea:focus {
  outline: none;
  border-color: #FFC871;
  box-shadow: 0 0 0 2px rgba(255, 200, 113, 0.1);
}

.modal-actions {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

.modal-actions button {
  flex: 1;
  padding: 0.8rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.modal-actions button[type="submit"] {
  background: linear-gradient(45deg, #FFC871, #C28B4E);
  color: #000;
  font-weight: 500;
}

.modal-actions button[type="button"] {
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.modal-actions button:hover {
  transform: translateY(-2px);
  filter: brightness(1.1);
}

.progress-container {
  margin: 2rem 0;
  text-align: center;
}

.progress-container input[type="range"] {
  width: 100%;
  margin-bottom: 1rem;
}

.progress-container span {
  font-size: 1.2rem;
  color: #FFC871;
}

/* Estilização da barra de rolagem */
.modulos-container::-webkit-scrollbar {
  height: 8px;
}

.modulos-container::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

.modulos-container::-webkit-scrollbar-thumb {
  background: #FFC871;
  border-radius: 4px;
}

/* Responsividade */
@media (max-width: 768px) {
  .checklist-container {
    padding: 1rem;
  }

  .checklist-header {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    padding: 1rem;
  }

  .header-buttons {
    width: 100%;
    justify-content: center;
  }

  .modulos-container {
    flex-direction: column;
    overflow-x: hidden;
  }
  
  .modulo-card {
    min-width: 100%;
    max-width: 100%;
  }
}

.task-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  margin: 0.5rem;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.task-card:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-2px);
}

.task-content {
  margin-bottom: 0.5rem;
}

.task-content h4 {
  color: #FFC871;
  margin: 0 0 0.5rem 0;
}

.task-content p {
  color: #ffffff;
  margin: 0;
  font-size: 0.9rem;
  line-height: 1.4;
}

.task-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.edit-button {
  padding: 0.3rem 0.8rem;
  background: rgba(255, 200, 113, 0.1);
  border: 1px solid rgba(255, 200, 113, 0.2);
  border-radius: 4px;
  color: #FFC871;
  cursor: pointer;
  font-size: 0.8rem;
}

.edit-button:hover {
  background: rgba(255, 200, 113, 0.2);
}

.complete-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #ffffff;
  font-size: 0.8rem;
}

.complete-label input[type="checkbox"] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.task-link-indicator {
  margin-top: 0.5rem;
  color: #FFC871;
  font-size: 0.8rem;
}

/* Modal de Tarefa */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #1a1a1a;
  border-radius: 15px;
  padding: 2rem;
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  border: 1px solid rgba(255, 200, 113, 0.1);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
}

.modal-content h2 {
  color: #FFC871;
  margin: 0 0 1.5rem 0;
}

.modal-content input[type="text"],
.modal-content input[type="url"],
.modal-content textarea {
  width: 100%;
  padding: 0.8rem;
  margin-bottom: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: #ffffff;
  font-size: 1rem;
}

.modal-content textarea {
  min-height: 150px;
  resize: vertical;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
}

.modal-actions button {
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.modal-actions button:first-child {
  background: linear-gradient(45deg, #FFC871, #C28B4E);
  border: none;
  color: #000;
}

.modal-actions button:last-child {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #ffffff;
}

.modal-actions button:hover {
  transform: translateY(-2px);
  filter: brightness(1.1);
}

/* Estilos para links nas tarefas */
.task-links {
  margin-top: 0.8rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.task-link {
  display: inline-flex;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background: rgba(255, 200, 113, 0.1);
  border: 1px solid rgba(255, 200, 113, 0.2);
  border-radius: 15px;
  color: #FFC871;
  text-decoration: none;
  font-size: 0.8rem;
  transition: all 0.3s ease;
}

.task-link:hover {
  background: rgba(255, 200, 113, 0.2);
  transform: translateY(-2px);
}

.task-link::before {
  content: "🔗";
  margin-right: 0.4rem;
}

/* Botão Ver Mais */
.ver-mais-btn {
  background: none;
  border: none;
  color: #FFC871;
  font-size: 0.8rem;
  padding: 0.3rem 0;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 0.5rem;
}

.ver-mais-btn:hover {
  color: #ffb84d;
}

/* Checkbox personalizado */
.checkbox-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.custom-checkbox {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 200, 113, 0.3);
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.05);
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
}

.custom-checkbox:checked {
  background: #FFC871;
  border-color: #FFC871;
}

.custom-checkbox:checked::before {
  content: "✓";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
  font-size: 14px;
  font-weight: bold;
}

.checkbox-label {
  color: #ffffff;
  font-size: 0.9rem;
  cursor: pointer;
}

/* Seção de Links no Modal */
.links-section {
  margin-top: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 1.5rem;
}

.link-item {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
  align-items: center;
  background: rgba(255, 255, 255, 0.03);
  padding: 0.8rem;
  border-radius: 8px;
}

.link-item input {
  flex: 1;
}

.remove-link-btn {
  padding: 0.3rem 0.8rem;
  background: rgba(255, 77, 77, 0.1);
  border: 1px solid rgba(255, 77, 77, 0.2);
  border-radius: 4px;
  color: #ff4d4d;
  cursor: pointer;
}

.add-link-form {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
}

.add-link-form input {
  flex: 1;
}

.add-link-btn {
  padding: 0.5rem 1rem;
  background: linear-gradient(45deg, rgba(255, 200, 113, 0.2), rgba(194, 139, 78, 0.2));
  border: 1px solid rgba(255, 200, 113, 0.3);
  border-radius: 4px;
  color: #FFC871;
  cursor: pointer;
  transition: all 0.3s ease;
}

.add-link-btn:hover {
  background: linear-gradient(45deg, rgba(255, 200, 113, 0.3), rgba(194, 139, 78, 0.3));
  transform: translateY(-2px);
}

/* Formatação do texto no modal */
.formatted-description {
  white-space: pre-wrap;
  line-height: 1.6;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  margin: 1rem 0;
}

/* Indicador de Progresso */
.task-card[data-tipo="indicador"] {
  background: linear-gradient(45deg, rgba(255, 200, 113, 0.2), rgba(194, 139, 78, 0.2));
  border: 2px solid #FFC871;
  position: relative;
  cursor: grab;
}

.task-card[data-tipo="indicador"]:active {
  cursor: grabbing;
}

.task-card[data-tipo="indicador"]::before {
  content: "📍";
  position: absolute;
  top: -10px;
  left: -10px;
  background: #1a1a1a;
  padding: 5px;
  border-radius: 50%;
  font-size: 1.2rem;
  z-index: 1;
}

/* Toast Notifications */
.Toastify__toast {
  background: rgba(26, 26, 26, 0.95) !important;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 200, 113, 0.1);
  border-radius: 8px !important;
  font-family: 'Roboto', sans-serif;
}

.Toastify__toast--success {
  border-color: rgba(88, 255, 144, 0.2) !important;
}

.Toastify__toast--error {
  border-color: rgba(255, 77, 77, 0.2) !important;
}

.Toastify__progress-bar {
  background: linear-gradient(45deg, #FFC871, #C28B4E) !important;
}

/* Confirmation Modal Styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.modal-content {
  background: rgba(40, 40, 40, 0.95);
  padding: 2rem;
  border-radius: 15px;
  min-width: 300px;
  max-width: 500px;
  border: 1px solid rgba(255, 200, 113, 0.2);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
}

.modal-content h2 {
  color: #FFC871;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.modal-content p {
  color: #ffffff;
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.modal-actions button {
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cancel-button {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #ffffff;
}

.cancel-button:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

.confirm-button {
  background: linear-gradient(45deg, #FFC871, #C28B4E);
  border: none;
  color: #000;
}

.confirm-button:hover {
  transform: translateY(-2px);
  filter: brightness(1.1);
  box-shadow: 0 4px 15px rgba(255, 200, 113, 0.3);
}

/* Edit button styles */
.edit-button {
  background: rgba(255, 200, 113, 0.1);
  border: 1px solid rgba(255, 200, 113, 0.2);
  color: #FFC871;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.8rem;
}

.edit-button:hover {
  background: rgba(255, 200, 113, 0.2);
  transform: translateY(-2px);
}

/* Form Styles */
.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  color: #FFC871;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid rgba(255, 200, 113, 0.2);
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.2);
  color: #ffffff;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #FFC871;
  box-shadow: 0 0 0 2px rgba(255, 200, 113, 0.1);
}

.form-group textarea {
  resize: vertical;
  min-height: 100px;
}

/* Add Task Button */
.add-task-button {
  background: rgba(255, 200, 113, 0.1);
  border: 1px solid rgba(255, 200, 113, 0.2);
  color: #FFC871;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9rem;
}

.add-task-button:hover {
  background: rgba(255, 200, 113, 0.2);
  transform: translateY(-2px);
}

.links-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.link-item {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
  border-radius: 8px;
  border: 1px solid rgba(255, 200, 113, 0.1);
}

.link-item input {
  flex: 1;
  background: transparent;
  border: none;
  color: #ffffff;
  padding: 0.5rem;
}

.link-item input:read-only {
  cursor: default;
}

.remove-link-button {
  background: rgba(255, 77, 77, 0.1);
  border: 1px solid rgba(255, 77, 77, 0.2);
  color: #ff4d4d;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.remove-link-button:hover {
  background: rgba(255, 77, 77, 0.2);
  transform: translateY(-2px);
}

.add-link-form {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-top: 0.5rem;
}

.add-link-button {
  background: rgba(255, 200, 113, 0.1);
  border: 1px solid rgba(255, 200, 113, 0.2);
  color: #FFC871;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.add-link-button:hover {
  background: rgba(255, 200, 113, 0.2);
  transform: translateY(-2px);
}

.task-details {
  margin: 1.5rem 0;
}

.task-description {
  white-space: pre-wrap;
  line-height: 1.6;
  color: #ffffff;
  margin-bottom: 1.5rem;
}

.task-links {
  margin-top: 1.5rem;
}

.task-links h3 {
  color: #FFC871;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.links-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.task-link {
  display: inline-block;
  padding: 0.5rem 1rem;
  background: rgba(255, 200, 113, 0.1);
  border: 1px solid rgba(255, 200, 113, 0.2);
  border-radius: 4px;
  color: #FFC871;
  text-decoration: none;
  transition: all 0.3s ease;
}

.task-link:hover {
  background: rgba(255, 200, 113, 0.2);
  transform: translateY(-2px);
  text-decoration: none;
}

input:focus, textarea:focus {
  outline: none;
  border-color: #FFC871;
  box-shadow: 0 0 0 2px rgba(255, 200, 113, 0.1);
}

.modal-content, .form-group, input, textarea, button {
  z-index: 1;
}