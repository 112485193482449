.estudio-criacao-container {
  background: url('../../imagens/fundo-login.webp') repeat center center fixed;
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;
  text-align: left;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.estudio-criacao-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.estudio-criacao-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 0;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 2;
}

.estudio-criacao-logo {
  height: 200px;
  margin: 0 auto;
  transition: transform 0.3s ease;
}

.estudio-criacao-logo:hover {
  transform: scale(1.05);
}

.estudio-criacao-user-info {
  position: absolute;
  top: 140px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  padding: 10px;
  backdrop-filter: blur(5px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.estudio-criacao-user-info span {
  margin-bottom: 10px;
  font-weight: 300;
}

.estudio-criacao-button {
  background: linear-gradient(45deg, #fac97b, #d5a05e);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.estudio-criacao-button:hover {
  background: linear-gradient(45deg, #d5a05e, #fac97b);
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.estudio-criacao-content {
  margin-top: 200px;
  padding-top: 50px;
  width: 100%;
  max-width: 900px;
  text-align: center;
  animation: fadeInUp 1s ease-out;
  z-index: 2;
  position: relative;
}

.estudio-criacao-title-gradient {
  font-size: 3em;
  background: linear-gradient(45deg, #FFC871, #997844);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-bottom: 20px;
  animation: fadeInUp 1s ease-out, gradientShift 5s ease infinite;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.estudio-criacao-description {
  font-size: 1.3em;
  max-width: 800px;
  margin: 0 auto 30px;
  color: #FFFFFF;
  animation: fadeInUp 1.2s ease-out;
  line-height: 1.6;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.estudio-criacao-button-large {
  background: linear-gradient(45deg, #fac97b, #d5a05e);
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 30px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  animation: fadeInUp 1.4s ease-out;
  margin-bottom: 20px;
  font-size: 1.1em;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.estudio-criacao-button-large:hover {
  background: linear-gradient(45deg, #d5a05e, #fac97b);
  transform: translateY(-3px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.estudio-criacao-button-large svg {
  margin-right: 10px;
  transition: transform 0.3s ease;
}

.estudio-criacao-button-large:hover svg {
  transform: rotate(15deg);
}

.estudio-criacao-button-menu {
  background: linear-gradient(45deg, #fac97b, #d5a05e);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  margin-top: 20px;
  animation: fadeInUp 1.6s ease-out;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.estudio-criacao-button-menu:hover {
  background: linear-gradient(45deg, #d5a05e, #fac97b);
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 768px) {
  .estudio-criacao-header {
    flex-direction: column;
    align-items: center;
  }

  .estudio-criacao-logo {
    height: 120px;
    margin-bottom: 10px;
  }

  .estudio-criacao-user-info {
    top: 100px;
    right: 10px;
    font-size: 0.9em;
  }

  .estudio-criacao-content {
    margin-top: 180px;
    padding: 0 20px;
  }

  .estudio-criacao-title-gradient {
    font-size: 2.2em;
  }

  .estudio-criacao-description {
    font-size: 1.1em;
  }

  .estudio-criacao-button-large,
  .estudio-criacao-button-menu {
    padding: 12px 24px;
    font-size: 1em;
  }
}

/* CSS específico para dispositivos móveis */
@media (max-width: 768px) {
    body {
        position: relative; /* Garante que o posicionamento não seja afetado */
        overflow-x: hidden; /* Bloqueia a rolagem horizontal */
    }
}
