.usuario-info {
  color: #fff;
  padding: 25px;
  background: linear-gradient(135deg, rgba(30, 30, 30, 0.95), rgba(45, 45, 45, 0.95));
  border-radius: 20px;
  width: 80%;
  max-width: 300px;
  text-align: left;
  margin: 20px auto;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.usuario-info:hover {
  transform: translateY(-8px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
}

.linha {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  transition: all 0.3s ease;
}

.linha:hover {
  transform: translateX(8px);
}

.icone,
.icone-usuario {
  margin-right: 15px;
  font-size: 20px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25%;
  background: linear-gradient(135deg, #ffcf7f, #c28b4e);
  color: #1e1e1e;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(255, 207, 127, 0.3);
}

.linha:hover .icone,
.linha:hover .icone-usuario {
  transform: scale(1.15) rotate(15deg);
}

.nome,
.email {
  font-size: 14px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.linha:hover .nome,
.linha:hover .email {
  color: #ffcf7f;
  text-shadow: 0 0 10px rgba(255, 207, 127, 0.5);
}

.barra-palavras {
  background-color: rgba(68, 68, 68, 0.7);
  border-radius: 20px;
  overflow: hidden;
  height: 30px;
  margin-bottom: 20px;
  position: relative;
  box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.4);
}

.progresso {
  background: linear-gradient(90deg, #ffcf7f, #c28b4e);
  height: 100%;
  transition: width 0.8s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  position: relative;
  overflow: hidden;
}

.progresso::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  background-size: 40px 40px;
  animation: moveStripes 1.5s linear infinite;
}

@keyframes moveStripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 40px 0;
  }
}
.foto-perfil {
  border: 2px solid #ddd;
    border-radius: 50%;
    height: 40px;
    object-fit: cover;
    width: 40px;
}
.quantidade-palavras {
  font-size: 16px;
  text-align: center;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffcf7f;
  font-weight: 700;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
}

.foto-perfil-vertical {
 border: 2px solid #ddd;
    border-radius: 50%;
    height: 60px;
    object-fit: cover;
    width: 60px;
}


/* Responsividade para celulares */
@media (max-width: 480px) {
  .usuario-info {
    padding: 20px;
    max-width: 90%;
  }

  .linha {
    margin-bottom: 15px;
  }

  .icone,
  .icone-usuario {
    width: 35px;
    height: 35px;
    font-size: 18px;
  }

  .nome,
  .email {
    font-size: 14px;
  }

  .barra-palavras {
    height: 25px;
  }

  .quantidade-palavras {
    font-size: 14px;
  }
}
.foto-perfil {
 border: 2px solid #ddd;
    border-radius: 50%;
    height: 40px;
    object-fit: cover;
    width: 40px;
}
.foto-perfil-vertical {
  border: 2px solid #ddd;
    border-radius: 50%;
    height: 40px;
    object-fit: cover;
    width: 40px;
}