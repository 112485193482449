/* Estilos para a página de Tutoriais */

.tutorial-container {
  background: url('../../imagens/fundo-login.webp') repeat center center fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;
  text-align: left;
  padding: 20px;
  box-sizing: border-box;
  scroll-behavior: smooth; /* Adicionando scroll suave */
}

.tutorial-header {
  width: 100%;
  display: flex;
  justify-content: center; /* Centraliza a logo */
  align-items: center;
  position: absolute;
  top: 20px;
  left: 0;
  padding: 0 20px;
  box-sizing: border-box;
}

.tutorial-logo {
  height: 200px;
}

.tutorial-user-info {
  position: absolute;
  top: 140px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  background: #0009;
  border-radius: 5px;
}

.tutorial-user-info span {
  margin-bottom: 10px;
}

.tutorial-button {
  background: linear-gradient(to right, #fac97b, #d5a05e);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.tutorial-button:hover {
  background: #C28B4E;
}

.tutorial-content {
  margin-top: 200px;
  padding-top: 50px;
  width: 100%;
  max-width: 900px;
  text-align: center;
  animation: fadeInUp 1s ease-out;
}

.tutorial-title-gradient {
  font-size: 2.5em;
  background: linear-gradient(to right, #FFC871, #997844);
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 20px;
  animation: fadeInUp 1s ease-out;
}

.tutorial-description {
  font-size: 1.2em;
  max-width: 800px;
  margin: 0 auto 20px;
  color: #FFFFFF;
  animation: fadeInUp 1s ease-out;
}

.tutorial-step {
  margin-bottom: 20px;
  text-align: left;
  color: #fff;
  font-size: 1.1em;
  list-style: none;
}

.tutorial-step h2 {
  margin-bottom: 10px;
  font-size: 1.5em;
  font-weight: bold;
}

.tutorial-step li {
  margin-bottom: 10px;
}

.tutorial-menu {
  margin-bottom: 20px;
  text-align: left;
}

.tutorial-menu h3 {
  color: #FFC871;
  margin-bottom: 10px;
  font-size: 1.5em;
}

.tutorial-menu ul {
  list-style: none;
  padding: 0;
}

.tutorial-menu ul li {
  margin-bottom: 10px;
  color: #ffffff;
  font-size: 1.1em;
}

.tutorial-button-large {
  background: linear-gradient(to right, #fac97b, #d5a05e);
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeInUp 1s ease-out;
  margin-bottom: 20px;
}

.tutorial-button-large:hover {
  background: #C28B4E;
}

.tutorial-button-large svg {
  margin-right: 10px;
}

.tutorial-button-menu {
  background: linear-gradient(to right, #fac97b, #d5a05e);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  animation: fadeInUp 1s ease-out;
}

.tutorial-button-menu:hover {
  background: #C28B4E;
}

.tutorial-link {
  color: #FFC871; /* Remove o azul e sublinhado padrão */
  text-decoration: none;
  border-bottom: 2px solid #FFC871;
}

.tutorial-link:hover {
  color: #FFD700;
  border-bottom: 2px solid #FFD700;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .tutorial-header {
    flex-direction: column;
    align-items: center;
  }

  .tutorial-logo {
    height: 100px;
    margin-bottom: 10px;
  }

  .tutorial-user-info {
    top: 80px;
  }

  .tutorial-content {
    margin-top: 150px;
    padding: 0 20px;
  }

  .tutorial-title-gradient {
    font-size: 2em;
  }

  .tutorial-description {
    font-size: 1em;
  }
}

/* CSS específico para dispositivos móveis */
@media (max-width: 768px) {
    body {
        position: relative; /* Garante que o posicionamento não seja afetado */
        overflow-x: hidden; /* Bloqueia a rolagem horizontal */
    }
}
