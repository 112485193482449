/* adminLogin.css */
body {
  font-family: 'Poppins', Arial, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url('../../imagens/fundo-login.webp') no-repeat center center fixed;
  background-size: cover;
}

#root {
  width: 100%;
}

.admin-login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
}

.admin-login-logo {
  width: 100%;
  max-width: 300px;
}

.admin-form-container {
  background: rgba(255, 255, 255, 0.03);
  padding: 2.5rem;
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.2);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  max-width: 400px;
  width: 100%;
  text-align: center;
  position: relative;
}

.admin-gradient-border {
  position: absolute;
  top: 0;
  left: -50%;
  width: 200%;
  height: 5px;
  background: linear-gradient(90deg, transparent, #eaa128, #b1833f, #eaa128, transparent);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  animation: gradient-animation 3s linear infinite;
}

@keyframes gradient-animation {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
}

.admin-title {
  font-size: 1.8rem;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 1.5rem;
  background: linear-gradient(to right, #cecece6b, #eaa128);
  -webkit-background-clip: text;
  color: transparent;
  animation: gradient-animation 3s linear infinite;
}

.admin-login-page-description {
  color: #ffffff;
  font-size: 0.9rem;
  margin-bottom: 1.5rem;
  opacity: 0.8;
}

.admin-input-container {
  position: relative;
  width: 90%;
  margin: 1rem auto;
}

.admin-input-container input {
  display: block;
  width: 100%;
  padding: 1rem;
  border: 1px solid #eaa128;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  color: #ffffff;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.admin-input-container input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.admin-input-container input:focus {
  outline: none;
  border-color: #ff8800;
  box-shadow: 0 0 0 2px rgba(255, 136, 0, 0.2);
}

.admin-input-container i {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
  transition: color 0.3s ease;
}

.admin-input-container input:focus + i {
  color: #ff8800;
}

.admin-password-input {
  padding-right: 40px;
}

.admin-login-button {
  display: block;
  width: 100%;
  padding: 1rem;
  margin-top: 1.5rem;
  background: linear-gradient(135deg, #ff8800, #ffa640);
  color: #ffffff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(255, 136, 0, 0.3);
}

.admin-login-button:hover {
  transform: translateY(-1px);
  background-color: #C28B4E;
}

.admin-error {
  color: red;
  margin-top: 10px;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .admin-login-logo {
    max-width: 65%;
  }

  .admin-form-container {
    padding: 1.5rem;
  }

  .admin-title {
    font-size: 1.5rem;
  }

  .admin-login-page-description {
    font-size: 0.8rem;
  }

  html {
    font-size: 14px;
  }
}
