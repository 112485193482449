@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap');

.lucro-alvo-container {
  background: url('../../imagens/fundo-login.webp') repeat center center fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;
  text-align: left;
  padding: 20px;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

.lucro-alvo-header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 0;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 10;
}

.lucro-alvo-logo {
  height: 200px;
  filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.5));
  transition: transform 0.3s ease;
}

.lucro-alvo-logo:hover {
  transform: scale(1.05);
}

.lucro-alvo-user-info {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 30px;
  padding: 10px 20px;
  backdrop-filter: blur(5px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.lucro-alvo-user-info span {
  margin-right: 10px;
  color: white;
  font-weight: 300;
}

.lucro-alvo-button {
  background: linear-gradient(45deg, #fac97b, #d5a05e);
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 30px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.lucro-alvo-button:hover {
  background: linear-gradient(45deg, #d5a05e, #fac97b);
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.lucro-alvo-content {
  margin-top: 150px;
  padding-top: 100px;
  width: 100%;
  max-width: 1200px;
}

.lucro-alvo-title-gradient {
  font-size: 3em;
  background: linear-gradient(45deg, #FFC871, #997844);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  font-weight: 700;
}

.lucro-alvo-description {
  font-size: 1.3em;
  max-width: 800px;
  line-height: 1.6;
  margin-bottom: 30px;
  font-weight: 300;
}

.lucro-alvo-form {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 800px;
  text-align: left;
  animation: slideInFromLeft 1s ease-out;
  backdrop-filter: blur(10px);
}

.lucro-alvo-form label {
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
  color: #FFC871;
  font-size: 1.1em;
}

.lucro-alvo-form p.input-description {
  font-size: 0.9em;
  margin-top: -8px;
  margin-bottom: 15px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 300;
}

.lucro-alvo-form input[type="text"], .lucro-alvo-form input[type="number"] {
  width: calc(100% - 20px);
  padding: 12px;
  margin: 10px 0;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  font-size: 1em;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  transition: all 0.3s ease;
}

.lucro-alvo-form input:focus {
  border-color: #FFC871;
  outline: none;
  box-shadow: 0 0 10px rgba(255, 200, 113, 0.3);
}

.lucro-alvo-form button.calcular {
  background: linear-gradient(45deg, #f6c478, #d7a360);
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1.1em;
  width: 100%;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.lucro-alvo-form button.calcular:hover {
  background: linear-gradient(45deg, #d7a360, #f6c478);
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.lucro-alvo-error {
  color: #ff6b6b;
  margin-top: 10px;
  font-weight: 500;
}

.lucro-alvo-results {
  display: none;
  animation: slideInFromBottom 1s ease-out;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 800px;
  margin-top: 30px;
  backdrop-filter: blur(10px);
}

.lucro-alvo-result-block {
  padding: 25px;
  border-radius: 15px;
  margin-bottom: 25px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  background-color: rgba(255, 255, 255, 0.05);
}

.lucro-alvo-result-block:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.lucro-alvo-result-block h3 {
  margin-bottom: 15px;
  color: #FFC871;
  font-size: 1.4em;
  font-weight: 600;
}

.lucro-alvo-result-item {
  margin-bottom: 12px;
  font-size: 1.1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 8px 0;
}

.lucro-alvo-icon {
  margin-right: 15px;
  font-size: 1.2em;
}

.lucro-alvo-metric {
  flex-grow: 1;
  font-weight: 300;
}

.lucro-alvo-number {
  font-weight: 600;
  color: #FFC871;
}

/* Ícones coloridos (mantidos como estavam) */

.lucro-alvo-highlight {
  background-color: rgba(34, 123, 255, 0.2);
  border-left: 5px solid #fec871;
  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
}

.lucro-alvo-button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.lucro-alvo-button-secondary {
  background: linear-gradient(45deg, #f5c377, #d9a562);
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.lucro-alvo-button-secondary:hover {
  background: linear-gradient(45deg, #d9a562, #f5c377);
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

@keyframes slideInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInFromBottom {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .lucro-alvo-header {
    flex-direction: column;
    align-items: center;
  }

  .lucro-alvo-logo {
    height: 100px;
    margin-bottom: 15px;
  }

  .lucro-alvo-user-info {
    top: 110px;
    right: 50%;
    transform: translateX(50%);
    flex-direction: column;
    align-items: center;
  }

  .lucro-alvo-user-info span {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .lucro-alvo-content {
    margin-top: 180px;
    padding: 0 20px;
  }

  .lucro-alvo-title-gradient {
    font-size: 2.2em;
    text-align: center;
  }

  .lucro-alvo-description {
    font-size: 1.1em;
    text-align: center;
  }

  .lucro-alvo-form {
    width: 100%;
    padding: 30px;
  }

  .lucro-alvo-result-block {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .lucro-alvo-result-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .lucro-alvo-result-item span {
    display: block;
    width: 100%;
    margin-left: 0;
    font-size: 1em;
    margin-top: 5px;
  }

  .lucro-alvo-button-group {
    flex-direction: column;
  }

  .lucro-alvo-button-secondary {
    margin-top: 10px;
  }
}

.print-logo {
  width: 250px;
  margin-bottom: 25px;
  filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.3));
}