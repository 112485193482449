/* Container principal */
.comments-container {
  width: 98%;
  margin: 0 auto;
  padding: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  position: relative;
  overflow: visible;
}



/* Área de input do comentário */
.comments-input-area {
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
  background: #2A2A2A;
  padding: 30px;
  border-radius: 8px;
  width: 96%;
  position: relative;
}
.comment-replies {
  margin-left: 20px;
  border-left: 2px solid #e0e0e0;
  padding-left: 20px;
  margin-top: 10px;
}

.reply-item {
  margin-top: 10px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: 8px;
}
.comments-user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.comments-input-container {
  flex: 1;
}

.comments-textarea {
  width: 100%;
  min-height: 80px;
  padding: 12px;
  background: #3A3A3A;
  border: 1px solid #4A4A4A;
  border-radius: 6px;
  color: #FFFFFF;
  resize: vertical;
  margin-bottom: 12px;
}

.comments-textarea::placeholder {
  color: #888888;
}

.comments-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.comments-emoji-picker {
  position: relative;
}

.comments-emoji-button {
  background: transparent;
  border: none;
  color: #888888;
  cursor: pointer;
  padding: 8px;
}

.comments-submit-button {
  background: #0066FF;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.comments-submit-button:hover {
  background: #0052CC;
}

/* Lista de comentários com scroll personalizado */
.comments-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  z-index: 1;
  max-height: 600px; /* Altura máxima antes do scroll */
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px; /* Compensar a largura da barra de scroll */
}

/* Estilização da barra de scroll */
.comments-list::-webkit-scrollbar {
  width: 6px;
}

.comments-list::-webkit-scrollbar-track {
  background: rgba(58, 58, 58, 0.3);
  border-radius: 3px;
}

.comments-list::-webkit-scrollbar-thumb {
  background: #0066FF;
  border-radius: 3px;
}

.comments-list::-webkit-scrollbar-thumb:hover {
  background: #0052CC;
}


.comment-item {
  background: #2A2A2A;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
}


.comment-header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
}

.comment-user-info {
  flex: 1;
}

.comment-username {
  color: #FFFFFF;
  font-weight: 600;
  margin-bottom: 4px;
}

.comment-timestamp {
  color: #888888;
  font-size: 0.875rem;
}

.comment-content {
  color: #FFFFFF;
  margin-bottom: 12px;
  line-height: 1.5;
}

.comment-actions {
  display: flex;
  gap: 16px;
  color: #888888;
}

.comment-action-button {
  background: linear-gradient(45deg, #ababab00, #b1833f00);
  border: none;
  color: inherit;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  padding: 4px;
  transition: color 0.2s;
}

.comment-action-button:hover {
  color: #FFFFFF;
}

.comment-likes {
  display: flex;
  align-items: center;
  gap: 4px;
}

/* Respostas aos comentários */
.comment-replies {
  margin-left: 52px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative; /* Adicionado */
}

/* Menções de usuários melhorada */
.comments-mentions {
  position: absolute;
  background: #2A2A2A;
  border: 1px solid #4A4A4A;
  border-radius: 8px;
  width: 250px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  margin-top: 4px;
  top: 100%; /* Posiciona abaixo do cursor */
  left: 0;
}

/* Estilização da barra de scroll das menções */
.comments-mentions::-webkit-scrollbar {
  width: 4px;
}

.comments-mentions::-webkit-scrollbar-track {
  background: rgba(58, 58, 58, 0.3);
  border-radius: 2px;
}

.comments-mentions::-webkit-scrollbar-thumb {
  background: #0066FF;
  border-radius: 2px;
}

.mention-item {
  padding: 10px 16px;
  cursor: pointer;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #3A3A3A;
  transition: background-color 0.2s;
}

.mention-item:last-child {
  border-bottom: none;
}

.mention-item:hover {
  background: #3A3A3A;
}

.mention-item-avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}

.mention-item-info {
  display: flex;
  flex-direction: column;
}

.mention-item-name {
  font-weight: 600;
  font-size: 14px;
}

.mention-item-username {
  font-size: 12px;
  color: #888888;
}
/* Loading state */
.comments-loading {
  text-align: center;
  color: #888888;
  padding: 20px;
}

/* Adicionar estas novas classes */
.comments-wrapper {
  position: relative;
  width: 100%;
  min-height: 200px; /* Altura mínima para o container */
}

.comments-fixed-content {
  position: relative;
  width: 100%;
  z-index: 2;
}

.comment-edit-container,
.reply-container {
  margin: 10px 0;
  padding: 10px;
  background: #2a2a2a;
  border-radius: 8px;
}

.comment-edit-actions,
.reply-actions {
  display: flex;
  gap: 10px;
  margin-top: 8px;
}

.delete-confirm {
  position: absolute;
  background: white;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-top: 5px;
  z-index: 1000;
}

.delete-confirm p {
  margin: 0 0 10px 0;
}

.delete-confirm button {
  margin: 0 5px;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.confirm-button {
  background: #dc3545;
  color: white;
}

.cancel-button {
  background: #6c757d;
  color: white;
}

.mention-notification {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #4a9eff;
  color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 300px;
}

.mention-notification button {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  padding: 0 0 0 10px;
}