/* src/paginas/minhaConta/PainelDeControle.css */

/* Container Principal */
.painel-de-controle-container-painel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 40px;
    background: linear-gradient(135deg, rgba(15, 14, 14, 0.95), rgba(30, 29, 29, 0.92));
    border-radius: 25px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4),
                inset 0 2px 2px rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(15px);
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    width: 100%;
    max-width: 1400px;
    margin: 30px auto;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.painel-de-controle-container-painel:hover {
    transform: translateY(-5px);
    box-shadow: 0 25px 50px rgba(0, 0, 0, 0.5),
                inset 0 2px 2px rgba(255, 255, 255, 0.15);
}

/* Seções de Assinatura e Visão Geral */
.painel-assinatura-painel, .painel-visao-geral-painel {
    background: linear-gradient(160deg, rgba(78, 76, 76, 0.8), rgba(50, 48, 48, 0.9));
    border-radius: 20px;
    padding: 35px;
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3),
                inset 0 2px 2px rgba(255, 255, 255, 0.05);
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    border: 1px solid rgba(255, 255, 255, 0.05);
}

.painel-assinatura-painel {
    width: 68%;
    margin-right: 35px;
}

.painel-visao-geral-painel {
    width: 45%;
}

.painel-assinatura-painel:hover, .painel-visao-geral-painel:hover {
    transform: translateY(-8px);
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.4),
                inset 0 2px 2px rgba(255, 255, 255, 0.1);
}

.painel-icone-titulo-painel {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    padding-bottom: 15px;
    border-bottom: 2px solid rgba(255, 215, 0, 0.2);
}

.icone-assinatura-painel {
    color: #FFD700;
    margin-right: 18px;
    font-size: 28px;
    text-shadow: 0 0 15px rgba(255, 215, 0, 0.5);
    animation: pulseIcon 2s infinite ease-in-out;
}

@keyframes pulseIcon {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
}

.titulo-assinatura-painel {
    font-size: 26px;
    margin: 0;
    color: #FFD700;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4),
                 0 0 15px rgba(255, 215, 0, 0.3);
    font-weight: 600;
    letter-spacing: 0.5px;
}

.descricao-assinatura-painel {
    margin: 15px 0;
    color: #fff;
    font-size: 17px;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
    line-height: 1.6;
    opacity: 0.9;
}

/* Bloco de Compra */
.bloco-compra {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.03));
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 25px;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.bloco-compra:hover {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05));
    transform: translateY(-5px) translateX(3px);
}

/* Linha de Informação */
.linha-info-painel {
    display: flex;
    align-items: center;
    padding: 15px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.02));
    border-radius: 12px;
    margin-bottom: 15px;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    border: 1px solid rgba(255, 255, 255, 0.05);
}

.linha-info-painel:hover {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.03));
    transform: translateX(8px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.icone-visao-geral-painel {
    color: #FFD700;
    margin-right: 15px;
    font-size: 20px;
    text-shadow: 0 0 10px rgba(255, 215, 0, 0.4);
}

/* Barra de Progresso */
.painel-barra-container-painel {
    margin-top: 30px;
    padding: 10px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.3);
}

.painel-barra-painel {
    background: linear-gradient(90deg, rgba(34, 34, 34, 0.8), rgba(51, 51, 51, 0.8));
    border-radius: 30px;
    overflow: hidden;
    height: 28px;
    position: relative;
    width: 100%;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.4);
}

.painel-barra-progresso-painel {
    height: 100%;
    background: linear-gradient(90deg, #FFD700, #FFA500);
    box-shadow: 0 0 20px rgba(255, 215, 0, 0.4),
                inset 0 2px 4px rgba(255, 255, 255, 0.3);
    width: 0;
    transition: width 1.8s cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
    overflow: hidden;
}

.painel-barra-progresso-painel::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.3) 50%,
        rgba(255, 255, 255, 0) 100%
    );
    animation: shimmer 2s infinite;
}

@keyframes shimmer {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(100%); }
}

.painel-barra-texto-painel {
    text-align: center;
    margin-top: 15px;
    color: #FFD700;
    font-weight: 600;
    font-size: 17px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4),
                 0 0 15px rgba(255, 215, 0, 0.3);
    letter-spacing: 0.5px;
}

/* Botão de Upgrade */
.painel-botao-upgrade-painel {
    background: linear-gradient(135deg, #FFD700, #FFA500);
    color: white;
    border: none;
    padding: 15px 30px;
    border-radius: 30px;
    cursor: pointer;
    margin-top: 30px;
    width: 100%;
    font-weight: 600;
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0 8px 15px rgba(255, 165, 0, 0.3),
                inset 0 2px 2px rgba(255, 255, 255, 0.3);
    position: relative;
    overflow: hidden;
}

.painel-botao-upgrade-painel:hover {
    background: linear-gradient(135deg, #FFA500, #FFD700);
    transform: translateY(-3px);
    box-shadow: 0 12px 20px rgba(255, 165, 0, 0.4),
                inset 0 2px 2px rgba(255, 255, 255, 0.4);
}

.painel-botao-upgrade-painel::after {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(
        45deg,
        transparent,
        rgba(255, 255, 255, 0.3),
        transparent
    );
    transform: rotate(45deg);
    animation: shimmerButton 3s infinite;
}

@keyframes shimmerButton {
    0% { transform: rotate(45deg) translateX(-100%); }
    100% { transform: rotate(45deg) translateX(100%); }
}

/* Foto de Perfil */
.foto-perfil-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    position: relative;
}

.foto-perfill {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #FFD700;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3),
                0 0 0 5px rgba(255, 215, 0, 0.2);
}

.foto-perfill:hover {
    transform: scale(1.05) rotate(5deg);
    border-color: #FFA500;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.4),
                0 0 0 8px rgba(255, 215, 0, 0.3);
}

/* Responsividade */
@media (max-width: 1200px) {
    .painel-de-controle-container-painel {
        padding: 30px;
        margin: 20px;
    }
}

@media (max-width: 1024px) {
    .painel-de-controle-container-painel {
        flex-direction: column;
        padding: 25px;
    }

    .painel-assinatura-painel,
    .painel-visao-geral-painel {
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px;
    }
}

@media (max-width: 768px) {
    .painel-de-controle-container-painel {
        padding: 20px;
        margin: 15px;
    }

    .painel-assinatura-painel,
    .painel-visao-geral-painel {
        padding: 25px;
    }

    .titulo-assinatura-painel {
        font-size: 22px;
    }
}

@media (max-width: 480px) {
    .painel-de-controle-container-painel {
        padding: 15px;
        margin: 10px;
    }

    .painel-assinatura-painel,
    .painel-visao-geral-painel {
        padding: 20px;
    }

    .foto-perfill {
        width: 120px;
        height: 120px;
    }
}