.chat-admin-container {
  padding: 20px;
}

.chat-admin-titulo {
  font-size: 24px;
  font-weight: bold;
  color: #FFD700;
  margin-bottom: 20px;
}

.chat-admin-erro {
  color: red;
}

.usuarios-tickets-container {
  display: flex;
  justify-content: space-between;
}

.usuarios-list {
  width: 30%;
  background-color: #333;
  padding: 15px;
  border-radius: 5px;
  color: #FFD700;
}

.usuarios-list h3 {
  margin-bottom: 10px;
}

.usuarios-list ul {
  list-style: none;
  padding: 0;
}

.usuarios-list li {
  background-color: #444;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.usuarios-list li:hover {
  background-color: #555;
}

.tickets-list {
  width: 65%;
}

.ticket-container {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 15px;
  overflow: hidden;
}

.ticket-header {
  background-color: #333;
  color: #FFD700;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.ticket-icon {
  margin-right: 10px;
}

.ticket-categoria {
  flex-grow: 1;
  font-size: 18px;
  margin: 0;
}

.ticket-detalhes {
  padding: 10px;
  background-color: #4e4c4c57;
  color: #FFF;
}

.interacoes-container {
  margin-top: 10px;
}

.interacao {
  background-color: #555;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.input-mensagem {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  resize: none;
}

.button-enviar, .button-fechar-ticket {
  background: linear-gradient(90deg, gold, orange);
  color: #ffffff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  display: block;
  width: 100%;
  text-align: center;
  font-weight: bold;
}

.button-enviar:hover, .button-fechar-ticket:hover {
  background-color: #e5c100;
}

.button-fechar-ticket {
  background-color: #FF6347;
}

.button-fechar-ticket:hover {
  background-color: #e5533b;
}

.notification-icon {
  color: red;
  margin-left: 10px;
}

/* Responsividade */
@media (max-width: 768px) {
  .usuarios-tickets-container {
    flex-direction: column;
  }

  .usuarios-list, .tickets-list {
    width: 100%;
  }

  .ticket-header {
    flex-direction: column;
    align-items: flex-start;
  }
}
