/* Estilos para o container principal */
.menu-container-unique {
  background: linear-gradient(135deg, #0a0a0a 0%, #141414 100%);
  min-height: 100vh;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #ffffff;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  position: relative;
  overflow-x: hidden;
}

.menu-container-unique::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 20%, transparent 100%);
  pointer-events: none;
  z-index: 1;
}

/* Estilos para o cabeçalho */
.header-unique {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 0;
  padding: 0 20px;
  box-sizing: border-box;
  background: linear-gradient(180deg, rgba(0,0,0,0.95) 0%, rgba(0,0,0,0.8) 50%, transparent 100%);
  z-index: 2;
}

.menu-logo-unique {
  margin: 0 auto;
  height: 200px;
  transition: transform 0.4s ease;
}

.menu-logo-unique:hover {
  transform: scale(1.05);
}

/* Estilos para a informação do usuário */
.user-info-unique {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 65px;
  right: 20px;
  background: rgba(0, 0, 0, 0.8);
  padding: 8px 15px;
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  z-index: 10;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 200, 113, 0.1);
}

.user-info-unique:hover {
  background: rgba(0, 0, 0, 0.9);
  box-shadow: 0 6px 12px rgba(255, 200, 113, 0.15);
  border-color: rgba(255, 200, 113, 0.3);
  transform: translateY(-2px);
}

.user-info-unique span {
  margin-right: 15px;
  color: #FFC871;
  font-weight: 500;
  transition: all 0.3s ease;
}

.user-info-unique:hover span {
  color: #FFC871;
  text-shadow: 0 0 8px rgba(255, 200, 113, 0.3);
}

/* Estilos para o título */
h1.title-gradient-unique {
  font-size: 3em;
  background: linear-gradient(45deg, #C28B4E, #8B6B43);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-bottom: 20px;
  letter-spacing: 1px;
  position: relative;
  z-index: 2;
  text-shadow: none;
}

/* Estilos para os menus */
.menu-items-unique {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
  width: 100%;
  margin-top: 30px;
  padding: 0 20px;
}

.menu-item-unique {
  background: linear-gradient(135deg, rgba(30, 30, 30, 0.95), rgba(20, 20, 20, 0.95));
  backdrop-filter: blur(10px);
  padding: 20px;
  margin: 15px;
  border-radius: 12px;
  width: 30%;
  max-width: 350px;
  min-width: 280px;
  text-align: center;
  color: white;
  transition: all 0.3s ease;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(40, 40, 40, 0.5);
}

.menu-item-unique:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4);
  border-color: rgba(255, 200, 113, 0.2);
  background: linear-gradient(135deg, rgba(35, 35, 35, 0.95), rgba(25, 25, 25, 0.95));
}

.menu-img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.menu-item-unique:hover .menu-img {
  transform: scale(1.03);
}

/* Botões e elementos interativos */
.btn-unique {
  background: linear-gradient(45deg, #FFC871, #C28B4E);
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
}

.btn-unique:hover {
  background: linear-gradient(45deg, #C28B4E, #FFC871);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Link de compra */
.buy-link {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  background: #C28B4E;
  color: #fff;
  padding: 10px 25px;
  border-radius: 6px;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.3s ease;
  z-index: 2;
}

.buy-link:hover {
  background: #FFC871;
  transform: translateX(-50%) translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* Estilos para o conteúdo */
.content-unique {
  margin-top: 150px;
  padding-top: 100px;
  width: 100%;
  position: relative;
  z-index: 1;
}

/* Estilos para as notificações */
.notification-unique {
  position: fixed;
  top: 20px;
  right: 20px;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.8), rgba(50, 50, 50, 0.8));
  color: #FFC871;
  padding: 12px 25px;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  animation: slideIn 0.5s ease-out;
  backdrop-filter: blur(5px);
}

.notification-unique button {
  background: none;
  border: none;
  color: #FFC871;
  font-size: 1.2em;
  cursor: pointer;
  margin-left: 15px;
  transition: all 0.3s ease;
}

.notification-unique button:hover {
  transform: rotate(90deg) scale(1.1);
}

.message-popup-unique {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.95);
  color: #333;
  border: 2px solid #FFC871;
  border-radius: 20px;
  padding: 25px;
  z-index: 1000;
  max-width: 80%;
  text-align: center;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
}

.message-popup-unique h2 {
  color: #C28B4E;
  margin-bottom: 15px;
  font-size: 1.8em;
}

.message-popup-unique p {
  margin-bottom: 15px;
  line-height: 1.6;
}

.message-popup-unique .highlight-unique {
  color: #C28B4E;
  font-weight: bold;
}

.message-popup-unique .user-name-unique {
  color: #C28B4E;
  font-weight: bold;
}

.message-popup-unique .close-btn-unique {
  background: linear-gradient(45deg, #FFC871, #C28B4E);
  color: #1a1a1a;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.message-popup-unique .close-btn-unique:hover {
  background: linear-gradient(45deg, #C28B4E, #FFC871);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.message-popup-unique .footer-message-unique {
  margin-top: 15px;
  color: #C28B4E;
  font-weight: bold;
  font-style: italic;
}

/* Animação de entrada */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Animação para os menus */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* CSS específico para dispositivos móveis */
@media (max-width: 768px) {
  .menu-items-unique {
    flex-direction: column;
    align-items: center;
  }
  
  .menu-logo-unique {
    height: 120px;
    margin: 0 auto;
  }

  .user-info-unique {
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 25px;
    display: flex;
    justify-content: flex-end;
    padding: 8px 15px;
    position: absolute;
    right: 20px;
    top: 100px;
  }

  .menu-item-unique {
    width: 90%;
    max-width: none;
  }

  h1.title-gradient-unique {
    font-size: 2.2em;
  }

  p.description-unique {
    font-size: 1.1em;
  }

  .message-popup-unique {
    max-width: 90%;
    padding: 20px;
    width: calc(100% - 40px);
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .message-popup-unique h2 {
    font-size: 1.4em;
  }

  .message-popup-unique p {
    font-size: 1em;
  }

  .message-popup-unique .close-btn-unique {
    padding: 8px 16px;
    font-size: 0.9em;
  }

  /* Adicionando o background preto para dispositivos móveis */
  body {
    background-color: #000; /* Fundo preto */
  }
}

/* Garantindo que elementos pais não escondam o botão */
.parent-element {
  overflow: visible;
}

/* CSS específico para dispositivos móveis */
@media (max-width: 768px) {
    body {
        position: relative; /* Garante que o posicionamento não seja afetado */
        overflow-x: hidden; /* Bloqueia a rolagem horizontal */
    }
}

/* Estilos específicos para dispositivos móveis */
@media (max-width: 768px) {
  .hero-section {
    height: 60vh;
    padding: 0 20px;
  }
  
  .header-unique {
    padding: 0 20px;
  }
  
  .modules-section {
    padding: 0 20px;
  }
  
  .module-card {
    min-width: 200px;
    height: 120px;
  }
}

.buy-link {
  position: absolute;
  bottom: 10px; /* Posiciona o botão de compra no final do card */
  left: 50%;
  transform: translateX(-50%);
  background-color: #bfa066; /* Cor de fundo para combinar com o design */
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  z-index: 2; /* Garante que o botão de compra fique acima do overlay de bloqueio */
}

.buy-link:hover {
  background-color: #5af202bb;
  text-decoration: none;
}

.overlay-blocked {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semitransparente */
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  border-radius: 8px;
  pointer-events: none; /* Permite que os cliques passem para os elementos abaixo */
}

.menu-item-unique {
  position: relative;
  /* Outras regras existentes */
}

/* Estilos para as categorias */
.category-title-unique {
  background: linear-gradient(135deg, rgba(30, 30, 30, 0.95), rgba(20, 20, 20, 0.95));
  color: #ffffff;
  text-align: left;
  padding: 15px 25px;
  border-radius: 12px;
  margin: 30px auto;
  display: inline-block;
  width: calc(85% - 40px);
  font-size: 1.6em;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 1px solid rgba(40, 40, 40, 0.5);
  transition: all 0.3s ease;
  backdrop-filter: blur(10px);
}

.category-title-unique:hover {
  border-color: rgba(255, 200, 113, 0.2);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
  background: linear-gradient(135deg, rgba(35, 35, 35, 0.95), rgba(25, 25, 25, 0.95));
  transform: translateY(-2px);
}
